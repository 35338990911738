import './RemittanceLookup.css';
import axios from 'axios';
import Accordion from '../../Accordion';
import React, { useState, useEffect, useContext } from 'react';
import ErrorHandler from './../../core/ErrorHandler';
import RemittanceLookupForm from './RemittanceLookupForm';
import { DetailsModal } from '../../grid/DetailsModal';
import { LoadingSpinner } from './../../layout/LoadingSpinner';
import { Row, Col, Button } from 'react-bootstrap';
import DataTable from '../../grid/DataTable';
import ActionCell from './ActionCell';
import { CustomAction } from '../../grid/CustomActionBarAction';
import singleCheckPDFExporter from '../../export/SingleCheckPDFExporter';
import NetRxLogo from '../../assets/NetRxLogo.png';
import pdfMake from "pdfmake/build/pdfmake";
import { UserContext } from '../../context/UserContext';
import { constants } from './RemittanceLookupConstants';
import { FeatureWidget } from '../../SiSense/FeatureWidget';

import { formatCheckDetailsForExport } from '../../export/CheckDetailsExcelExport'
import { RowExcelExporter } from '../../export/RowExcelExporter'

export default function RemittanceLookup(props) {

    const { passedProps } = props
    const user = passedProps.user.email
    const [isLoading, setIsLoading] = useState(false)

    const [error, setError] = useState({})
    const [showModal, setShowModal] = useState(false)

    const [submittedFormData, setSubmittedFormData] = useState(null)

    const [data, setData] = useState([])
    const [columns, setColumns] = useState([])

    const [showArchiveSelectedButton, setShowArchiveSelectedButton] = useState(false)
    const [showRestorePendingButton, setShowRestorePendingButton] = useState(false)

    const [checkDetails, setCheckDetails] = useState(null)
    const [detailData, setDetailData] = useState([])
    const [detailColumns, setDetailColumns] = useState([])

    const [archiveData, setArchiveData] = useState([])
    const [archiveColumns, setArchiveColumns] = useState([])
    const [showArchiveModal, setShowArchiveModal] = useState(false)
    const [singleCheckForExport, setSingleCheckForExport] = useState(null)
    const [customExport, setCustomExport] = useState(null)

    const userContext = useContext(UserContext)

    function handleFormSubmit(formInput) {
        setIsLoading(true)
        setSubmittedFormData(formInput)
    }

    useEffect(() => {
        if (submittedFormData) {
            getAllCheckDetails()
        }
    }, [submittedFormData])

    useEffect(() => {
        if (checkDetails) {
            passedProps.auth.getAccessToken()
                .then(accessToken => {
                    axios.get(`api/RemittanceLookup/Get`, {
                        params: {
                            user: user,
                            Payers: submittedFormData.payers,
                            Locations: submittedFormData.locations,
                            Status: submittedFormData.status,
                            FromDate: submittedFormData.fromDate,
                            ToDate: submittedFormData.toDate,
                            DateType: submittedFormData.dateType,
                            CheckAmount: submittedFormData.amount,
                            CheckNumber: submittedFormData.checkNumber
                        },
                        headers: { Authorization: `Bearer ${accessToken}` }
                    })
                        .then(onSuccess)
                        .catch(onFailure)
                })
                .catch(onFailure)
        }
    }, [checkDetails])

    function getAllCheckDetails() {
        passedProps.auth.getAccessToken()
            .then(accessToken => {
                axios.get(`api/RemittanceLookup/GetCheckDetails`, {
                    params: {
                        user: user,
                        Payers: submittedFormData.payers,
                        Locations: submittedFormData.locations,
                        Status: submittedFormData.status,
                        FromDate: submittedFormData.fromDate,
                        ToDate: submittedFormData.toDate,
                        DateType: submittedFormData.dateType,
                        CheckAmount: submittedFormData.amount,
                        CheckNumber: submittedFormData.checkNumber
                    },
                    headers: { Authorization: `Bearer ${accessToken}` }
                })
                    .then(onSuccess)
                    .catch(onFailure)
            })
            .catch(onFailure)
    }

    useEffect(() => {
        if (submittedFormData && data) {
            let dataArchivedCount = 0;
            let dataPendingCount = 0;

            for (let i = 0; i < data.length; i++) {
                let currentRow = data[i];

                if (currentRow.status === "Archived") {
                    dataArchivedCount++;
                }

                if (currentRow.status === "Pending") {
                    dataPendingCount++;
                }
            }

            //NOTE: If the user wants to see pending checks, and there are more than 2 of them, then the user should be able to bulk archive them
            setShowArchiveSelectedButton(submittedFormData.status.includes('Pending') && dataPendingCount >= 2);

            //NOTE: If the user wants to see archived checks, and there are more than 2 of them, then the user should be able to bulk restore pending them
            setShowRestorePendingButton(submittedFormData.status.includes('Archive') && dataArchivedCount >= 2);
        }

    }, [data]);


    function setArchiveHistoryData(rows) {
        if (rows.length > 0) {
            let headerProps = constants.archiveHistoryHeaderProps;

            const columns = []
            Object.keys(rows[0]).map(key => {
                columns.push({
                    accessor: key,
                    title: headerProps[key].title,
                    type: headerProps[key].type,
                    showTotal: headerProps[key].showTotal,
                    fixedLeft: headerProps[key].fixedLeft,
                    hidden: headerProps[key].hidden
                })
            });

            setArchiveData(rows)
            setArchiveColumns(columns)
            setShowArchiveModal(true)
        }
    }

    async function getSingleCheckPdf(id) {
        setIsLoading(true)

        try {
            const accessToken = await passedProps.auth.getAccessToken()
            const response = await axios.get(`api/Check/GetSingleCheckPdfExport`, {
                params: { user: user, PMID: id },
                headers: { Authorization: `Bearer ${accessToken}` }
            })

            var ctx = document.getElementById('hiddenCanvasForSingleCheckPdf').getContext('2d');
            var img = new Image();
            img.onload = () => {
                ctx.drawImage(img, 0, 0);

                const data = response.data
                let pdfFileName = "REMITADVICE";
                let docDefinition = singleCheckPDFExporter(data);
                if (docDefinition) {
                    pdfMake.createPdf(docDefinition).download(pdfFileName);
                    setIsLoading(false)

                }
            }
            img.src = NetRxLogo;
        } catch (e) {
            setError("An error has occurred while generating PDF")
            setIsLoading(false)
        }
    }

    async function getSingleCheckExcel(id) {
        setIsLoading(true)
        try {
            const accessToken = await passedProps.auth.getAccessToken()
            const response = await axios.get(`api/Check/GetSingleCheckExcelExport`, {
                params: { user: user, pmid: id },
                headers: { Authorization: `Bearer ${accessToken}` }
            })

            setSingleCheckForExport(formatCheckDetailsForExport(response.data))
        } catch (e) {
            console.log(e)
        }
        setIsLoading(false)
    }

    function onSuccess(response) {
        switch (response.config.url) {
            case 'api/RemittanceLookup/Get': setGridData(response.data); break;
            case 'api/RemittanceLookup/GetCheckDetails': setGridDetails(response.data); break;
            case 'api/RemittanceLookup/GetArchiveHistory': setArchiveHistoryData(response.data); break;
            case 'api/RemittanceLookup/RestorePending': handleRestorePendingCallBack(); break;
            case 'api/RemittanceLookup/ArchiveRemittance': handleArchiveRemittanceCallBack(); break;
            default: break;
        }
    }


    function setGridData(data) {
        let columns = [];

        if (data.length > 0) {
            let headerProps = constants.mainGridHeaderProps;
            headerProps.archivedDate = { title: 'Archived Date', type: 'date', hidden: (submittedFormData.status && submittedFormData.status.includes('Archive')) ? false : true };

            columns.push({
                accessor: "Action",
                title: "Action",
                type: "custom",
                selectable: true
            });

            Object.keys(data[0]).forEach(key => {
                columns.push({
                    accessor: key,
                    title: headerProps[key].title,
                    type: headerProps[key].type,
                    showTotal: headerProps[key].showTotal,
                    fixedLeft: headerProps[key].fixedLeft,
                    hidden: headerProps[key].hidden,
                    editable: headerProps[key].editable
                })
            });

            let rows = data;

            rows.forEach(row => {
                row["Action"] = getActionCell(row['pmid'], { NCPDP: row.ncpdp, status: row.status, isManualCheck: row.isManualCheck });
            })

            setError({});
            setData(rows);
        }
        else {
            setError({ status: 201, Message: 'No data found for given params' })
            setData([])
        }

        setColumns(columns);
        setIsLoading(false)
    }

    function getCheckDetails(id) {
        let columns = [];

        let headerProps = constants.mainGridDetailHeaderProps;
        let row = Object.keys(checkDetails)[0];
        Object.keys(checkDetails[row][0]).forEach(key => {
            columns.push({
                title: headerProps[key].title,
                type: headerProps[key].type,
                accessor: key,
                showTotal: headerProps[key].showTotal,
                fixedLeft: headerProps[key].fixedLeft,
                hidden: headerProps[key].hidden || false,
            })
        });

        setDetailData(checkDetails[id])
        setDetailColumns(columns)
        setShowModal(true)
    }

    function setGridDetails(data) {
        setCheckDetails(data)
        exportMainGridData(data)
    }

    function onFailure(error) {
        setIsLoading(false)
        setError(error.response)
        switch (error.response.config.url) {
            case 'api/RemittanceLookup/Get':
                setGridData([]);
                setColumns([]);
                break;
            case 'api/RemittanceLookup/GetCheckDetails':
                setGridDetails([]);
                setDetailColumns([]);
                break;
            case 'api/RemittanceLookup/GetArchiveHistory':
                setArchiveHistoryData([]);
                setArchiveColumns([]);
                break;
            default: break;
        }

        return;
    }

    function getActionCell(rowId, rowData) {
        return (
            <ActionCell rowId={rowId} rowData={rowData} />
        )
    }

    async function handleCellEdit(value, row, column) {
        if (column === 'paymentDate') {
            try {
                const accessToken = await passedProps.auth.getAccessToken();
                const response = await axios.post('api/RemittanceLookup/UpdatePaymentDate',
                    {
                        user: user,
                        PaymentDate: (value) ? value.toLocaleDateString() : '',
                        PMID: row.pmid
                    },
                    { headers: { Authorization: `Bearer ${accessToken}` } });
                if (response.data) {
                    let index = data.findIndex(element => element.pmid == row.pmid)
                    let updatedData = [...data]
                    let updatedRow = row

                    updatedRow[column] = value
                    updatedData[index] = updatedRow
                    setData(updatedData)

                    setError({ status: 200, Message: 'Your payment date is queued to be updated!' })
                }
                else {
                    setError({ status: 501, Message: '' })
                }
                setTimeout(() => { setError({}) }, 3600);
            }
            catch (e) {
                setIsLoading(false)
                setError(e)
            }
        }
    }

    function getNewRowSelectedValue(rows) {
        if (rows.length > 1) {
            return !rows.every(x => {
                return x.selected === true
            })
        }
        return !rows[0].selected
    }

    function handleRowSelected(rows) {
        let newData = [...data]
        let selectedValue = getNewRowSelectedValue(rows)

        rows.forEach(row => {
            let index = newData.findIndex((x => x.pmid == row.pmid))
            newData[index].selected = selectedValue
        })
        setData(newData)
    }

    function getCustomActions() {
        const potentialRestorePending = showRestorePendingButton && (
            <CustomAction label="Restore Pending status for Selected" onClick={restorePendingAllSelected} />
        )

        const potentialArchiveSelected = showArchiveSelectedButton && (
            <CustomAction label="Archive Selected" onClick={archiveAllSelected} />
        )

        return (
            <React.Fragment>
                {potentialArchiveSelected}
                {potentialRestorePending}
            </ React.Fragment>
        )
    }

    function postRestorePending(pmids) {
        if (pmids.length > 0) {
            //NOTE: This was the original logic for Restore Pending, I need this to make the API call and then trigger onSuccess, which in turn does the handleRestorePendingCallBack()
            passedProps.auth.getAccessToken()
                .then(accessToken => {
                    axios.get('api/RemittanceLookup/RestorePending', {
                        params: {
                            user: user,
                            rpid: userContext.currentOrganization.value,
                            pmids: pmids.join(',')
                        },
                        headers: { Authorization: `Bearer ${accessToken}` }
                    })
                        .then(onSuccess)
                        .catch(onFailure)
                })
                .catch(onFailure)
        }
    }

    function handleRestorePendingCallBack() {
        let resetData = data.filter(payment => !(payment.selected && payment.status === 'Archived'))
        setData([...resetData])

        setError({ status: 200, Message: 'Your payment is queued to be restored!' })
        setTimeout(() => { setError({}) }, 3600);
    }

    function archiveAllSelected() {
        let selectedChecks = [];

        data.forEach((row) => {
            if (row.selected) {
                selectedChecks.push(row);
            }
        });

        let containsAnyArchived = selectedChecks.some(row => row.status === "Archived");

        let finalPmids = [];

        if (containsAnyArchived) {
            //NOTE: If the set of selected rows, need to check what happens
            if (window.confirm('Selected records include both Archived and Pending statuses. Click "OK" if you wish to proceed Archiving only the selected Pending status records, or "Cancel" to stop and return to modify selections.')) {
                selectedChecks.forEach((row) => {
                    if (row.status === "Pending") {
                        finalPmids.push(row.pmid);
                    }
                });

                postArchiveRemittances(finalPmids);
            }
        }
        else {
            selectedChecks.forEach((row) => {
                finalPmids.push(row.pmid);
            });

            postArchiveRemittances(finalPmids);
        }
    }

    function handleArchiveRemittanceCallBack() {
        let resetData = data.filter(payment => !(payment.selected && payment.status === 'Pending'))
        setData([...resetData])

        setError({ status: 200, Message: 'Your payment is queued to be archived!' })
        setTimeout(() => { setError({}) }, 3600);
    }

    function archiveRemit(row) {
        let newData = data
        let index = newData.findIndex(x => x.pmid === row.pmid)
        newData[index].selected = true
        setData(newData)

        postArchiveRemittances([row.pmid])
    }

    function postArchiveRemittances(pmids) {
        if (pmids.length > 0) {
            passedProps.auth.getAccessToken()
                .then(accessToken => {
                    axios.get('api/RemittanceLookup/ArchiveRemittance', {
                        params: {
                            user: user,
                            rpid: userContext.currentOrganization.value,
                            pmids: pmids.join(',')
                        },
                        headers: { Authorization: `Bearer ${accessToken}` }
                    })
                        .then(onSuccess)
                        .catch(onFailure)
                })
                .catch(onFailure)
        }
    }

    function restorePendingAllSelected() {
        let selectedChecks = [];

        data.forEach((row) => {
            if (row.selected) {
                selectedChecks.push(row);
            }
        });

        let containsAnyPending = selectedChecks.some(row => row.status === "Pending");
        let finalPmids = [];

        if (containsAnyPending) {
            //NOTE: If the set of selected rows, need to check what happens
            if (window.confirm('Selected records include both Archived and Pending statuses. Click "OK" if you wish to proceed Restoring only the selected Archived status records, or "Cancel" to stop and return to modify selections.')) {
                selectedChecks.forEach((row) => {
                    if (row.status === "Archived") {
                        finalPmids.push(row.pmid);
                    }
                });

                postRestorePending(finalPmids);
            }
        }
        else {
            selectedChecks.forEach((row) => {
                finalPmids.push(row.pmid);
            });

            postRestorePending(finalPmids);
        }
    }

    //NOTE: The "showArchiveSelectedButton" and "showRestorePendingButton" values are determined before this point and so can be used for determining which "sub buttons" to show
    function getArchiveHistory(id) {
        passedProps.auth.getAccessToken()
            .then(accessToken => {
                axios.get(`api/RemittanceLookup/GetArchiveHistory`, {
                    params: {
                        pmid: id
                    },
                    headers: { Authorization: `Bearer ${accessToken}` }
                })
                    .then(onSuccess)
                    .catch(onFailure)
            })
            .catch(onFailure)
    }

    function handleCellClick(row, column) {

        switch (column) {
            case 'ncpdp':
            case 'paid':
            case 'adjusted':
            case 'checkAmount':
                getCheckDetails(row.pmid)
                break;
            case 'viewArchiveHistory':
                //NOTE: Reference the getArchiveHistory() on ArchiveRemittanceReview.js for the similiar function
                getArchiveHistory(row.pmid);
                break;
            case 'pdf':
                getSingleCheckPdf(row.pmid);
                break;
            case 'excel':
                getSingleCheckExcel(row.pmid);
                break;
            case 'archiveRecord':
                archiveRemit(row);
                break;
            case 'restorePending':
                restorePending(row);
                break;
            default: break;

        }
    }

    function restorePending(row) {
        let newData = data
        let index = newData.findIndex(x => x.pmid === row.pmid)
        newData[index].selected = true
        setData(newData)

        postRestorePending([row.pmid])
    }

    function exportMainGridData(data) {
        let customCols = [];
        let customRows = {};

        if (Object.keys(data).length > 0) {
            let headerProps = constants.mainGridExportHeaderProps;
            headerProps.archivedDate.hidden = true;
            headerProps.archiveUser.hidden = true;

            if (submittedFormData.status.includes("Archive")) {
                headerProps.archivedDate.hidden = false;
                headerProps.archiveUser.hidden = false;
            }

            let col = Object.values(data[Object.keys(data)[0]]);
            Object.keys(col[0]).forEach(key => {
                customCols.push({
                    title: headerProps[key].title,
                    type: headerProps[key].type,
                    accessor: key,
                    showTotal: headerProps[key].showTotal,
                    hidden: headerProps[key].hidden || false
                })
            })
            customRows = data;
        }

        setCustomExport({ columns: customCols, rows: customRows, PrimaryKey: 'pmid', page: 'RemittanceLookup' })
    }

    return (
        <React.Fragment>
            <div className="feature">
                <canvas id="hiddenCanvasForSingleCheckPdf" style={{ display: "none" }} width={185} height={70} />
                <LoadingSpinner isDataLoading={isLoading} controlsName={'Remittance Lookup'} />
                <RowExcelExporter dataSet={singleCheckForExport} reset={() => setSingleCheckForExport(null)} filename='Remittance Lookup' />
                <ErrorHandler error={error} onClose={() => { setError({}) }} />
                <Accordion defaultExpanded label="Search & Filter">
                    <Row>
                        <Col lg="auto" >
                            <RemittanceLookupForm handleFormSubmit={handleFormSubmit}></RemittanceLookupForm>
                        </Col>
                        <Col lg="auto" className="forceToRightForLargeWindow">
                            <FeatureWidget
                                title='Last 5 Remittances Received'
                                iFrameId='sisense-remittance-lookup-frame'
                                filters={{}}
                                dashboard={process.env.REACT_APP_Dashboard_RemittanceLookup}
                                widget={process.env.REACT_APP_Widget_RemittanceLookup}
                                settings={{ showToolbar: false, showLeftPane: false, showRightPane: false }}
                                size="lg"
                            />
                        </Col>
                    </Row>
                </Accordion>

                <DataTable
                    columns={columns}
                    rows={data}
                    id="remittance-lookup"
                    exportable customExport={customExport} exportOptions={[`xlsx`, `pdf`]}
                    filterable totalFooter pagination selectableRows
                    handleCellClick={handleCellClick}
                    handleCellEdit={handleCellEdit}
                    handleRowSelected={(row) => handleRowSelected(row)}
                    customActionBarActions={getCustomActions()}
                />

                <DetailsModal title="Remittance Receipt Details" show={showModal} handleClose={() => setShowModal(false)}>
                    <DataTable
                        columns={detailColumns}
                        rows={detailData}
                        id="remittance-receipt-verification-detail"
                        exportable filterable totalFooter pagination
                    />
                </DetailsModal>
            </div>

            <DetailsModal title="Archive History" show={showArchiveModal} handleClose={() => setShowArchiveModal(false)}>
                <DataTable
                    columns={archiveColumns}
                    rows={archiveData}
                    id="remittance-lookup-history"
                />
            </DetailsModal>
        </React.Fragment>
    )

}