import React from "react";

import "./ScreenHeader.css";
import "../../../css/custom.css";
import MainLogo from "../../assets/MHA-NetRx-Logo.svg";
import { Col, Navbar } from "react-bootstrap";
import { Organization } from "./Organization";
import { UserAvatar } from "./UserAvatar";
import { Link } from "react-router-dom";

export function ScreenHeader(props) {
  const { user, auth } = props;
  const { userType, given_name, family_name } = user;

  return (
    <Navbar className="no-gutters" expand="lg" id="screen-header">
      <Col className="sm-4 no-gutters">
        <Link to="/">
          <Navbar.Brand>
            <img src={MainLogo} width="210" height="80" alt="logo" />
          </Navbar.Brand>
        </Link>
      </Col>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse className="no-gutters" id="basic-navbar-nav">
        <Col
          className="no-gutters action-link-with-icon"
          id="organization-action-link"
        >
          <Organization userType={userType} />
        </Col>
        <Col className="no-gutters" id="screen-header-action-link-group">
          <span className="action-link-with-icon ">
            <i className="fa fa-info-circle" />
            Help Center
          </span>
        </Col>
        <Col className="col-md-auto d-flex">
          <span className="action-link-with-icon">
            <i className="fa fa-comments" />
            Chat
          </span>
        </Col>
        <Col className="col-md-auto">
          <span className="action-link-with-icon">
            <i className="fa fa-bell-o" />
          </span>
        </Col>
        <UserAvatar user={{ firstName: given_name, lastName: family_name }} auth={auth} />
      </Navbar.Collapse>
    </Navbar>
  );
}
