import React, { useState, useEffect } from 'react';
import ExcelFormatter from './excelFormatter';
// import ReactExport from 'react-export-excel';

export default function excelExporter(props) {
    const { columns = [], rows = [], custom = {}, name = 'xlsxExport', button = 'Excel Spreadsheet .xlsx', handleActionBarStartAction, handleActionBarCompleteAction } = props;

    const [xlsxColumns, setXlsxColumns] = useState([])
    const [xlsxRows, setXlsxRows] = useState([])

    const exportButton = <span><i className='fa fa-file-excel-o' aria-hidden></i> {button}</span>

    //NOTE TODO: Figure out if loading spinners are need for the excel main grid download
    useEffect(() => {
        //handleActionBarStartAction();
        if (custom) {
            switch (custom.PrimaryKey) {
                case 'pmid': setXlsxRows(filterByPaymentID()); break;
                case 'clid': setXlsxRows(filterByClaimID()); break;
                default: setXlsxRows(custom.rows); break;
            }
            setXlsxColumns(custom.columns);
        }
        else {
            setXlsxColumns(columns)
            setXlsxRows(rows)
        }
        //handleActionBarCompleteAction();
    }, [rows])
       
    function filterByPaymentID() {
        let data = [];
        for (let i = 0; i < rows.length; i++) {
            let currentRow = rows[i];
            let customRow = custom.rows[currentRow.pmid];
            if (customRow) {
                customRow.map( row => { data.push(row) })
            }
        }
        return data;
    }

    function filterByClaimID() {
        let data = [];
        for (let i = 0; i < rows.length; i++) {
            let currentRow = rows[i];
            let customRow = custom.rows[currentRow.clid];
            if (customRow) {
                customRow.forEach(row => { data.push(row) })
            }
        }
        return data;
    }

    return (
        <React.Fragment>
            {/* <ReactExport.ExcelFile fileName={name} element={exportButton}>
                <ReactExport.ExcelFile.ExcelSheet dataSet={ExcelFormatter(xlsxColumns, xlsxRows)} name={name} />
            </ReactExport.ExcelFile> */}
        </React.Fragment>
    );
}