import axios from 'axios';
import React, { useState, useEffect, useContext } from 'react';
import Accordion from '../../Accordion';
import { Row, Col } from 'react-bootstrap';
import DataTable from '../../grid/DataTable';
import AlertHandler from './../../core/ErrorHandler';
import { DetailsModal } from '../../grid/DetailsModal';
import { FeatureWidget } from '../../SiSense/FeatureWidget';
import { LoadingSpinner } from './../../layout/LoadingSpinner';
import ActionCell from './ActionCell';
import ArchiveRemittanceReviewForm from './ArchiveRemittanceReviewForm';
import { CustomAction } from '../../grid/CustomActionBarAction';
import singleCheckPDFExporter from '../../export/SingleCheckPDFExporter';
import NetRxLogo from '../../assets/NetRxLogo.png';
import pdfMake from "pdfmake/build/pdfmake";
import { formatCheckDetailsForExport } from '../../export/CheckDetailsExcelExport'
import { RowExcelExporter } from '../../export/RowExcelExporter'

import { UserContext } from '../../context/UserContext';

export default function ArchiveRemittanceReview(props) {

    const { passedProps } = props
    const user = passedProps.user.email
    const [isLoading, setIsLoading] = useState(false)
    const [isCurrentlyGettingData, setIsCurrentlyGettingData] = useState(false)

    const [submittedFormData, setSubmittedFormData] = useState(null)

    const [data, setData] = useState([])
    const [columns, setColumns] = useState([])
    const [archiveData, setArchiveData] = useState([])
    const [archiveColumns, setArchiveColumns] = useState([])
    const [showArchiveModal, setShowArchiveModal] = useState(false)
    const [error, setError] = useState({})

    const [customExport, setCustomExport] = useState(null)

    const [showModal, setShowModal] = useState(false)
    const [detailData, setDetailData] = useState([])
    const [detailColumns, setDetailColumns] = useState([])
    const [detailError, setDetailError] = useState({})

    const [checkDetails, setCheckDetails] = useState(null)
    const [singleCheckForExport, setSingleCheckForExport] = useState(null)

    const userContext = useContext(UserContext)

    useEffect(() => {
        if (submittedFormData) {
            getAllCheckDetails()
        }

    }, [submittedFormData])


    useEffect(() => {
        if (checkDetails) {
            passedProps.auth.getAccessToken()
                .then(accessToken => {
                    axios.get(`api/RemittanceReceiptArchive/Get`, {
                        params: {
                            user: user,
                            fromDate: submittedFormData.fromDate,
                            toDate: submittedFormData.toDate,
                            locations: submittedFormData.locations,
                            payers: submittedFormData.payers,
                            dateType: submittedFormData.dateType
                        },
                        headers: { Authorization: `Bearer ${accessToken}` }
                    })
                        .then(onSuccess)
                        .catch(onFailure)
                })
                .catch(onFailure)
        }
    }, [checkDetails])

    useEffect(() => {
        if (isLoading) {
            if (!isCurrentlyGettingData) {
                setIsLoading(false)
            }
        }
    }, [isCurrentlyGettingData, isLoading])

    function getArchiveHistory(id) {
        passedProps.auth.getAccessToken()
            .then(accessToken => {
                axios.get(`api/RemittanceReceiptArchive/GetArchiveHistory`, {
                    params: {
                        user: user,
                        pmid: id
                    },
                    headers: { Authorization: `Bearer ${accessToken}` }
                })
                    .then(onSuccess)
                    .catch(onFailure)
            })
            .catch(onFailure)
    }

    function handleFormSubmit(e) {
        setIsCurrentlyGettingData(true);
        setIsLoading(true);
        setData([])
        setSubmittedFormData(e)
    }

    function getAllCheckDetails() {
        passedProps.auth.getAccessToken()
            .then(accessToken => {
                axios.get('api/RemittanceReceiptArchive/GetCheckDetails', {
                    params: {
                        user: user,
                        fromDate: submittedFormData.fromDate,
                        toDate: submittedFormData.toDate,
                        locations: submittedFormData.locations,
                        payers: submittedFormData.payers,
                        dateType: submittedFormData.dateType
                    },
                    headers: { Authorization: `Bearer ${accessToken}` }
                })
                    .then(onSuccess)
                    .catch(onFailure)
            })
            .catch(onFailure)
    }

    async function getSingleCheckPdf(id) {
        setIsLoading(true)
        setIsCurrentlyGettingData(true)
        try {
            const accessToken = await passedProps.auth.getAccessToken()
            const response = await axios.get(`api/Check/GetSingleCheckPdfExport`, {
                params: { PMID: id },
                headers: { Authorization: `Bearer ${accessToken}` }
            })

            var ctx = document.getElementById('hiddenCanvasForSingleCheckPdf').getContext('2d');
            var img = new Image();
            img.onload = () => {
                ctx.drawImage(img, 0, 0);

                const data = response.data
                let pdfFileName = "REMITADVICE";
                let docDefinition = singleCheckPDFExporter(data);
                if (docDefinition) {
                    pdfMake.createPdf(docDefinition).download(pdfFileName);
                    setIsCurrentlyGettingData(false)

                }
            }
            img.src = NetRxLogo;
        } catch (e) {
            setError("An error has occurred while generating PDF")
            setData([])
            setColumns([])
            setIsCurrentlyGettingData(false)
        }
    }


    function onSuccess(response) {
        switch (response.config.url) {
            case 'api/RemittanceReceiptArchive/Get': setGridData(response.data); break;
            case 'api/RemittanceReceiptArchive/RestorePending': handleRestorePendingCallBack(); break;
            case 'api/RemittanceReceiptArchive/GetArchiveHistory': setArchiveHistoryData(response.data); break;
            case 'api/RemittanceReceiptArchive/GetCheckDetails': setCheckDetailDataAndCustomExport(response.data); break;
            default: break;
        }
    }

    function setCheckDetailDataAndCustomExport(data) {
        setCheckDetails(data);
        handleCustomExport(data);
    }

    function onFailure(error) {
        if (error.response.config.url) {
            switch (error.response.config.url) {
                case `api/RemittanceReceiptArchive/Get`:
                    setError(error.response)
                    setData([])
                    setColumns([])
                    break;
                case `api/RemittanceReceiptArchive/GetCheckDetails`:
                    setDetailError(error.response)
                    setDetailData([])
                    setDetailColumns([])
                    break;
                case `api/RemittanceReceiptArchive/RestorePending`:
                    setError(error.response)
                    break;
                case `api/RemittanceReceiptArchive/GetArchiveHistory`:
                    setError(error.response)
                    setArchiveData([])
                    setArchiveColumns([])
                    break;
                default: break;
            }
        }
        else {
            setError(error.response);
            setColumns([])
            setData([])
        }

        setIsLoading(false)

    }

    async function handleCellEdit(value, row, column) {
        if (column === 'paymentDate') {
            try {
                const accessToken = await passedProps.auth.getAccessToken();
                const response = await axios.post('api/RemittanceReceiptArchive/UpdatePaymentDate',
                    {
                        user: user,
                        PaymentDate: (value) ? value.toLocaleDateString() : '',
                        PMID: row.pmid
                    },
                    { headers: { Authorization: `Bearer ${accessToken}` } });
                if (response.data) {
                    let index = data.findIndex(element => element.pmid == row.pmid)
                    let updatedData = [...data]
                    let updatedRow = row

                    updatedRow[column] = value
                    updatedData[index] = updatedRow
                    setData(updatedData)

                    setError({ status: 200, Message: 'Your payment date is queued to be updated!' })
                }
                else {
                    setError({ status: 501, Message: '' })
                }
                setTimeout(() => { setError({}) }, 3600);
            }
            catch (e) {
                setError(e);
            }
        }
    }

    function getCheckDetails(id) {
        let headerProps = {
            payer: { title: 'Payer Name' },
            pmid: { title: 'Pmid', hidden: true },
            checkNumber: { title: 'Check #' },
            checkDate: { title: 'Check Date', type: 'date' },
            remittanceDate: { title: 'Remit Date', type: 'date' },
            paymentDate: { title: 'Payment Date', type: 'date' },
            location: { title: 'Location (NCPDP)' },
            paid: { title: 'Paid', type: 'money', showTotal: true },
            adjusted: { title: 'Adjusted', type: 'money', showTotal: true },
            checkAmount: { title: 'Check Amount', type: 'money', showTotal: true },
            archivedDate: { title: 'Archive Date', type: 'date', hidden: true },
            archiveUser: { title: 'Archive User', hidden: true }
        }

        let detailColumns = [];
        let firstKey = Object.keys(checkDetails)[0]
        Object.keys(checkDetails[firstKey][0]).map(key => {
            detailColumns.push({
                title: headerProps[key].title,
                type: headerProps[key].type,
                accessor: key,
                showTotal: headerProps[key].showTotal,
                fixedLeft: headerProps[key].fixedLeft,
                hidden: headerProps[key].hidden
            })
        });
        setDetailData(checkDetails[id])
        setShowModal(true)
        setDetailColumns(detailColumns)
    }

    function setGridData(data) {

        let columns = [];

        if (data.length > 0) {
            let headerProps = {
                payer: { title: 'Payer Name' },
                pmid: { title: 'PMID', hidden: true },
                checkNumber: { title: 'Check #' },
                checkDate: { title: 'Check Date', type: 'date' },
                remittanceDate: { title: 'Remit Date', type: 'date' },
                paymentDate: { title: 'Payment Date', type: 'date', editable: true },
                ncpdp: { title: 'NCPDP' },
                paid: { title: 'Paid', type: 'money', showTotal: true },
                adjusted: { title: 'Adjusted', type: 'money', showTotal: true },
                checkAmount: { title: 'Check Amount', type: 'money', showTotal: true },
                archived: { title: 'archived', hidden: true },
                archivedDate: { title: 'Archived Date', type: 'date' }
            }

            columns.push({ accessor: "Action", title: "Action", type: "custom" });

            Object.keys(data[0]).map(key => {
                columns.push({
                    accessor: key,
                    title: headerProps[key].title,
                    type: headerProps[key].type,
                    showTotal: headerProps[key].showTotal,
                    fixedLeft: headerProps[key].fixedLeft,
                    hidden: headerProps[key].hidden,
                    editable: headerProps[key].editable
                })
            });

            let rows = data;
            rows.map(row => {
                row["selected"] = false;
                row["Action"] = renderActionCell(row['pbid'], { NCPDP: row.NCPDP, Archived: row.archived })
            })

            setError({});
            setData(rows);
            setColumns(columns);
        }
        else {
            setError({ status: 201, Message: 'No data found for given params' })
            setColumns([])
            setData([])
        }
        setIsCurrentlyGettingData(false)
    }

    function setArchiveHistoryData(rows) {
        if (rows.length > 0) {
            let headerProps = {
                checkNumber: { title: 'Check Number' },
                checkDate: { title: 'Check Date', type: 'date' },
                checkAmount: { title: 'Check Amount', type: 'money' },
                archived: { title: 'Activity' },
                addUser: { title: 'User' },
                addDate: { title: 'Date', type: 'date' },
                pmid: { title: 'pmid', hidden: true }
            }

            const columns = []
            Object.keys(rows[0]).map(key => {
                columns.push({
                    accessor: key,
                    title: headerProps[key].title,
                    type: headerProps[key].type,
                    showTotal: headerProps[key].showTotal,
                    fixedLeft: headerProps[key].fixedLeft,
                    hidden: headerProps[key].hidden
                })
            });

            setArchiveData(rows)
            setArchiveColumns(columns)
            setShowArchiveModal(true)
        }
    }

    function handleCustomExport(data) {
        let customCols = [];
        let customRows = {};

        if (Object.keys(data).length > 0) {
            let headerProps = {
                payer: { title: 'Payer' },
                pmid: { title: 'pmid', hidden: true },
                checkNumber: { title: 'Check Number' },
                checkDate: { title: 'Check Date', type: 'date' },
                remittanceDate: { title: 'Remit Date', type: 'date' },
                paymentDate: { title: 'Payment Date', type: 'date' },
                location: { title: 'NCPDP' },
                paid: { title: 'Paid', type: 'money', showTotal: true },
                adjusted: { title: 'Adjusted', type: 'money', showTotal: true },
                checkAmount: { title: 'Check Amount', type: 'money', showTotal: true },
                archivedDate: { title: 'Archived Date', type: 'date' },
                archiveUser: { title: 'Archived User' }
            }

            let col = Object.values(data[Object.keys(data)[0]]);

            Object.keys(col[0]).map(key => {
                customCols.push({
                    title: headerProps[key].title,
                    type: headerProps[key].type,
                    accessor: key,
                    showTotal: headerProps[key].showTotal,
                    hidden: headerProps[key].hidden || false
                })
            })

            customRows = data;
        }

        setCustomExport({ columns: customCols, rows: customRows, PrimaryKey: 'pmid', page: 'ArchiveRemittanceReview' })
    }

    function getNewRowSelectedValue(rows) {
        if (rows.length > 1) {
            return !rows.every(x => {
                return x.selected === true
            })
        }
        return !rows[0].selected
    }

    function handleRowSelected(rows) {
        let newData = [...data]
        let selectedValue = getNewRowSelectedValue(rows)

        rows.forEach(row => {
            let index = newData.findIndex((x => x.pmid == row.pmid))
            newData[index].selected = selectedValue
        })
        setData(newData)
    }

    function renderActionCell(rowId, rowData) {
        return (
            <ActionCell rowId={rowId} rowData={rowData} />
        )
    }

    function handleCellClick(row, column) {

        switch (column) {
            case 'archiveHistory':
                getArchiveHistory(row.pmid)
                break;
            case 'restorePending':
                restorePending(row);
                break;
            case 'pdf':
                getSingleCheckPdf(row.pmid);
                break;
            case 'ncpdp':
            case 'paid':
            case 'adjusted':
            case 'checkAmount':
                getCheckDetails(row.pmid)
                break;
            case 'excel':
                getSingleCheckExcel(row.pmid)
                break;
        }
    }

    function postRestorePending(pmids) {
        if (pmids.length > 0) {
            passedProps.auth.getAccessToken()
                .then(accessToken => {
                    axios.get(`api/RemittanceReceiptArchive/RestorePending`, {
                        params: {
                            user: user,
                            rpid: userContext.currentOrganization.value,
                            pmids: pmids.join(',')
                        },
                        headers: { Authorization: `Bearer ${accessToken}` }
                    })
                        .then(onSuccess)
                        .catch(onFailure)
                })
                .catch(onFailure)
        }
    }

    function restorePendingAllSelected() {
        const pmids = []
        data.forEach((row) => {
            if (row.selected) pmids.push(row.pmid)
        });

        postRestorePending(pmids)
    }

    function restorePending(row) {
        let newData = data
        let index = newData.findIndex(x => x.pmid === row.pmid)
        newData[index].selected = true
        setData(newData)

        postRestorePending([row.pmid])
    }

    function handleRestorePendingCallBack() {
        let resetData = data.filter(payment => !payment.selected)
        setData([...resetData])

        setError({ status: 200, Message: 'Your payment is queued to be restored!' })
        setTimeout(() => { setError({}) }, 3600);

        setIsLoading(false);
    }

    function getNewRowSelectedValue(rows) {
        if (rows.length > 1) {
            return !rows.every(x => {
                return x.selected === true
            })
        }
        return !rows[0].selected
    }

    function getCustomActions() {
        return (
            <CustomAction label="Restore Pending status for Selected" onClick={restorePendingAllSelected} />
        )
    }

    function exportPDF(data) {

        var ctx = document.getElementById('hiddenCanvasForSingleCheckPdf').getContext('2d');
        var img = new Image();
        img.onload = function () {
            ctx.drawImage(img, 0, 0);

            let pdfFileName = "REMITADVICE";
            let docDefinition = singleCheckPDFExporter(data);
            if (docDefinition) {
                pdfMake.createPdf(docDefinition).download(pdfFileName);
                setIsCurrentlyGettingData(false)
            }
        }
        img.src = NetRxLogo;
    }

    async function getSingleCheckExcel(id) {
        setIsLoading(true)
        setIsCurrentlyGettingData(true)
        try {
            const accessToken = await passedProps.auth.getAccessToken()
            const response = await axios.get(`api/Check/GetSingleCheckExcelExport`, {
                params: { pmid: id },
                headers: { Authorization: `Bearer ${accessToken}` }
            })

            setSingleCheckForExport(formatCheckDetailsForExport(response.data))
        } catch (e) {
            console.log(e)
        }
        setIsCurrentlyGettingData(false)
    }


    function localActionBarStart() {
        setIsCurrentlyGettingData(true);
        setIsLoading(true);
    }

    function localActionBarComplete() {
        setIsCurrentlyGettingData(false);
        setIsLoading(false);
    }

    return (
        <div className="feature">
            <canvas id="hiddenCanvasForSingleCheckPdf" style={{ display: "none" }} width={185} height={70}></canvas>
            <LoadingSpinner isDataLoading={isLoading} controlsName='ArchiveRemittanceVerification' />
            <RowExcelExporter dataSet={singleCheckForExport} reset={() => setSingleCheckForExport(null)} filename='Remittance Lookup' />
            <AlertHandler error={error} onClose={() => { setError({}) }} />
            <Accordion defaultExpanded label="Search & Filter">
                <Row>
                    <Col>
                        <ArchiveRemittanceReviewForm handleFormSubmit={handleFormSubmit} />
                    </Col>
                    <Col>
                        <FeatureWidget
                            title='Total Amount Archived Last 30 Days'
                            iFrameId='sisense-arr-frame'
                            filters={{}}
                            dashboard={process.env.REACT_APP_Dashboard_ArchiveRemittance}
                            widget={process.env.REACT_APP_Widget_ArchiveRemittance}
                            settings={{ showToolbar: false, showLeftPane: false, showRightPane: false }}
                            hidden={false}
                        />
                    </Col>
                </Row>
            </Accordion>
            <DataTable
                columns={columns}
                rows={data}
                id="remittance-receipt-archive"
                exportable exportOptions={[`pdf`]} customExport={customExport} filterable totalFooter pagination selectableRows
                handleCellClick={handleCellClick}
                handleCellEdit={handleCellEdit}
                handleRowSelected={(row) => handleRowSelected(row)}
                customActionBarActions={getCustomActions()}
                handleActionBarStartAction={localActionBarStart}
                handleActionBarCompleteAction={localActionBarComplete}
            />

            <DetailsModal title="Payment Details" show={showModal} handleClose={() => setShowModal(false)}>
                <AlertHandler error={detailError} onClose={() => { setDetailError({}) }} />
                <DataTable
                    columns={detailColumns}
                    rows={detailData}
                    id="remittance-receipt-archive-detail"
                    exportable filterable totalFooter pagination
                />
            </DetailsModal>
            <DetailsModal title="Archive History" show={showArchiveModal} handleClose={() => setShowArchiveModal(false)}>
                <DataTable
                    columns={archiveColumns}
                    rows={archiveData}
                    id="remittance-archive-history"
                />
            </DetailsModal>
        </div>
    );

}