import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { Form, Button, Col, OverlayTrigger, Popover } from 'react-bootstrap'

import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'

import FormValidation from '../../form/validation/FormValidation';
import MemberLocationDropdown from '../../form/MemberLocationDropdown'
import MemberPayerDropdown from '../../form/MemberPayerDropdown'

import { constants } from './DSOByPayerTrackingConstants';

export function DSOByPayerTrackingForm(props) {
    const [fromDate, setFromDate] = useState(getFromDate())
    const [toDate, setToDate] = useState(new Date())
    const [selectedLocations, setSelectedLocations] = useState([])
    const [selectedPayers, setSelectedPayers] = useState([])
    const [loaded, setLoaded] = useState(false)

    useEffect(() => {
        if (!loaded && selectedLocations.length > 0 && selectedPayers.length > 0) {
            setLoaded(true)
            submitForm();
        }
    }, [selectedLocations, selectedPayers])

    function getFromDate() {
        let date = new Date()
        date.setMonth(date.getMonth() - 1);
        return new Date(date)
    }

    function submitForm() {
        let parameters = {
            fromDate: fromDate,
            toDate: toDate,
            selectedLocations: selectedLocations,
            selectedPayers: selectedPayers,
            requiredFields: ["fromDate", "toDate"]
        };

        let validationIssueMessage = FormValidation(parameters);

        if (validationIssueMessage) {
            alert(validationIssueMessage);
        }
        else {
            props.handleFormSubmit(
                {
                    toDate: toDate.toLocaleDateString(),
                    fromDate: fromDate.toLocaleDateString(),
                    selectedLocation: selectedLocations.join(';'),
                    selectedPayer: selectedPayers.join(';')
                }
            );
        }

    }

    function handleSubmit(event) {
        event.preventDefault();

        submitForm();
    }

    const popover = (
        <Popover id="popover-basic">
            <Popover.Content>
                {constants.featureInfo}
            </Popover.Content>
        </Popover>
    );
    return (
        <Form onSubmit={handleSubmit}>
            <Form.Row className="justify-content-start">
                <Form.Group as={Col} md="auto" className="d-flex flex-column" controlId="formGridFromDate">
                    <Form.Label>Date From</Form.Label>
                    <Form.Control
                        name="fromDate"
                        as={DatePicker}
                        selected={fromDate}
                        onChange={(date) => setFromDate(date)}
                    />
                </Form.Group>
                <Form.Group as={Col} md="auto" className="d-flex flex-column" controlId="formGridToDate">
                    <Form.Label>Date To</Form.Label>
                    <Form.Control
                        name="toDate"
                        as={DatePicker}
                        selected={toDate}
                        onChange={(date) => setToDate(date)}
                    />
                </Form.Group>
                <Form.Group as={Col} md="auto" className="d-flex flex-column" >
                    <Form.Label>Payer</Form.Label>
                    <Form.Control as={MemberPayerDropdown} onChange={(payers) => setSelectedPayers(payers)} />
                </Form.Group>
                <Form.Group as={Col} md="auto" className="d-flex flex-column" >
                    <Form.Label>Location (NCPDP)</Form.Label>
                    <Form.Control as={MemberLocationDropdown} onChange={(locations) => setSelectedLocations(locations)} />
                </Form.Group>
                <Form.Group as={Col} md="auto" className="d-flex flex-column" controlId="formGridToDate">
                    <Button className="inline-show-result-button" size="sm" type="submit">Show Results</Button>
                </Form.Group>
                <span>
                    <OverlayTrigger trigger="click" placement="bottom" overlay={popover} rootClose>
                        <i className="additional-info-popover-icon fa fa-info-circle" />
                    </OverlayTrigger>
                </span>
            </Form.Row>
        </Form>
    )
}

DSOByPayerTrackingForm.propTypes = {
    handleFormSubmit: PropTypes.func.isRequired
}