import React from 'react';
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'

export function IgnoreModal(props) {
    const { show, title, children, handleClose } = props

    return (
        <Modal className="grid-claim-ignore-modal"  show={show} onHide={handleClose}  >
            <Modal.Header>
                <h3>{title}</h3>
            </Modal.Header>
            <Modal.Body>
                {children}
            </Modal.Body>

            
        </Modal>

    )


}