import React from 'react';
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'

export function DetailsModal(props) {
    const { show, title, children, handleClose } = props 
 
    return (
        <Modal className="grid-details-modal" show={show} onHide={handleClose}>
            <Modal.Header>
                <h1>{title}</h1>
            </Modal.Header>
            <Modal.Body>
                {children}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>

            </Modal.Footer>
        </Modal>

    )
    

}