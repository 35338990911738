import React, { Component } from 'react';
import '../../css/custom.css';
import { Accordion } from './Accordion';
import Col from 'react-bootstrap/Col'
import { Link } from 'react-router-dom'

export class NavBar extends Component {
    render() {
        return (
            <Accordion selected={this.props.label}>
                <div icon="fa fa-dashboard" label="Dashboard"></div>
                <div icon="fa fa-building-o" label="Business Overview">
                    <ul>
                        <li><Link to="#">Total Sales/Payment Summary</Link></li>
                        <li><Link to="#">Business Trend</Link></li>
                        <li><Link to="#">Facility Analytics</Link></li>
                    </ul>
                </div>
                <div icon="fa fa-calculator" label="Advanced Analytics">
                    <ul>
                        <li><Link to="/LocationComparison">Location Comparison</Link></li>
                        <li><Link to="/Byod">Self-guided Analytics</Link></li>
                        <li><Link to="/AugmentedAnalytics">Augmented Analytics</Link></li>
                    </ul>
                </div>
                <div icon="fa fa-money" label="Value Assessment">
                    <ul>
                        <li><Link to="#">RecRx Recovery</Link></li>
                        <li><Link to="#">Reverse/Rebill Report</Link></li>
                        <li><Link to="#">PRE Results</Link></li>
                    </ul>
                </div>
                <div icon="fa fa-wpforms" label="Claims and Billing Review">
                    <ul>
                        <li><Link to="#">Rx Lookup</Link></li>
                        <li><Link to="#">Payer Messaging Manager</Link></li>
                        <li><Link to="#">SCC Evaluation</Link></li>
                        <li><Link to="#">High $ Claims Review</Link></li>
                        <li><Link to="#">EditRx Report Repository</Link></li>
                        <li><Link to="#">PRE Claims Review</Link></li>
                        <li><Link to="#">Compound Claims Analysis</Link></li>
                    </ul>
                </div>
                <div icon="fa fa-handshake-o" label="Payer and Contract Evaluation">
                    <ul>
                        <li><Link to="#">Payer Analytics</Link></li>
                        <li><Link to="#">DSO by Payer Tracking</Link></li>
                        <li><Link to="#">Contract Assure</Link></li>
                        <li><Link to="#">Payer GER Tracker</Link></li>
                    </ul>
                </div>
                <div icon="fa fa-bar-chart" label="Drug and Pricing Statistics">
                    <ul>
                        <li><Link to="#">Drug Analytics</Link></li>
                        <li><Link to="#">5-year AWP Trend</Link></li>
                        <li><Link to="#">30-day Price Change Review</Link></li>
                    </ul>
                </div>
                <div icon="fa fa-paper-plane-o" label="Remittance Processing">
                    <ul>
                        <li><Link to="#">Remittance Receipt Verification</Link></li>
                        <li><Link to="#">Archived Remittance Review</Link></li>
                        <li><Link to="#">835 Remittance Creation</Link></li>
                        <li><Link to="#">Missing Remittance Reporting</Link></li>
                        <li><Link to="#">Remittance Exporting</Link></li>
                        <li><Link to="#">Remittance Lookup</Link></li>
                    </ul>
                </div>
                <div icon="fa fa-hourglass-end" label="Reconciliation and Aging Manager">
                    <ul>
                        <li><Link to="#">Third Party Accounts Receivable</Link></li>
                        <li><Link to="#">Aging (Outstanding) Payments</Link></li>
                        <li><Link to="#">Ignored Claims</Link></li>
                        <li><Link to="#">Collections</Link></li>
                        <li><Link to="#">Payer List</Link></li>
                    </ul>
                </div>
                <div icon="fa fa-binoculars" label="Fee and Adjustment Tracker">
                    <ul>
                        <li><Link to="#">Fee and Adjustment Research</Link></li>
                        <li><Link to="#">Payment Exceptions</Link></li>
                        <li><Link to="#">Copay Adjustment Manager</Link></li>
                    </ul>
                </div>
            </Accordion>




        );
    }
}
