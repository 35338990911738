import React from 'react';

import InputGroup from 'react-bootstrap/InputGroup'
import FormControl from 'react-bootstrap/FormControl'

export default function OrganizationDropdownFilter(props) {
    console.log(props);
    const { onFilterChange, filter } = props
    
    return (
        <InputGroup>
            <FormControl
                type='text'
                value={filter}
                onChange={event => onFilterChange(event.target.value)}
                placeholder=""
            />
            <InputGroup.Text style={{ 'height': '40px' }}>
                <i className="fa fa-search filter-icon" />
            </InputGroup.Text>
        </InputGroup>
    )
}