import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import StatusDropdown from '../../MultiSelectDropdown';
import { Form, Col, Button } from 'react-bootstrap';
import MemberLocationDropdown from '../../form/MemberLocationDropdown'
import MemberPayerDropdown from '../../form/MemberPayerDropdown'

export default function RemittanceLookupForm(props) {

    const [selectedStatus, setSelectedStatus] = useState([])
    const [selectedPayers, setSelectedPayers] = useState([])
    const [selectedLocations, setSelectedLocations] = useState([])  

    const [isDateLookup, setDateLookup] = useState(true)
    const [dateType, setDateType] = useState('check')
    const [fromDate, setFromDate] = useState(new Date(new Date().setMonth(new Date().getMonth() - 3)))
    const [toDate, setToDate] = useState(new Date())

    const [amount, setAmount] = useState('')
    const [isAmountLookup, setAmountLookup] = useState(false)

    const [checkNumber, setCheckNumber] = useState('')
    const [isCheckLookup, setCheckLookup] = useState(false)

    const statusOptions = [
        { value: 'Archive', label: "Archived"  },
        { value: 'Pending', label: "Pending"  }
    ];

    function validateForm() {

        // location (NCPDP) is a required field
        if (selectedLocations.length === 0) return 'Location (NCPDP) is a required field';

        // Payer is a required field
        if (selectedPayers.length === 0) return 'Payer is a required field';

        // Status is a required field 
        if (selectedStatus.length === 0) return 'Status is a required field';

        // At least one selection must be made for either date, amount or check number lookup RME-1511
        if (isDateLookup || isAmountLookup || isCheckLookup) {
            // when Date look up is checked then validate date look up values
            if (isDateLookup) {
                // Date From is a required field
                if (!fromDate) return 'Date From is a required field';

                // Date To is a required field
                if (!toDate) return 'Date To is a required field';

                // Date From cannot be greater than Date To
                if (fromDate > toDate) return 'Date From cannot be greater than Date To';
            }

            function isSpecialCharacters(val) {
                var pattern = new RegExp(/[~`!#$%\^&*+=\-\[\]\\';,/{}|\\":<>\?]/);
                return pattern.test(val);
            }

            // when amount look up is checked then validate amount look up values
            if (isAmountLookup) {
                if (amount) { // check if it contains a value
                    if (isSpecialCharacters(amount)) { // check if value contains any special characters
                        return 'Please only use standard alphanumerics characters';
                    }
                    else if (isNaN(amount)) { // check if the value is a number
                        return 'Amount value should be a number';
                    }
                    else {
                        let amountVal = amount;
                        setAmount(parseFloat(amountVal).toFixed(2))
                    }
                }
                else {
                    return 'Amount field is required when look up by amount is selected';
                }
            }

            // when check look up is checked then validate the check look up values
            if (isCheckLookup) {
                if (checkNumber) { // check if it contains a value
                    if (isSpecialCharacters(checkNumber)) { // check if value contains any special characters
                        return 'Please only use standard alphanumerics characters';
                    }
                    else if (checkNumber.length < 3) { // check if value is at least 3 characters long
                        return 'Check amount should be at least 3 characters long';
                    }
                }
                else {
                    return 'Check field is required when look up by check number is selected';
                }
            }
        }
        else {
            return 'At least one selection should be made for look up by';
        }

        return null;
    }

    function handleFormSubmit(e) {
        e.preventDefault()

        let errors = validateForm();
        (errors) ? alert(errors) : submitForm();
    }

    function submitForm() {
        props.handleFormSubmit({
            payers: selectedPayers.join(','),
            locations: selectedLocations.join(','),
            status: selectedStatus.join(','),
            dateType: (isDateLookup) ? dateType : '',
            fromDate: (fromDate && isDateLookup) ? fromDate.toLocaleDateString() : '',
            toDate: (toDate && isDateLookup) ? toDate.toLocaleDateString() : '',
            amount: (isAmountLookup) ? amount : '',
            checkNumber: (isCheckLookup) ? checkNumber : ''
        });
    }

    return (
        <Form onSubmit={handleFormSubmit}>
            <Form.Row className="justify-content-start">
                <Form.Group as={Col} md="auto" className="d-flex flex-column">
                    <Form.Label>Payer</Form.Label>
                    <Form.Control as={MemberPayerDropdown} onChange={(payers) => setSelectedPayers(payers)} />
                </Form.Group>
                <Form.Group as={Col} md="auto" className="d-flex flex-column">
                    <Form.Label>Location (NCPDP)</Form.Label>
                    <Form.Control as={MemberLocationDropdown} onChange={(locations) => setSelectedLocations(locations)} />
                </Form.Group>
                <Form.Group as={Col} md="auto" className="d-flex flex-column">
                    <Form.Label>Status</Form.Label>
                    <StatusDropdown options={statusOptions} onChange={(Status) => { setSelectedStatus(Status) }} selectAllOption filterable onLoadAllSelected />
                </Form.Group>
            </Form.Row>
            <Form.Row>
                <Form.Group as={Col} md="3" className="d-flex flex-column">
                    <Form.Label>Look Up By...</Form.Label>
                    <div key="LookupDate" className="mb-3">
                        <Form.Check custom type="checkbox" id="lookup1" label="Date" onChange={(e) => { setDateLookup(e.target.checked) }} defaultChecked />
                    </div>
                </Form.Group>
                <Form.Group as={Col} md="3" className="d-flex flex-column" style={{ width: "140px" }}>
                    <Form.Label>Date Type</Form.Label>
                    <Form.Control as="select" onChange={(e) => { setDateType(e.target.value) }} disabled={!isDateLookup}>
                        <option value="check">Check</option>
                        <option value="remittance">Remit</option>
                        <option value="archive">Archive</option>
                        <option value="payment">Payment</option>
                    </Form.Control>
                </Form.Group>
                <Form.Group as={Col} md="3" className="d-flex flex-column">
                    <Form.Label>Date From</Form.Label>
                    <Form.Control name="fromDate" as={DatePicker} selected={fromDate} onChange={(date) => { setFromDate(date) }} disabled={!isDateLookup} />
                </Form.Group>
                <Form.Group as={Col} md="3" className="d-flex flex-column">
                    <Form.Label>Date To</Form.Label>
                    <Form.Control name="fromDate" as={DatePicker} selected={toDate} onChange={(date) => { setToDate(date) }} disabled={!isDateLookup} />
                </Form.Group>
            </Form.Row>
            <Form.Row>
                <Form.Group as={Col} md="3" className="d-flex flex-column" style={{ padding: `35px 20px 0px 0px` }}>
                    <div key="LookupAmount" className="mb-3">
                        <Form.Check custom type="checkbox" id="Lookup2" label="Amount" onChange={(e) => { setAmountLookup(e.target.checked) }} />
                    </div>
                </Form.Group>
                <Form.Group as={Col} md="auto" className="d-flex flex-column">
                    <Form.Label>Amount</Form.Label>
                    <Form.Control type="text" maxLength="19" placeholder="Optional" onChange={(e) => { setAmount(e.target.value) }} value={amount} disabled={!isAmountLookup} />
                </Form.Group>
            </Form.Row>
            <Form.Row>
                <Form.Group as={Col} md="3" className="d-flex flex-column" style={{ padding: `35px 20px 0px 0px` }}>
                    <div key="LookupCheckNumber" className="mb-3">
                        <Form.Check custom type="checkbox" id="lookup3" label="Check Number" onChange={(e) => { setCheckLookup(e.target.checked) }} />
                    </div>
                </Form.Group>
                <Form.Group as={Col} md="auto" className="d-flex flex-column">
                    <Form.Label>Check Number</Form.Label>
                    <Form.Control type="text" maxLength="60" placeholder="Optional" onChange={(e) => { setCheckNumber(e.target.value) }} value={checkNumber} disabled={!isCheckLookup} />
                </Form.Group>
                <Form.Group as={Col} md="auto" className="d-flex flex-column">
                    <Button className="inline-show-result-button" size="sm" type="submit">Show Results</Button>
                </Form.Group>
            </Form.Row>
        </Form>
    )
}