import React from 'react'

export const constants = {
    headerProps: {
        ncpdp: { title: 'NCPDP' },
        payer: { title: 'Payer Name' },
        bin: { title: 'BIN' },
        pcn: { title: 'PCN' },
        numberOfClaims: { title: '# of Claims', type: 'number', showTotal: true },
        totalPaid: { title: 'Total Paid', type: 'money', showTotal: true },
        average: {
            title: 'Average DSO',
            type: 'number',
            isCellAlert: function (row, column) {
                if (column === "average") {
                    return row[column] > 45
                }
            }
        }
    },
    featureInfo:
        <React.Fragment>
           <p>The Average Days Sales Outstanding values are based upon Payer Check Date. Averages may be altered by changes to the original payment. Common examples include PBM reversal/rebills, clawbacks, and/or LIS Adjustments.</p>
            <b>Average DSO</b>
            <p>Values in red > than 45 days</p>
        </React.Fragment>
}