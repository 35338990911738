import axios from 'axios';
import React, { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import DataTable from '../../grid/DataTable';
import { TotalSalesForm } from './TotalSalesForm';
import { DetailsModal } from '../../grid/DetailsModal';
import { LoadingSpinner } from './../../layout/LoadingSpinner';
import ErrorHandler from './../../core/ErrorHandler';
import Accordion from '../../Accordion';
import { constants } from './TotalSalesConstants';

export function TotalSalesByPayer(props) {

    const { passedProps } = props
    const user = passedProps.user.email
    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState({})

    const [submittedFormData, setSubmittedFormData] = useState({})

    const [data, setData] = useState([])
    const [columns, setColumns] = useState([])

    const [showModal, setShowModal] = useState(false)
    const [detailData, setDetailData] = useState([])
    const [detailColumns, setDetailColumns] = useState([])
    const [detailError, setDetailError] = useState({})

    function handleFormSubmit(e) {
        setIsLoading(true)
        setSubmittedFormData(e)

        passedProps.auth.getAccessToken()
            .then(accessToken => {
                axios.get(`api/TotalSales/GetByPayer`, {
                    params: {
                        user: user,
                        toDate: e.toDate,
                        fromDate: e.fromDate,
                        dateType: e.dateType,
                        paidAmountType: e.paidAmountType,
                        includeCopay: e.includeCopay,
                        omitIgnored: e.omitIgnored,
                        mmids: e.selectedLocation,
                    },
                    headers: { Authorization: `Bearer ${accessToken}` }
                })
                    .then(onSuccess)
                    .catch(onFailure)
            })
            .catch(onFailure)
    }

    function handleCellClick(row, column) {
        const disabledColumns = ['PayerName'];

        if (row[column] != null && !disabledColumns.includes(column) && !column.includes('Count')) {
            setIsLoading(true)
            passedProps.auth.getAccessToken()
                .then(accessToken => {
                    axios.get(`api/TotalSales/GetByPayerDetails`, {
                        params: {
                            user: user,
                            payer: row.PayerName,
                            searchDate: column,
                            dateType: submittedFormData.dateType,
                            omitIgnored: submittedFormData.omitIgnored,
                            mmids: submittedFormData.selectedLocation,
                        },
                        headers: { Authorization: `Bearer ${accessToken}` }
                    })
                        .then(onSuccess)
                        .catch(onFailure)
                })
                .catch(onFailure)
        }
    }

    function onSuccess(response) {
        switch (response.config.url) {
            case "api/TotalSales/GetByPayer": setGridData(response.data); break;
            case "api/TotalSales/GetByPayerDetails": setGridDetailData(response.data); break;
            default: break;
        }
    }

    function onFailure(error) {
        switch (error.response.config.url) {
            case "api/TotalSales/GetByPayerDetails":
                setDetailError(error.response);
                setDetailData([])
                setDetailColumns([])
                setShowModal(true)
                break;
            default:
                setError(error.response);
                setData([])
                setColumns([])
                break;
        }

        setIsLoading(false)
    }

    function setGridData(data) {
        let columns = [];

        if (data.length > 0) {
            let headerProps = { PayerName: { title: 'Payer Name' } }
            Object.keys(data[0]).map(key => {
                return columns.push({
                    accessor: key,
                    title: headerProps[key] ? headerProps[key].title : (key.includes('Count') ? '#' : key),
                    type: headerProps[key] ? null : (key.includes('Count') ? 'number' : 'money'),
                    showTotal: headerProps[key] ? false : true,
                    fixedLeft: headerProps[key] ? true : false,
                    selectable: headerProps[key] || key.includes('Count') ? false : true
                })
            });

            setError({})
        }
        else {
            setError({ status: 201, Message: 'No data found for given params' })
        }
        setData(data)
        setColumns(columns)
        setIsLoading(false)
    }

    function setGridDetailData(data) {
        let columns = [];

        if (data.length > 0) {
            let headerProps = constants.detailGridHeaderProps;
            Object.keys(data[0]).map(key => {
                return columns.push({
                    accessor: key,
                    title: headerProps[key].title,
                    type: headerProps[key].type,
                    showTotal: headerProps[key].showTotal,
                    fixedLeft: headerProps[key].fixedLeft
                })
            });

            setDetailError({})
        }
        else {
            setDetailError({ status: 201, Message: 'No data found for given params' })
        }

        setDetailData(data)
        setDetailColumns(columns)
        setIsLoading(false)
        setShowModal(true)
    }

    return (
        <div className="feature-tab" id="total-sales-by-payer">
            <LoadingSpinner isDataLoading={isLoading} controlsName={'totalSalesByPayer'}/>
            <ErrorHandler error={error} onClose={() => { setError({}) }} />
            <Accordion defaultExpanded label="Search & Filter">
                <Row>
                    <Col>
                        <TotalSalesForm handleFormSubmit={handleFormSubmit} _uid="TSP" />
                    </Col>
                </Row>
            </Accordion>
            <DataTable
                columns={columns}
                rows={data}
                id="total-sales-by-payer"
                exportable filterable totalFooter pagination
                handleCellClick={handleCellClick}
            />
            <DetailsModal title="Total Sales Detail" show={showModal} handleClose={() => setShowModal(false)}>
                <ErrorHandler error={detailError} onClose={() => { setDetailError({}) }} />
                <DataTable
                    columns={detailColumns}
                    rows={detailData}
                    id="total-sales-by-location-details"
                    exportable filterable totalFooter pagination
                />
            </DetailsModal>
        </div>
    )
}