import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { Form, Col, Button } from 'react-bootstrap'
import DatePicker from 'react-datepicker'
import MemberLocationDropdown from '../../form/MemberLocationDropdown'

import FormValidation from '../../form/validation/FormValidation';

export function DateRangeForm(props) {

    const [fromDate, setFromDate] = useState(getFromDate())
    const [toDate, setToDate] = useState(new Date())
    const [reportType, setReportType] = useState('unreconciled')
    const [dateType, setDateType] = useState('dispense')
    const [includeClaimsWithNoPayer, setIncludeClaimsWithNoPayer] = useState(true)
    const [selectedLocations, setSelectedLocations] = useState([])
    const [loaded, setLoaded] = useState(false)

    function getFromDate() {
        let date = new Date()
        date.setDate(date.getDate() - 15)
        return new Date(date)
    }

    useEffect(() => {
        if (!loaded && selectedLocations.length > 0) {
            setLoaded(true)
            submitForm()
        }
    }, [selectedLocations])

    function submitForm() {
        let parameters = {
            toDate: toDate,
            fromDate: fromDate,
            selectedLocations: selectedLocations,
            requiredFields: ["toDate", "fromDate"]
        };

        let validationIssueMessage = FormValidation(parameters);

        if (validationIssueMessage) {
            alert(validationIssueMessage);
        }
        else {

            props.handleFormSubmit({
                toDate: toDate.toLocaleDateString(),
                fromDate: fromDate.toLocaleDateString(),
                reportType: reportType,
                dateType: dateType,
                includeClaimsWithNoPayer: includeClaimsWithNoPayer,
                mmid: selectedLocations.join(',')
            });

        }
    }
    function handleSubmit(event) {
        event.preventDefault()
        submitForm()
    }

    return (
        <Form onSubmit={handleSubmit}>
            <Form.Row className="justify-content-start">
                <Form.Group as={Col} md="auto" className="d-flex flex-column" controlId="dateRangeAgingFormGridFromDate">
                    <Form.Label>From Date</Form.Label>
                    <Form.Control
                        name="fromDate"
                        as={DatePicker}
                        selected={fromDate}
                        onChange={(date) => setFromDate(date)}
                    />
                </Form.Group>
                <Form.Group as={Col} md="auto" className="d-flex flex-column" controlId="dateRangeAgingFormGridToDate">
                    <Form.Label>To Date</Form.Label>
                    <Form.Control
                        name="toDate"
                        as={DatePicker}
                        selected={toDate}
                        onChange={(date) => setToDate(date)}
                    />
                </Form.Group>
                <Form.Group as={Col} md="auto" className="d-flex flex-column" controlId="dateRangeAgingFormGridLocation">
                    <Form.Label>Location (NCPDP)</Form.Label>
                    <Form.Control as={MemberLocationDropdown} onChange={(locations) => setSelectedLocations(locations)} />
                </Form.Group>
                <Form.Group>
                    <Button className="inline-show-result-button" size="sm" type="submit">Show Results</Button>
                </Form.Group>
            </Form.Row>
            <Form.Row className="justify-content-start">
                <Form.Group as={Col} md="auto">
                    <Form.Label>Report Type</Form.Label>
                    <div key="ReportType" className="mb-3 mr-2">
                        <Form.Check custom type="radio" id="ReportTypeDR-1" name="ReportType" label="Total Unreconciled Claims" onChange={(event) => setReportType(event.target.value)} value="unreconciled" defaultChecked />
                        <Form.Check custom type="radio" id="ReportTypeDR-2" name="ReportType" label="Total Unpaid Claims" onChange={(event) => setReportType(event.target.value)} value="unpaid" />
                        <Form.Check custom type="radio" id="ReportTypeDR-3" name="ReportType" label="Claims Pending Receipt of Check" onChange={(event) => setReportType(event.target.value)} value="pending" />
                    </div>
                </Form.Group>
                <Form.Group as={Col} md="auto">
                    <Form.Label>Date Type</Form.Label>
                    <div key="DateType" className="mb-3 mr-2">
                        <Form.Check custom type="radio" id="DateTypeDR-1" name="DateType" label="Dispense date" onChange={(event) => setDateType(event.target.value)} value="dispense" defaultChecked />
                        <Form.Check custom type="radio" id="DateTypeDR-2" name="DateType" label="Transaction date" onChange={(event) => setDateType(event.target.value)} value="transaction" />
                    </div>
                </Form.Group>
                <Form.Group as={Col} md="auto" controlId="DateRangeControllers">
                    <div key="DateRangeControllers" className="mb-3 mt-3 pt-3">
                        <Form.Check custom type="checkbox" id="DateRangeControllers-2" name="includeClaimsWithNoPayer" label="Show Claims with Payers not Setup" onChange={(event) => setIncludeClaimsWithNoPayer(event.target.checked)} defaultChecked />
                    </div>
                </Form.Group>
            </Form.Row>
        </Form>
    )
}

DateRangeForm.propTypes = {
    handleFormSubmit: PropTypes.func.isRequired
}