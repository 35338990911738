import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Row, Col } from 'react-bootstrap';
import CopayAdjustmentManagerForm from './CopayAdjustmentManagerForm';
import Accordion from '../../Accordion';
import { CopayAdjustmentWidget } from './CopayAdjustmentWidget';
import DataTable from '../../grid/DataTable';
import { LoadingSpinner } from './../../layout/LoadingSpinner';
import ErrorHandler from './../../core/ErrorHandler';
import { constants } from './CopayAdjustmentManagerConstants'
export default function CopayAdjustmentManager(props) {

    const { passedProps } = props;
    const user = passedProps.user.email
    const [submittedFormData, setSubmittedFormData] = useState(null)
    const [data, setData] = useState(props.data ? props.data : [])
    const [columns, setColumns] = useState(props.columns ? props.columns : [])
    const [error, setError] = useState({})
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {

        if (submittedFormData) {
            passedProps.auth.getAccessToken()
                .then(accessToken => {
                    axios.get(`api/CopayAdjustmentManager/Get`, {
                        params: {
                            user: user,
                            FromDate: submittedFormData.fromDate,
                            ToDate: submittedFormData.toDate,
                            Locations: submittedFormData.locations,
                            Payers: submittedFormData.payers,
                            DateType: submittedFormData.dateType
                        },
                        headers: { Authorization: `Bearer ${accessToken}` }
                    })
                        .then(onSuccess)
                        .catch(onFailure)
                })
                .catch(onFailure)
        }
    }, [submittedFormData])


    function onSuccess(response) {
        let columns = []
        let data = response.data
        if (data.length > 0) {
            const headerProps = constants.headerProps
            Object.keys(data[0]).map(key => {
                return columns.push({
                    accessor: key,
                    title: headerProps[key].title,
                    type: headerProps[key].type,
                    fixedLeft: headerProps[key].fixedLeft,
                    hidden: headerProps[key].hidden,
                    showTotal: headerProps[key].showTotal,
                    truncatable: headerProps[key] ? headerProps[key].truncatable : false
                })
            })
            setError({})
        }
        else {
            setError({ status: 201, Message: 'No data found for given params' })
        }

        setColumns(columns);
        setData(data);
        setIsLoading(false)
    }

    function onFailure(error) {
        setIsLoading(false)
        setError(error.response)
        setData([])
    }
    function handleFormSubmit(e) {
        setData([])
        setIsLoading(true)
        setSubmittedFormData(e)
    }



    return (
        <div className="feature">
            <LoadingSpinner isDataLoading={isLoading} controlsName={'RemitRcptVerification'} />
            <ErrorHandler error={error} onClose={() => { setError({}) }} />

            <Accordion defaultExpanded label="Search & Filter">
                <Row>
                    <Col>
                        <CopayAdjustmentManagerForm handleFormSubmit={handleFormSubmit} />
                    </Col>
                    <Col>
                        <CopayAdjustmentWidget
                            title='Payer Initiated Pt. Copay Adjustments'
                            iFrameId='sisense-cam-frame'
                            filters={{}}
                            settings={{ showToolbar: false, showLeftPane: false, showRightPane: false }}
                            hidden={false}
                        />
                    </Col>
                </Row>
            </Accordion>
            <DataTable
                columns={columns}
                rows={data}
                id="CopayAdjustmentManager"
                exportable filterable totalFooter pagination
            />
        </div>
    );

}