import axios from 'axios';
import React, { useState } from 'react';

import { DateRangeForm } from './DateRangeForm';
import { Row, Col, Button, Form, Container } from 'react-bootstrap';
import DataTable from '../../grid/DataTable';
import ErrorHandler from './../../core/ErrorHandler';
import { DetailsModal } from '../../grid/DetailsModal';
import { LoadingSpinner } from './../../layout/LoadingSpinner';
import Accordion from '../../Accordion';
import { IgnoreModal } from '../../pages/Aging/IgnoreModal';
import SingleSelectDropdown from '../../SingleSelectDropdown';
import { CustomAction } from '../../grid/CustomActionBarAction'

export function DateRange(props) {

    const { passedProps } = props
    const user = passedProps.user.email
    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState({})

    const [data, setData] = useState([])
    const [columns, setColumns] = useState([])
    const [submittedFormData, setSubmittedFormData] = useState({})
    const [showModal, setShowModal] = useState(false)
    const [detailError, setDetailError] = useState({})
    const [detailData, setDetailData] = useState([])
    const [detailColumns, setDetailColumns] = useState([])
    const [detailSummaryData, setDetailSummaryData] = useState([])
    const [detailSummaryColumns, setDetailsSummaryColumns] = useState([])
    const [detailGridTitle, setDetailsGridTitle] = useState('')
    const [showIgnoreModal, setShowIgnoreModal] = useState(false)
    const [ignoreError, setIgnoreError] = useState({})
    const [selectedIgnoreOption, setSelectedIgnoreOption] = useState('')
    const [ignoreNote, setIgnoreNote] = useState('')
    const [selectedNCPDP, setSelectedNCPDP] = useState('')
    const [selectedRX, setSelectedRX] = useState('')
    const [ignoreAll, setIgnoreAll] = useState(false)

    function handleFormSubmit(e) {
        setIsLoading(true)
        setSubmittedFormData(e)

        passedProps.auth.getAccessToken()
            .then(accessToken => {
                axios.get(`api/Aging/GetDateRangeAgingSummary`, {
                    params: {
                        user: user,
                        mmids: e.mmid,
                        dateType: e.dateType,
                        fromDate: e.fromDate,
                        toDate: e.toDate,
                        reportType: e.reportType,
                        includeClaimsWithNoPayer: e.includeClaimsWithNoPayer,
                    },
                    headers: { Authorization: `Bearer ${accessToken}` }
                })
                    .then(onSuccess)
                    .catch(onFailure)
            })
            .catch(onFailure)
    }
    function handleCellClick(row, column) {
        if (column == 'TotalOutstanding') {
            setIsLoading(true);
            passedProps.auth.getAccessToken()
                .then(accessToken => {
                    axios.get('api/AgingDetails/GetByDateRangeAging', {
                        params: {
                            user: user,
                            mmids: submittedFormData.mmid,
                            AgeAsOf: null,
                            PBID: row.PBID,                            
                            fromDate: submittedFormData.fromDate,
                            toDate: submittedFormData.toDate,
                            dateType: submittedFormData.dateType,
                            outstandingType: submittedFormData.reportType,
                        },
                        headers: { Authorization: `Bearer ${accessToken}` }
                    })
                        .then((response) => {
                            response.config.row = row;
                            response.config.column = column;
                            onSuccess(response)
                        })
                        .catch(onFailure)
                })
                .catch(onFailure)
        }
    }
    //Triggers when user clicks cell on detail grid 
    function handleDetailCellClick(row, column) {
        if (row[column] && column === 'ignore') {

            setSelectedNCPDP(row.NCPDP);
            setSelectedRX(row.RxNumber);
            setShowIgnoreModal(true)
        }
    }

    function onSuccess(response) {
        switch (response.config.url) {
            case 'api/Aging/GetDateRangeAgingSummary': setGridData(response.data); break;
            case 'api/AgingDetails/GetByDateRangeAging': setGridDetailData(response); break;
            default: break;
        }
        setIsLoading(false);
    }

    function onFailure(error) {
        switch (error.response.config.url || error.config.url) {
            case 'api/Aging/GetDateRangeAgingSummary':
                setError(error.response)
                setColumns([])
                setData([])
                break;
            case 'api/AgingDetails/GetByDateRangeAging':
                setDetailError(error.response)
                setDetailsSummaryColumns([])
                setDetailSummaryData([])
                setDetailColumns([])
                setDetailData([])
                setShowModal(true)
                break;
            case 'api/AgingDetails/IgnoreClaims':
                setIgnoreError(error.response)
                setShowIgnoreModal(true)
                break;
            default:
                setError(error.response)
                setColumns([])
                setData([])
                break;
        }
        setIsLoading(false)
        return;
    }

    function setGridData(data) {
        let columns = [];

        if (data.length > 0) {
            let headerProps = {
                PBID: { title: 'PBID', hidden: true },
                Payer: { title: 'Payer Name' },
                Adjudicated: { title: 'Adjudicated', type: 'money', showTotal: true },
                Paid: { title: 'Paid', type: 'money', showTotal: true },
                RxCount: { title: 'Rx Count', type: 'number', showTotal: true },
                TotalOutstanding: { title: 'Total Outstanding', type: 'money', showTotal: true, selectable: true }
            }
            Object.keys(data[0]).map(key => {
                    columns.push({
                        accessor: key,
                        title: headerProps[key] ? headerProps[key].title : (key.includes('+') ? `${key} days and over` : `${key} days`),
                        type: headerProps[key] ? headerProps[key].type : 'money',
                        showTotal: headerProps[key] ? headerProps[key].showTotal : false,
                        selectable: headerProps[key] ? headerProps[key].selectable : false,
                        fixedLeft: (headerProps[key].fixedLeft) ? headerProps[key].fixedLeft : false,
                        hidden: headerProps[key].hidden
                    })
            });
            setError({})
        }
        else {
            setError({ status: 201, Message: 'No data found for given params' })
        }
        setColumns(columns)
        setData(data)
    }

    function setGridDetailData(response) {
        let columns = [];
        let detailTitle = '';
        let detailSummaryData = [];
        let detailSummaryColumns = [];
       
        if (response.data.length > 0) {
            let headerProps = {
                ignore: { title: 'Actions', fixedLeft: true, selectable: true },
                claimId: { title: 'ClaimId', hidden: true },
                ncpdp: { title: 'NCPDP', fixedLeft: true },
                rxNumber: { title: 'Rx #', fixedLeft: true },
                dispensedDate: { title: 'Dispensed Date', fixedLeft: true, type: 'date' },
                transactionDate: { title: 'Transaction Date', type: 'date' },
                bin: { title: 'BIN' },
                pcn: { title: 'PCN' },               
                claimNumber: { title: 'Claim #' },
                cardholderId: { title: 'Cardholder ID' },
                adjudicatedCoPay: { title: 'Adjudicated Copay', type: 'money', showTotal: true },
                promiseToPay: { title: 'Promise to Pay', type: 'money', showTotal: true },
                remittancePaid: { title: 'Remittance Paid', type: 'money', showTotal: true },
                outstanding: { title: 'Outstanding', type: 'money', showTotal: true }
            }

            Object.keys(response.data[0]).map(key => {
                if (key != 'claimId') {                    
                    columns.push({
                        accessor: key,
                        title: headerProps[key].title,
                        type: headerProps[key].type,
                        showTotal: headerProps[key].showTotal,
                        fixedLeft: headerProps[key].fixedLeft,
                        selectable: headerProps[key].selectable,
                        hidden: headerProps[key].hidden || false
                    })
                }
            });


            let totalAdjudicated = 0;
            let totalPaid = 0;
            for (let i = 0; i < response.data.length; i++) {
                totalAdjudicated += response.data[i].promiseToPay;
                totalPaid += response.data[i].remittancePaid
            }

            response.data.forEach(row => {
                row["selected"] = false;
                //row["Action"] = getActionCell(row['ClaimId'], customDataObject);
            })

            detailSummaryData.push({
                Payer: response.config.row.Payer,
                ClaimCount: response.data.length,
                Adjudicated: totalAdjudicated,
                Paid: totalPaid,
                Outstanding: totalAdjudicated - totalPaid
            });

            let detailSummaryHeaderProps = {
                Payer: { title: 'Payer Name', type: 'text' },
                ClaimCount: { title: 'Claim Count', type: 'number' },
                Adjudicated: { title: 'Adjudicated', type: 'money' },
                Paid: { title: 'Paid', type: 'money' },
                Outstanding: { title: 'TotalOutstanding', type: 'money' }
            }

            Object.keys(detailSummaryData[0]).map(key => {
                detailSummaryColumns.push({
                    accessor: key,
                    title: detailSummaryHeaderProps[key].title,
                    type: detailSummaryHeaderProps[key].type,
                });
            });
            if (response.config.column != 'TotalOutstanding') { detailTitle = response.config.row.Payer + ', ' + response.config.column + ' days old'; }
            else { detailTitle = response.config.row.Payer + ', ' + 'Total Outstanding'; }
            setDetailError({})
        }
        else {
            setDetailError({ status: 201, Message: 'No data found for given params' })
        }

        setDetailData(response.data)
        setDetailColumns(columns)
        setDetailsGridTitle(detailTitle)
        setDetailSummaryData(detailSummaryData)
        setDetailsSummaryColumns(detailSummaryColumns)

        setShowModal(true)
    }

    //Called when user clicks save in ignore details modal 
    async function handleSaveIgnoreClick() {
        setShowIgnoreModal(false)

        if (selectedIgnoreOption && !ignoreAll) {
            var objIndex = detailData.findIndex((obj => obj.NCPDP == selectedNCPDP && obj.RxNumber == selectedRX))
            var strClid = detailData[objIndex].claimId

            try {
                const accessToken = await passedProps.auth.getAccessToken();
                const response = await axios.get('api/AgingDetails/IgnoreClaims', {
                    params: {
                        user: user,
                        IgnoreId: selectedIgnoreOption,
                        IgnoreNote: ignoreNote,
                        ClaimIds: strClid
                    },
                    headers: { Authorization: `Bearer ${accessToken}` }
                });

                resetGrid(response)
            }
            catch (e) {
                setIgnoreError({ status: 501, Message: '' })
                setShowIgnoreModal(true)
            }

        }
        else if (selectedIgnoreOption && ignoreAll) {
            var strClids = ""
            detailData.forEach(function (obj) { if (obj.selected) { strClids += obj.claimId + "," } }); //get all clids that are being ignored
            strClids.slice(0, -1); // remove last delim

            try {
                const accessToken = await passedProps.auth.getAccessToken();
                const response = await axios.get('api/AgingDetails/IgnoreClaims', {
                    params: {
                        user: user,
                        IgnoreId: selectedIgnoreOption,
                        IgnoreNote: ignoreNote,
                        PY_PYID: "",
                        ClaimIds: strClids
                    },
                    headers: { Authorization: `Bearer ${accessToken}` }
                });

                resetGrid(response)
            }
            catch (e) {
                setIgnoreError({ status: 501, Message: '' })
                setShowIgnoreModal(true)
            }

        }
    }

    function resetGrid(objIndex) {
        if (ignoreAll) {
            setIgnoreNote('')
            let resetDetailData = detailData.filter(claim => { return !claim.selected })
            setDetailData([...resetDetailData])
            setIgnoreAll(false);
        }
        else {
            let resetDetailData = detailData
            resetDetailData.splice(objIndex, 1)
            setDetailData([...resetDetailData]);
        }

        setIgnoreActionCompletedMessage();
    }


    function setIgnoreActionCompletedMessage() {
        setDetailError({ status: 200, Message: 'Selected items have been queued to be ignored!' })
        setTimeout(() => { setDetailError({}) }, 3600);

    }

    function getNewRowSelectedValue(rows) {
        if (rows.length > 1) {
            return !rows.every(x => {
                return x.selected === true
            })
        }
        return !rows[0].selected
    }

    function handleRowSelected(rows) {
        
        let newData = [...detailData]
        let selectedValue = getNewRowSelectedValue(rows)

        rows.forEach(row => {
            let index = newData.findIndex((x => x.claimId == row.claimId))
            newData[index].selected = selectedValue
        })
        setDetailData(newData)
    }

    function ignoreAllSelected() {
        setIgnoreAll(true)
        setSelectedNCPDP("All Selected");
        setSelectedRX("All Selected");
        setSelectedIgnoreOption("")
        setIgnoreNote("")
        setShowIgnoreModal(true)
    }

    function getCustomActions() {
        return (
            <CustomAction label="Ignore Selected" onClick={ignoreAllSelected} />
        )
    }

    const IgnoreOptions = [
        { value: 1, label: 'WO - RTS: HAVE RX RECEIPT' },
        { value: 2, label: 'RTS: CC OVER $75.00' },
        { value: 3, label: 'RTS: UNDER $75.00' },
        { value: 4, label: 'WO - DUPLICATE' },
        { value: 5, label: 'PAID PREVIOUS RA' },
        { value: 6, label: 'DROPPED' },
        { value: 7, label: 'PDR or APR' },
        { value: 8, label: 'DIFFERENCE /REBILL' },
        { value: 9, label: 'AUDIT APPROVED/RECOUP' },
        { value: 10, label: 'AUDIT NOT APPROVED' },
        { value: 11, label: 'RDC or CNP' },
        { value: 12, label: 'ADJ CLAIM BY PLAN' },
        { value: 13, label: 'OVERCHARGE ON COPAY' },
        { value: 14, label: 'RINGING ERROR' },
        { value: 15, label: 'W/O LAST RA' },
        { value: 16, label: 'OVER $75 CANCELED RX' },
        { value: 17, label: 'WO - PAID ON OTHER PLAN/PRIMARY' },
        { value: 18, label: 'PAID NEXT RA' },
        { value: 19, label: 'WO - OTHER' },
        { value: 20, label: 'EXP - FEES' },
        { value: 21, label: 'WO - OCER/UNDER BILLING ISSUE' },
        { value: 22, label: 'WO - AUDIT' },
        { value: 23, label: 'WO - SYSTEM ERROR' },
        { value: 24, label: 'EXP - TECHNICAL BILLING ISSUE' },
        { value: 25, label: 'IGN - NOT SETUP FOR 835s' },
        { value: 26, label: 'PAID ON PHYSICAL CHECK' },
        { value: 27, label: 'IGN - OTHER' },
        { value: 28, label: 'IGN - SYSTEM ERROR' },
        { value: 51, label: 'MEDICAL BENEFITS' },
        { value: 52, label: 'WRITE-OFF' },
        { value: 53, label: 'COST-SHARE PAYMENTS' },
        { value: 54, label: 'Forwarding Balance Ignore' },
        { value: 55, label: '100% Copay' },
        { value: 90, label: 'PATIENT RESPONSIBILITY' },
        { value: 91, label: 'VALID CLAIM NOT RECEIVED BY NET-RX' },
        { value: 92, label: 'SALES TAX' },
        { value: 93, label: 'LIC Adjustment' },
        { value: 94, label: 'REVERSAL' },
        { value: 95, label: 'PAID ON PAPER EOB' },
        { value: 98, label: 'Manual Ignore' },
        { value: 99, label: 'Overpaid Ignore' },
    ]
    return (
        <div className="feature-tab" id="Date-Range">
            <LoadingSpinner isDataLoading={isLoading} controlsName={'agingDateRange'} />
            <ErrorHandler error={error} onClose={() => { setError({}) }} />
            <Accordion defaultExpanded label="Search & Filter">
                <DateRangeForm handleFormSubmit={handleFormSubmit} />
            </Accordion>      
            <DataTable
                columns={columns}
                rows={data}
                id="date-range-aging"
                exportable filterable totalFooter pagination
                handleCellClick={handleCellClick}
            />
            <DetailsModal title="Payer Summary" show={showModal} handleClose={() => setShowModal(false)}>
                <ErrorHandler error={detailError} onClose={() => { setDetailError({}) }} />
                <Row>
                    <Col>
                        <DataTable
                            columns={detailSummaryColumns}
                            rows={detailSummaryData}
                            id="Aging-summary-details-table"       
                        />
                    </Col>
                    <Col/>
                </Row>
                <br />
                <h2>Aging Report</h2>
                <h4>{detailGridTitle}</h4>
                <DataTable
                    columns={detailColumns}

                    rows={detailData}
                    id="Aging-details-table"
                    exportable filterable totalFooter pagination selectableRows
                    handleCellClick={handleDetailCellClick}
                    handleRowSelected={(row) => handleRowSelected(row)}
                    customActionBarActions={getCustomActions()}
                />
            </DetailsModal>
            <IgnoreModal title="Ignore Details" show={showIgnoreModal} handleClose={() => { setShowIgnoreModal(false), setIgnoreAll(false) }}>
                <ErrorHandler error={ignoreError} onClose={() => { setIgnoreError({}) }} />
                <Container>
                    <Row>
                        <Col> NCPDP </Col>
                        <Col> Rx #</Col>
                    </Row>
                    <Row>
                        <Col>{selectedNCPDP}</Col>
                        <Col>{selectedRX}</Col>
                    </Row>
                    <br />
                    <Row>
                        <Col> Ignore Code  </Col>
                    </Row>
                    <Row md={1}>
                        < Col > <SingleSelectDropdown options={IgnoreOptions} onChange={(selectedIgnoreOption) => { setSelectedIgnoreOption(selectedIgnoreOption) }} /> </ Col>
                    </Row>
                    <br />
                    <Row md={1}>
                        <Col>
                            <Form.Group controlId="txtIgnoreNote">
                                <Form.Label>  Comments  </Form.Label>
                                <Form.Control as="textarea" rows='5' placeholder="Type your message here..." value={ignoreNote} onChange={(event) => { setIgnoreNote(event.target.value) }} > </Form.Control>
                            </Form.Group>
                        </Col>
                    </Row>
                    <br />
                    <Row >
                        <Col ><Button variant="secondary" onClick={() => setShowIgnoreModal(false)} > Cancel </Button></Col>
                        <Col>
                            <Button className='primary-button' onClick={handleSaveIgnoreClick}> Save </Button>
                        </Col>
                    </Row>
                </Container>
            </IgnoreModal>
        </div>
        
    )
}