import React, { useRef, useState } from 'react';
import './ActionCell.css'
import { Popover, Overlay } from 'react-bootstrap';
//NOTE: It's totally possible to replace the "props.onClick" here with some different onClick functionality, or just completely change the hooks below
export default function ActionCell(props) {

    return (
        <span>
            <View handleClick={props.onClick} rowData={props.rowData} rowId={props.rowId} /> &nbsp;
            <Download handleClick={props.onClick} rowData={props.rowData} rowId={props.rowId} /> &nbsp;
            <Action handleClick={props.onClick} rowData={props.rowData} rowId={props.rowId} />
        </span>
    )
}

function View(props) {
    const { handleClick } = props
    const [show, setShow] = useState(false)

    const popover = (
        <Popover id="action-cell-popover">
            <Popover.Content>
                <div className="selectable" onClick={() => popoverClick('paymentExceptions')}>Payment Exceptions</div>
                <div className="selectable" onClick={() => popoverClick('feeAndAdjustmentResearch')}>Fee and Adjustment Research</div>
            </Popover.Content>
        </Popover>
    )

    function popoverClick(accessor) {
        setShow(false);
        handleClick(accessor);
    }

    const target = useRef(null);

    return (
        <React.Fragment>
            <span className="action-cell" data-testid={"view-" + props.rowId}>
                <span onClick={() => setShow(!show)} ref={target} ><i className="fa fa-eye"></i> View</span>
                <Overlay rootClose target={target.current} show={show} placement="bottom" onHide={() => setShow(false)}>
                    {popover}
                </Overlay>
            </span>


        </React.Fragment>
    )


}

function Download(props) {
    const { handleClick } = props
    const [show, setShow] = useState(false)

    //NOTE TODO: I think I fixed the values here so that the props here contains relevant data, it may be possible to use these values to make the data calls?
    //but that would involve NOT using the handleCellClick() method in RemitRcptVerification.js, so maybe not the right way to go?
    //console.log(props);

    const popover = (
        <Popover id="action-cell-popover">
            <Popover.Content>
                <div className="selectable" onClick={() => popoverClick('excel')}>Excel Spreadsheet (.xlsx)</div>
                <div className="selectable" onClick={() => popoverClick('pdf')}>Portable Document Format (.pdf)</div>
            </Popover.Content>
        </Popover>
    )

    function popoverClick(accessor) {
        setShow(false);
        handleClick(accessor);
    }

    const target = useRef(null);

    return (
        <React.Fragment>
            <span className="action-cell" data-testid={"download-" + props.rowId}>
                <span onClick={() => setShow(!show)} ref={target} ><i className="fa fa-download"></i> Download</span>
                <Overlay rootClose target={target.current} show={show} placement="bottom" onHide={() => setShow(false)}>
                    {popover}
                </Overlay>
            </span>


        </React.Fragment>
    )
}

function Action(props) {
    const { handleClick, rowData } = props
    const [show, setShow] = useState(false);

    let possibleViewArchiveHistoryRow = rowData.archived === true && (
        <div className="selectable" onClick={() => popoverClick('viewArchiveHistory')}>View Archive History</div>
    ) 

    let possibleArchiveThisRecordRow = rowData.status === 'Pending' && (
        <div className={'selectable'} onClick={() => popoverClick('archiveRecord')}>Archive this Record </div>
    ) 

    let possibleRestorePendingRow = rowData.status === 'Archived' && (
        <div className={'selectable'} onClick={() => popoverClick('restorePending')}>Restore Pending </div>
    )

    let possibleEditManualRemittanceRow = rowData.status === 'Archived' && rowData.isManualCheck && (
        <div className={'selectable'} onClick={() => popoverClick('editManualRemittance')}>Edit Manual Remittance </div>
    )

    const popover = (
        <Popover id="action-cell-popover">
            <Popover.Content>
                {possibleViewArchiveHistoryRow}
                {possibleArchiveThisRecordRow}
                {possibleRestorePendingRow}
                {possibleEditManualRemittanceRow}
            </Popover.Content>
        </Popover>
    )

    function popoverClick(accessor) {
        setShow(false);
        handleClick(accessor);
    }

    const target = useRef(null);

    return (
        <React.Fragment>
            <span className="action-cell" data-testid={"action-" + props.rowId}>
                <span onClick={() => setShow(!show)} ref={target} ><i className="fa fa-share-square"></i> Actions</span>
                <Overlay rootClose target={target.current} show={show} placement="bottom" onHide={() => setShow(false)}>
                    {popover}
                </Overlay>
            </span>


        </React.Fragment>
    )

}