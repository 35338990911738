import React from 'react';
import DataTable from '../../grid/DataTable';

import { constants } from './FeeAndAdjustmentResearchConstants'
import Accordion from '../../Accordion';

import './FeeAndAdjustmentDetailsGroup.css'
export default function FeeAndAdjustmentDetailsGroup(props) {
    const { data } = props

    const summaryColumns = getSummaryColumns(data.summary)
    const columns = getColumns(data.details)

    function getSummaryColumns(rows) {
        const headerProps = constants.detailSummaryHeaderProps

        const columns = []
        Object.keys(rows).map(key => {
            return columns.push({
                accessor: key,
                title: headerProps[key].title,
                type: headerProps[key].type
            })
        })

        return columns
    }

    function getColumns(rows) {
        const headerProps = constants.detailHeaderProps

        const columns = []
        Object.keys(rows[0]).map(key => {
            return columns.push({
                accessor: key,
                title: headerProps[key].title,
                type: headerProps[key].type
            })
        })
        return columns
    }

    return (
        <React.Fragment>
            <div className='fee-and-adjustment-detail-summary-wrapper'>
                <DataTable
                    columns={summaryColumns}
                    rows={[data.summary]}
                    id="fee-and-adjustment-detail-summary"
                />
            </div>
            <Accordion position="right">
                <DataTable
                    columns={columns}
                    rows={data.details}
                    id="fee-and-adjustment-detail"
                    pagination filterable
                />
            </Accordion>
        </React.Fragment>

    )
}