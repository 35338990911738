import React from 'react';

import { CurrentAging } from './CurrentAging';
import { AsOfAging } from './AsOfAging';
import { DateRange } from './DateRange';
import TabBasedFeature from '../../layout/TabBasedFeature';

import { Col } from 'react-bootstrap';
export default function Aging(props) {
    let tabs = [
        { key: 'currentAging', title: 'Current Aging', Component: CurrentAging},
        { key: 'dateRange', title: 'Date Range', Component: DateRange},
        { key: 'asOfAging', title: '"As Of" Aging', Component: AsOfAging},
    ]

    return (
        <TabBasedFeature tabs={tabs} defaultActiveKey="currentAging" passedProps={props.passedProps} />
    );
}