import React from 'react';

export default function DetailActionCell(props) {

    return (
        <span>
            <PrescriptionDetail clid={props.key} handleClick={props.onClick} /> &nbsp;&nbsp;&nbsp;
            <Ignore clid={props.key} handleClick={props.onClick} />
        </span>
    )

}

function PrescriptionDetail(props) {
    const { clid, handleClick } = props;
    return (
        <span className="action-cell" onClick={() => handleClick("prescriptionDetail")} data-testid={"action-" + clid}>
                Prescription Detail
            </span> 
            )
}
function Ignore(props) {
    const { clid, handleClick } = props;
    return (
        <span className="action-cell" onClick={() => handleClick("ignore")} data-testid={"action-" + clid}>
            Ignore
        </span>
    )
}
