import React from 'react';
import { Row, Col } from 'react-bootstrap';

import './FeeAndAdjustmentDetailGrandTotal.css'

export default function FeeAndAdjustmentDetailGrandTotal(props) {
    const { data } = props

    const total = data.reduce((total, summary) => total += summary.adjustmentAmount, 0)

    return (
        <Row className='fee-and-adjustment-detail-grand-total-row'>
            <Col>
                Grand Total
            </Col>
            <Col>
                Adjustments: {total.toLocaleString(undefined, { style: 'currency', currency: 'USD', minimumFractionDigits: 2, maximumFractionDigits: 2 })}
            </Col>
        </Row>

    )
}