import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { Form, Col, Button } from 'react-bootstrap'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import MultiSelectDropdown from '../../MultiSelectDropdown';
import MemberLocationDropdown from '../../form/MemberLocationDropdown'

import FormValidation from '../../form/validation/FormValidation';

export function AsOfAgingForm(props) {

    const [toDate, setToDate] = useState(new Date())
    const [selectedLocations, setSelectedLocations] = useState([])
    const [loaded, setLoaded] = useState(false)

    useEffect(() => {
        if (!loaded && selectedLocations.length > 0) {
            setLoaded(true)
            submitForm();
        }
    }, [selectedLocations])

    function submitForm() {
        let parameters = {
            toDate: toDate,
            selectedLocations: selectedLocations,
            requiredFields: ["toDate"]
        };

        let validationIssueMessage = FormValidation(parameters);

        if (validationIssueMessage) {
            alert(validationIssueMessage);
        }
        else {
            props.handleFormSubmit({
                toDate: toDate.toLocaleDateString(),
                mmids: selectedLocations.join(',')
            })
        }
    }

    function handleFormSubmit(e) {
        e.preventDefault();

        submitForm();
    }

    return (
        <Form onSubmit={handleFormSubmit}>
            <Form.Row className="justify-content-start">
                <Form.Group as={Col} md="auto" className="d-flex flex-column" controlId="asOfAgingFormGridLocation">
                    <Form.Label>Location (NCPDP)</Form.Label>
                    <Form.Control as={MemberLocationDropdown} onChange={(locations) => setSelectedLocations(locations)} />
                </Form.Group>
                <Form.Group as={Col} md="auto" className="d-flex flex-column" controlId="asOfAgingFormGridToDate">
                    <Form.Label>To Date</Form.Label>
                    <Form.Control
                        name="toDate"
                        as={DatePicker}
                        selected={toDate}
                        onChange={(date) => setToDate(date)}
                    />
                </Form.Group>
                <Form.Group>
                    <Button className="inline-show-result-button" size="sm" type="submit">Show Results</Button>
                </Form.Group>

            </Form.Row>
        </Form>
    )
}

AsOfAgingForm.propTypes = {
    handleFormSubmit: PropTypes.func.isRequired
}