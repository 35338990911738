import React from 'react';

export default function PrescriptionDetailActionCell(props) {

    return (
        <span>
            <ExcelExport clid={props.key} handleClick={props.onClick} /> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <PdfExport clid={props.key} handleClick={props.onClick} />
        </span>
    )


}

function ExcelExport(props) {
    const { key, handleClick } = props;
    return (
        <span className="action-cell" onClick={() => handleClick("excelExport")} data-testid={"action-" +key}>
            Excel Spreadsheet .xlsx 
            </span>
    )

}

function PdfExport(props) {
    const { key, handleClick } = props;
    return (
        <span className="action-cell" onClick={() => handleClick("pdfExport")} data-testid={"action-" + key}>
            Portable Document Format .pdf
            </span>
    )

}