import axios from 'axios';
import React, { useState } from 'react';
import Accordion from '../../Accordion';
import { Row, Col } from 'react-bootstrap';
import DataTable from '../../grid/DataTable';
import { AsOfAgingForm } from './AsOfAgingForm';
import ErrorHandler from './../../core/ErrorHandler';
import { DetailsModal } from '../../grid/DetailsModal';
import { LoadingSpinner } from './../../layout/LoadingSpinner';

export function AsOfAging(props) {

    const { passedProps } = props
    const user = passedProps.user.email
    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState({})

    const [submittedFormData, setSubmittedFormData] = useState({})

    const [data, setData] = useState([])
    const [columns, setColumns] = useState([])
    const [showModal, setShowModal] = useState(false)
    const [detailError, setDetailError] = useState({})
    const [detailData, setDetailData] = useState([])
    const [detailColumns, setDetailColumns] = useState([])
    const [detailSummaryData, setDetailSummaryData] = useState([])
    const [detailSummaryColumns, setDetailsSummaryColumns] = useState([])
    const [detailGridTitle, setDetailsGridTitle] = useState('')

    function handleFormSubmit(e) {
        setIsLoading(true)
        setSubmittedFormData(e)

        passedProps.auth.getAccessToken()
            .then(accessToken => {
                axios.get(`api/Aging/GetAsOfAging`, {
                    params: {
                        user: user,
                        asOfDate: e.toDate,
                        mmids: e.mmids
                    },
                    headers: { Authorization: `Bearer ${accessToken}` }
                })
                    .then(onSuccess)
                    .catch(onFailure)
            })
            .catch(onFailure)
    }

    function handleCellClick(row, column) {
        const disabledColumns = ['Payer', 'Bin', 'TotalOutstanding'];       
      
        if (row[column] && disabledColumns.indexOf(column) === -1) {
            setIsLoading(true);            
            passedProps.auth.getAccessToken()
                .then(accessToken => {
                    axios.get('api/AgingDetails/GetByAsOfAging', {
                        params: {
                            user: user,
                            pbid: row.PBID,
                            asOfDate: submittedFormData.toDate,
                            mmids: submittedFormData.mmids,                            
                            bin: row.Bin,
                            period: column
                        },
                        headers: { Authorization: `Bearer ${accessToken}` }
                    })
                        .then((response) => {
                            response.config.row = row;
                            response.config.column  = column;
                            onSuccess(response);
                        })
                        .catch(onFailure)
                })
                .catch(onFailure)
        }
    }

    function onSuccess(response) {
        switch (response.config.url) {
            case 'api/Aging/GetAsOfAging': setGridData(response.data); break;
            case 'api/AgingDetails/GetByAsOfAging': setGridDetailData(response); break;
            default: break;
        }
        setIsLoading(false);
    }

    function onFailure(error) {
        console.log(error)
        switch (error.response.config.url) {
            case 'api/Aging/GetAsOfAging':
                setError(error.response)
                setColumns([])
                setData([])
                break;
            case 'api/AgingDetails/GetByAsOfAging':
                setDetailError(error.response)
                setDetailsSummaryColumns([])
                setDetailSummaryData([])
                setDetailColumns([])
                setDetailData([])
                setShowModal(true);
                break;
            default:
                setError(error.response)
                setColumns([])
                setData([])
                break;
        }
        setIsLoading(false)
        return;
    }

    function setGridData(data) {
        let columns = [];      
        if (data.length > 0) {
            let headerProps = {             
                PBID: { title: 'PBID', type: 'int', hidden: true },
                Payer: { title: 'Payer Name', type: 'text' },
                Bin: { title: 'BIN', type: 'text' },
                TotalOutstanding: { title: 'Total Outstanding', type: 'money', showTotal: true }
            }
            Object.keys(data[0]).map(key => {
                return columns.push({
                    accessor: key,
                    title: headerProps[key] ? headerProps[key].title : (key.includes('+') ? `${key} days and over` : `${key} days`),
                    type: headerProps[key] ? headerProps[key].type : 'money',
                    showTotal: headerProps[key] ? headerProps[key].showTotal : true,
                    selectable: headerProps[key] ? headerProps[key].selectable : true,
                    hidden: headerProps[key] ? headerProps[key].hidden : false,
                })
            });
            setError({})
        }
        else {
            setError({ status: 201, Message: 'No data found for given params' })
        }
        setColumns(columns);
        setData(data);
    }

    function setGridDetailData(response) {
        let columns = [];
        let detailTitle = '';
        let detailSummaryData = [];
        let detailSummaryColumns = [];
        if (response.data.length > 0) {
            let headerProps = {
                ncpdp: { title: 'NCPDP', fixedLeft: true},
                rxNumber: { title: 'Rx #', fixedLeft: true },
                dispensedDate: { title: 'Dispensed Date', fixedLeft: true, type: 'date' },
                transactionDate: { title: 'Transaction Date', type: 'date'},
                bin: { title: 'BIN' },
                pcn: { title: 'PCN' },
                claimNumber: { title: 'Claim #' },
                cardholderId: { title: 'Cardholder ID' },
                adjudicatedCoPay: { title: 'Adjudicated Copay', type: 'money', showTotal: true },
                promiseToPay: { title: 'Promise to Pay', type: 'money', showTotal: true },
                remittancePaid: { title: 'Remittance Paid', type: 'money', showTotal: true },
                outstanding: { title: 'Outstanding', type: 'money', showTotal: true }
            }
            Object.keys(response.data[0]).map(key => {
                return columns.push({
                    accessor: key,
                    title: headerProps[key].title,
                    type: headerProps[key].type,                       
                    showTotal: headerProps[key].showTotal,
                    fixedLeft: headerProps[key].fixedLeft,
                    selectable: headerProps[key].selectable
                })
            });

            ///calculate the totalpromistopay and RemittancePaid
            let totalAdjudicated = 0;
            let totalPaid = 0;
            for (let i = 0; i < response.data.length; i++) {
                totalAdjudicated += response.data[i].promiseToPay;
                totalPaid += response.data[i].remittancePaid
            }

            detailSummaryData.push({
                Payer: response.config.row.Payer,
                ClaimCount: response.data.length,
                Adjudicated: totalAdjudicated,
                Paid: totalPaid,
                Outstanding: totalAdjudicated - totalPaid
            });

            let detailSummaryHeaderProps = {
                Payer: { title: 'Payer Name', type: 'text' },
                ClaimCount: { title: 'Claim Count', type: 'number' },
                Adjudicated: { title: 'Adjudicated', type: 'money' },
                Paid: { title: 'Paid', type: 'money' },
                Outstanding: { title: 'TotalOutstanding', type: 'money' }
            }

            Object.keys(detailSummaryData[0]).map(key => {
                return detailSummaryColumns.push({
                    accessor: key,
                    title: detailSummaryHeaderProps[key].title,
                    type: detailSummaryHeaderProps[key].type,
                    showTotal: detailSummaryHeaderProps[key].showTotal,
                    fixedLeft: detailSummaryHeaderProps[key].fixedLeft,
                })
            });

            detailTitle = 'BIN ' + response.config.row.Bin + ', ' + response.config.row.Payer + ', ' + response.config.params.period + ' days old';
            setDetailError({})
        }
        else {
            setDetailError({ status: 201, Message: 'No data found for given params' })
        }
        setDetailData(response.data)
        setDetailColumns(columns)
        setDetailsGridTitle(detailTitle)
        setDetailSummaryData(detailSummaryData)
        setDetailsSummaryColumns(detailSummaryColumns)

        setShowModal(true)
    }



    return (
        <React.Fragment>
        <LoadingSpinner isDataLoading={isLoading} controlsName={'agingAsOfAging'} />
        <div className="feature-tab" id="As-Of-Aging">
            <ErrorHandler error={error} onClose={() => { setError({}) }} />
            <Accordion defaultExpanded label="Search & Filter">
                <AsOfAgingForm handleFormSubmit={handleFormSubmit} />
            </Accordion>
            <DataTable
                columns={columns}
                rows={data}
                id="date-range-aging"
                exportable filterable totalFooter pagination
                handleCellClick={handleCellClick}
            />
                <DetailsModal title="Payer Summary" show={showModal} handleClose={() => setShowModal(false)}>
                    <ErrorHandler error={detailError} onClose={() => { setDetailError({}) }} />
		        <Row>
		            <Col>
		                <DataTable
		                    columns={detailSummaryColumns}
		                    rows={detailSummaryData}
		                    id="Aging-summary-details-table"
		                />
                        </Col>
                        <Col>
                        </Col>
		        </Row>
	        <br />
	        <h2>Aging Details</h2>
	        <h4>{detailGridTitle}</h4>
	        <DataTable
	            columns={detailColumns}
	            rows={detailData}
	            id="Aging-details-table"
	            exportable filterable totalFooter pagination
	        />
		</DetailsModal>
        </div>
        </React.Fragment>
    )
}