import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { Form, Col, Button } from 'react-bootstrap'
import MultiSelectDropdown from '../../MultiSelectDropdown';
import MemberLocationDropdown from '../../form/MemberLocationDropdown'

import FormValidation from '../../form/validation/FormValidation';

export function CurrentAgingForm(props) {

    const [reportType, setReportType] = useState('unreconciled')
    const [dateType, setDateType] = useState('dispense')
    const [selectedLocations, setSelectedLocations] = useState([])
    const [loaded, setLoaded] = useState(false)

    useEffect(() => {
        if (!loaded && selectedLocations.length > 0) {
            setLoaded(true)
            submitForm()
        }
    }, [selectedLocations])

    function handleFormSubmit(event) {
        event.preventDefault();
        submitForm()
    }

    function submitForm() {
        let parameters = {
            selectedLocations: selectedLocations
        };

        let validationIssueMessage = FormValidation(parameters);

        if (validationIssueMessage) {
            alert(validationIssueMessage);
        }
        else {
            props.handleFormSubmit({
                reportType: reportType,
                dateType: dateType,
                mmid: selectedLocations.join(',')
            })
        }
        
    }

    return (
        <Form onSubmit={handleFormSubmit}>
            <Form.Row className="justify-content-start">
                <Form.Group as={Col} md="auto" className="d-flex flex-column" controlId="currentAgingFormGridLocation">
                    <Form.Label>Location (NCPDP)</Form.Label>
                    <Form.Control as={MemberLocationDropdown} onChange={(locations) => setSelectedLocations(locations)} />
                </Form.Group>
                <Form.Group as={Col} md="auto" className="d-flex flex-column" controlId="formGridSubmitButton">
                    <Button className="inline-show-result-button" size="sm" type="submit">Show Results</Button>
                </Form.Group>
            </Form.Row>
            <Form.Row className="justify-content-start">
                <Form.Group as={Col} md="auto">
                    <Form.Label>Date Type</Form.Label>
                    <div key="DateType" className="mb-3 mr-2">
                        <Form.Check custom type="radio" id="DateTypeCA-1" name="DateType" label="Dispense date" value="dispense" onChange={(event) => setDateType(event.target.value)} defaultChecked />
                        <Form.Check custom type="radio" id="DateTypeCA-2" name="DateType" label="Transaction date" value="transaction" onChange={(event) => setDateType(event.target.value)} />
                    </div>
                </Form.Group>
            </Form.Row>
        </Form>
    )
}


CurrentAgingForm.propTypes = {
    handleFormSubmit: PropTypes.func.isRequired
}
