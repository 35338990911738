import React, { useState, useRef, useContext } from 'react';
import { Popover, Overlay, Col, Dropdown } from 'react-bootstrap'
import { AuthContext } from '../../context/AuthContext'

export function UserAvatar(props) {
    const { user, auth } = props
    const { firstName, lastName } = user

    const [show, setShow] = useState(false);
    const target = useRef(null);
    // const auth = useContext(AuthContext)

    const popover = (
        <Popover className="popover-basic">
            <Dropdown.Item eventKey="1">
                <i className='fa fa-cogs' />
                User Settings
            </Dropdown.Item>
            <Dropdown.Item eventKey="2">
                <i className='fa fa-lock' />
                Password & Security
            </Dropdown.Item>
            <Dropdown.Divider />
            <Dropdown.Item eventKey="3" onClick={() => auth.signOut("/login")}>
                <i className='fa fa-sign-out' />
                Logout
            </Dropdown.Item>
        </Popover>
    )

    const initials = firstName[0] + lastName[0]
    const name = `${firstName} ${lastName}`
    return (
        <span>
            <Col onClick={() => setShow(!show)} className="col-md-auto avatar-wrapper d-flex">
                <i ref={target} className='fa fa-circle' id='user-avatar' />
                <span id="user-avatar-initials">{initials}</span>
                <span id="username" className='align-middle'>{name}</span>
            </Col>
            <Overlay target={target.current} show={show} placement="bottom" rootClose onHide={() => setShow(!show)}>
                {popover}
            </Overlay>
        </span>
    );
}