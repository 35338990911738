import React, { useContext, useState, useEffect } from 'react';
import MultiSelectDropdown from '../MultiSelectDropdown';
import { UserContext } from '../context/UserContext'

export default function MemberLocationDropdown(props) {
    const { onChange } = props
    const userContext = useContext(UserContext);

    return userContext.locations && (
        <MultiSelectDropdown options={userContext.locations} onChange={onChange} selectAllOption filterable onLoadAllSelected />
    );
}