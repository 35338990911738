import DatePicker from 'react-datepicker';
import React, { useState, useEffect, useContext } from 'react';
import { Form, Col, Button } from 'react-bootstrap';
import MemberLocationDropdown from '../../form/MemberLocationDropdown'
import MemberPayerDropdown from '../../form/MemberPayerDropdown'

import FormValidation from '../../form/validation/FormValidation';

export default function ArchiveRemittanceReviewForm(props) {

    const [toDate, setToDate] = useState(new Date());
    const [fromDate, setFromDate] = useState(new Date (new Date().setDate(new Date().getDate() - 30)))
    const [selectedPayers, setSelectedPayers] = useState([]);
    const [selectedLocations, setSelectedLocations] = useState([]);
    const [dateType, setDateType] = useState('archive');

    const [loaded, setLoaded] = useState(false)

    useEffect(() => {
        if (!loaded && selectedLocations.length > 0 && selectedPayers.length > 0) {
            setLoaded(true)
            submitForm();
        }
    }, [selectedLocations, selectedPayers])

    function handleFormSubmit(event) {
        event.preventDefault();

        submitForm();
    }

    function submitForm() {
        let parameters = {
            fromDate: fromDate,
            toDate: toDate,
            selectedLocations: selectedLocations,
            selectedPayers: selectedPayers,
            requiredFields: ["fromDate", "toDate"]
        };

        let validationIssueMessage = FormValidation(parameters);

        if (validationIssueMessage) {
            alert(validationIssueMessage);
        }
        else {
            props.handleFormSubmit({
                fromDate: fromDate,
                toDate: toDate,
                payers: selectedPayers.join(','),
                locations: selectedLocations.join(','),
                dateType: dateType
            });
        }
    }

    return (
        <Form onSubmit={handleFormSubmit}>
            <Form.Row className="justify-content-start">
                <Form.Group as={Col} md="auto" className="d-flex flex-column">
                    <Form.Label>Date From</Form.Label>
                    <Form.Control name="fromDate" as={DatePicker} selected={fromDate} onChange={(date) => setFromDate(date)} />
                </Form.Group>
                <Form.Group as={Col} md="auto" className="d-flex flex-column">
                    <Form.Label>Date To</Form.Label>
                    <Form.Control name="toDate" as={DatePicker} selected={toDate} minDate={fromDate} maxDate={new Date()} onChange={(date) => setToDate(date)} />
                </Form.Group>
                <Form.Group as={Col} md="auto" className="d-flex flex-column">
                    <Form.Label>Payer</Form.Label>
                    <Form.Control as={MemberPayerDropdown} onChange={(payers) => setSelectedPayers(payers)} />
                </Form.Group>
                <Form.Group as={Col} md="auto" className="d-flex flex-column">
                    <Form.Label>Location (NCPDP)</Form.Label>
                    <Form.Control as={MemberLocationDropdown} onChange={(locations) => setSelectedLocations(locations)} />
                </Form.Group>
                <Form.Group as={Col} md="auto" className="d-flex flex-column">
                    <Button className="inline-show-result-button" size="sm" type="submit">Show Results</Button>
                </Form.Group>
            </Form.Row>
            <Form.Row className="justify-content-start">
                <Form.Group as={Col} md="auto" className="d-flex flex-column">
                    <Form.Label>Date Type</Form.Label>
                    <div key="dateType" className="mb-3 mr-2">
                        <Form.Check custom type="radio" id="dateType1" name="dateType" onChange={(event) => setDateType(event.target.value)} label="Check Date" value="check" />
                        <Form.Check custom type="radio" id="dateType2" name="dateType" onChange={(event) => setDateType(event.target.value)} label="Remit Date" value="remittance" />
                        <Form.Check custom type="radio" id="dateType3" name="dateType" onChange={(event) => setDateType(event.target.value)} label="Payment Date" value="payment" />
                        <Form.Check custom type="radio" id="dateType4" name="dateType" onChange={(event) => setDateType(event.target.value)} label="Archive Date" value="archive" defaultChecked />
                    </div>
                </Form.Group>
            </Form.Row>
        </Form>    
    );
}