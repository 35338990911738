import React, { useState, useEffect } from 'react';

import { LocationComparisonForm } from './LocationComparisonForm'
import Dashboard from '../../SiSense/Dashboard'
import { loadScript, isLoaded } from '../../SiSense/SiSenseScriptLoader'
import Accordion from '../../Accordion';

export default function LocationComparison(props) {
    const { passedProps } = props

    const [scriptLoaded, setScriptLoaded] = useState(false)
    const [filters, setFilters] = useState({})

    useEffect(() => {
        if (isLoaded()) {
            setScriptLoaded(true)
        } else {
            loadScript().then(() => {
                setScriptLoaded(true)
            }).catch(e => console.log(e))
        }
    }, [])

    function generateBinJaqlFilter(queryBuilderInput) {
        const defaultJaql = {
            "jaql": {
                dim: "[vwBenchmark_Sisense.BIN]",
                datatype: "text",
                "filter": {
                    "explicit": false,
                    "multiSelection": true,
                    "all": true
                }
            }
        }

        let jaql = null;

        if (queryBuilderInput) {
            let value = queryBuilderInput.find(x => x.type === "BIN")
            if (value && value.options) {
                let parameters = value.options.split(';').map(x => x.trim());

                if (parameters && parameters.length > 0) {
                    jaql = {
                        "jaql": {
                            dim: "[vwBenchmark_Sisense.BIN]",
                            datatype: "text",
                            "filter": {
                                "explicit": false,
                                "multiSelection": true,
                                "members": parameters
                            }
                        }
                    }
                }

            }
        }

        return jaql || defaultJaql;
    }

    function generateDrugCodeJaqlFilter(queryBuilderInput) {
        const defaultJaql = {
            "jaql": {
                dim: "[vwBenchmark_Sisense.DRUG CODE]",
                datatype: "text",
                "filter": {
                    "explicit": false,
                    "multiSelection": true,
                    "all": true
                }
            }
        }

        let jaql = null;

        if (queryBuilderInput) {
            let value = queryBuilderInput.find(x => x.type === "Drug Type")
            if (value && value.options) {
                let parameters = value.options;

                if (parameters && parameters.length > 0) {
                    jaql = {
                        "jaql": {
                            dim: "[vwBenchmark_Sisense.DRUG CODE]",
                            datatype: "text",
                            "filter": {
                                "explicit": false,
                                "multiSelection": true,
                                "members": parameters
                            }
                        }
                    }
                }

            }
        }

        return jaql || defaultJaql;
    }

    function generatePcnJaqlFilter(queryBuilderInput) {
        const defaultJaql = {
            "jaql": {
                dim: "[vwBenchmark_Sisense.PCN]",
                datatype: "text",
                "filter": {
                    "explicit": false,
                    "multiSelection": true,
                    "all": true
                }
            }
        }

        let jaql = null;

        if (queryBuilderInput) {
            let value = queryBuilderInput.find(x => x.type === "PCN")
            if (value && value.options) {
                let parameters = value.options.split(';').map(x => x.trim());

                if (parameters && parameters.length > 0) {
                    jaql = {
                        "jaql": {
                            dim: "[vwBenchmark_Sisense.PCN]",
                            datatype: "text",
                            "filter": {
                                "explicit": false,
                                "multiSelection": true,
                                "members": parameters
                            }
                        }
                    }
                }

            }
        }

        return jaql || defaultJaql;
    }

    function generatePatientResidenceCodeJaqlFilter(queryBuilderInput) {
        const defaultJaql = {
            "jaql": {
                dim: "[vwBenchmark_Sisense.PATIENT RESIDENCE CODE]",
                datatype: "text",
                "filter": {
                    "explicit": false,
                    "multiSelection": true,
                    "all": true
                }
            }
        }

        let jaql = null;

        if (queryBuilderInput) {
            let value = queryBuilderInput.find(x => x.type === "Patient Residence Code")
            if (value && value.options) {
                let parameters = value.options;

                if (parameters && parameters.length > 0) {
                    jaql = {
                        "jaql": {
                            dim: "[vwBenchmark_Sisense.PATIENT RESIDENCE CODE]",
                            datatype: "text",
                            "filter": {
                                "explicit": false,
                                "multiSelection": true,
                                "members": parameters
                            }
                        }
                    }
                }

            }
        }

        return jaql || defaultJaql;
    }

    function generateTherapeuticClassJaqlFilter(queryBuilderInput) {
        const defaultJaql = {
            "jaql": {
                dim: "[vwBenchmark_Sisense.THERAPEUTIC CLASS]",
                datatype: "text",
                "filter": {
                    "explicit": false,
                    "multiSelection": true,
                    "all": true
                }
            }
        }

        let jaql = null;

        if (queryBuilderInput) {
            let value = queryBuilderInput.find(x => x.type === "Therapeutic Class")
            if (value && value.options) {
                //NOTE: I'll one-line this method, but I really think this is an example of when method chaining makes things harder to read
                let parameters = value.options.split(';').map(x => x.toUpperCase().trim());

                if (parameters && parameters.length > 0) {
                    jaql = {
                        "jaql": {
                            dim: "[vwBenchmark_Sisense.THERAPEUTIC CLASS]",
                            datatype: "text",
                            "filter": {
                                "explicit": false,
                                "multiSelection": true,
                                "members": parameters
                            }
                        }
                    }
                }

            }
        }

        return jaql || defaultJaql;
    }

    function generateTherapeuticClassCodeJaqlFilter(queryBuilderInput) {
        const defaultJaql = {
            "jaql": {
                dim: "[vwBenchmark_Sisense.TH_CODE]",
                datatype: "text",
                "filter": {
                    "explicit": false,
                    "multiSelection": true,
                    "all": true
                }
            }
        }

        let jaql = null;

        if (queryBuilderInput) {
            let value = queryBuilderInput.find(x => x.type === "Therapeutic Class Code")
            if (value && value.options) {
                let parameters = value.options.split(';').map(x => x.trim());

                if (parameters && parameters.length > 0) {
                    jaql = {
                        "jaql": {
                            dim: "[vwBenchmark_Sisense.TH_CODE]",
                            datatype: "text",
                            "filter": {
                                "explicit": false,
                                "multiSelection": true,
                                "members": parameters
                            }
                        }
                    }
                }

            }
        }

        return jaql || defaultJaql;
    }

    function handleFormSubmit(e) {
        //NOTE: "QueryBuilderInput" represents the parameters from the query builder

        if (!e.selectedDisplayType || !e.selectedMeasure || !e.selectedLocations.length || !e.selectedComparisonLocations.length) {
            return alert("Please select the required parameters to continue")
        }
        else {
            //NOTE: Handle getting the filter values from queryBuilderInput
            let binFilter = generateBinJaqlFilter(e.queryBuilderInput);
            let drugCodeFilter = generateDrugCodeJaqlFilter(e.queryBuilderInput);
            let pcnFilter = generatePcnJaqlFilter(e.queryBuilderInput);
            let patientResidenceCodeFilter = generatePatientResidenceCodeJaqlFilter(e.queryBuilderInput);
            let therapeuticClassFilter = generateTherapeuticClassJaqlFilter(e.queryBuilderInput);
            let therapeuticClassCodeFilter = generateTherapeuticClassCodeJaqlFilter(e.queryBuilderInput);

            let filters =
                [
                    binFilter,
                    drugCodeFilter,
                    pcnFilter,
                    patientResidenceCodeFilter,
                    therapeuticClassFilter,
                    therapeuticClassCodeFilter,
                    {
                        "jaql": {
                            dim: "[vwBenchmark_Sisense.ServiceYearMonth (Calendar)]",
                            datatype: "datetime",
                            "filter": {
                                "from": e.fromDate,
                                "to": e.toDate
                            }
                        }
                    },

                    {
                        "jaql": {
                            dim: "[Benchmark_Value.Benchmark_Value]",
                            datatype: "numeric",
                            "filter": {
                                "members": e.selectedDisplayType //["2"]
                            }
                        }
                    },
                    {
                        "jaql": {
                            dim: "[Measure_Value.Measure_Value]",
                            datatype: "numeric",
                            "filter": {
                                "members": e.selectedMeasure  //["8"]
                            }
                        }
                    },
                    {
                        // negative members
                        "jaql": {
                            "type": "measure",
                            "datatype": "text",
                            "title": "Negative_Member",
                            "dim": "[Filtered_Member.Negative_Member]",
                            "filter": {
                                "explicit": true,
                                "multiSelection": true,
                                "members": e.selectedComparisonLocations
                            },
                            "modifiedByParameters": true
                        }
                    },
                    {
                        //m1
                        "jaql": {
                            "type": "measure",
                            "title": "M1",
                            "dim": "[Filtered_Member.M1]",
                            "datatype": "text",
                            "filter": {
                                "explicit": true,
                                "members": e.selectedLocations[0] ? [e.selectedLocations[0].toString()] : ["none"]
                            },

                            "modifiedByParameters": true
                        }
                    },
                    {
                        //m2
                        "jaql": {
                            "type": "measure",
                            "title": "M2",
                            "dim": "[Filtered_Member.M2]",
                            "datatype": "text",
                            "filter": {
                                "explicit": true,
                                "members": e.selectedLocations[1] ? [e.selectedLocations[1].toString()] : ["none"]
                                //"members": ["3997915"]
                            },
                            "modifiedByParameters": true
                        }
                    },
                    {
                        //m3
                        "jaql": {
                            "type": "measure",
                            "title": "M3",
                            "dim": "[Filtered_Member.M3]",
                            "datatype": "text",
                            "filter": {
                                "explicit": true,
                                "members": e.selectedLocations[2] ? [e.selectedLocations[2].toString()] : ["none"]
                            },
                            "modifiedByParameters": true
                        }
                    },
                    {
                        //m4
                        "jaql": {
                            "type": "measure",
                            "title": "M4",
                            "dim": "[Filtered_Member.M4]",
                            "datatype": "text",
                            "filter": {
                                "explicit": true,
                                "members": e.selectedLocations[3] ? [e.selectedLocations[3].toString()] : ["none"]
                            },
                            "modifiedByParameters": true
                        }
                    },
                    {
                        //m5
                        "jaql": {
                            "type": "measure",
                            "title": "M5",
                            "dim": "[Filtered_Member.M5]",
                            "datatype": "text",
                            "filter": {
                                "explicit": true,
                                "members": e.selectedLocations[4] ? [e.selectedLocations[4].toString()] : ["none"]
                            },
                            "modifiedByParameters": true
                        }
                    },
                    {
                        //m6
                        "jaql": {
                            "type": "measure",
                            "title": "M6",
                            "dim": "[Filtered_Member.M6]",
                            "datatype": "text",
                            "filter": {
                                "explicit": true,
                                "members": e.selectedLocations[5] ? [e.selectedLocations[5].toString()] : ["none"]
                            },
                            "modifiedByParameters": true
                        }
                    },
                    {
                        //m7
                        "jaql": {
                            "type": "measure",
                            "title": "M7",
                            "dim": "[Filtered_Member.M7]",
                            "datatype": "text",
                            "filter": {
                                "explicit": true,
                                "members": e.selectedLocations[6] ? [e.selectedLocations[6].toString()] : ["none"]
                            },
                            "modifiedByParameters": true
                        }
                    }
                ]


            setFilters(filters)
        }
    }

    const settings = {
        showToolbar: false,
        showLeftPane: false,
        showRightPane: false
    }

    return (
        <div className="sisense-feature" >
            <Accordion defaultExpanded label="Search & Filter">
                <LocationComparisonForm handleFormSubmit={handleFormSubmit} />
            </Accordion>

            <Dashboard
                scriptLoaded={scriptLoaded}
                iFrameId='benchmarking-sisense-frame'
                filters={filters}
                dashboard={process.env.REACT_APP_Dashboard_BenchmarkingLite}
                settings={settings}
                hidden={false}
                type="fullFeature"
            />
        </div>
    )

}