const FormValidation = function (formData) {
    let validationIssueMessage = null;

    function addToValidationIssueMessage(message) {
        if (validationIssueMessage) {
            validationIssueMessage = validationIssueMessage + "\r\n" + message;
        }
        else {
            validationIssueMessage = message;
        }
    }

    function isSpecialCharacters(val) {
        var pattern = new RegExp(/[~@`!#$%\^&*+=\-\[\]\\';,/{}|\\":<>\?]/);
        return pattern.test(val);
    }

    if (formData.selectedLocations && formData.selectedLocations.length === 0) {
        addToValidationIssueMessage("Please select a location.");
    }

    if (formData.selectedPayers && formData.selectedPayers.length === 0) {
        addToValidationIssueMessage("Please select a payer.");
    }

    if (formData.selectedMessageCategory && formData.selectedMessageCategory.length === 0) {
        addToValidationIssueMessage("Please select a message category.");
    }

    if (
        formData.fromDate && formData.toDate &&
        formData.fromDate > formData.toDate
    ) {
        addToValidationIssueMessage("The From Date is after the To Date. Please correct this.");
    }

    if (formData.requiredFields && formData.requiredFields.length > 0) {
        formData.requiredFields.forEach(function (x) {
            switch (x) {
                case "fromDate":
                    if (!formData.fromDate) {
                        addToValidationIssueMessage("Please enter a valid From Date.");
                    }
                    break;
                case "toDate":
                    if (!formData.toDate) {
                        addToValidationIssueMessage("Please enter a valid To Date.");
                    }
                    break;
                case "date":
                    if (!formData.date) {
                        addToValidationIssueMessage("Please enter a valid Date.");
                    }
                    break;
                case "checkNumberOrDateRange":
                    if (!((formData.toDate && formData.fromDate) || formData.checkNumber)) {
                        addToValidationIssueMessage("Please enter a valid date range or check number.");
                    }
                    break;
                default:
                    console.log(x);
                    console.log("Invalid value passed to requiredFields check in FormValidation.js");
                    break;
            }
        });
    }

    return validationIssueMessage;

}

export default FormValidation;