import React from 'react';

export default function UnappliedCashDetailActionCell(props) {

    return (
        <span>
            <PrescriptionDetail handleClick={props.onClick} /> &nbsp;&nbsp;&nbsp;
        </span>
    )

}

function PrescriptionDetail(props) {
    const { handleClick } = props;
    return (
        <span className="action-cell" onClick={() => handleClick("prescriptionDetail")} >
            Prescription Detail
        </span>
    )
}