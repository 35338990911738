import React from 'react';

export default function ActionCell(props) {

    return (
        <span className="action-cell" onClick={() => props.onClick("viewReport")} data-testid={"action-" + props.key}>
            View Report
        </span>
    )

}
