import React, { useState, useEffect } from 'react';
import { Row, Col, NavDropdown, Nav } from 'react-bootstrap';
import Accordion from '../../Accordion';
import OverpaymentsForm from './OverpaymentsForm';
import axios from 'axios';
import { FeatureWidget } from './PaymentExceptionFeatureWidget'
//import { FeatureWidget } from '../../SiSense/FeatureWidget';
import { constants } from './PaymentExceptionConstants'
import { LoadingSpinner } from './../../layout/LoadingSpinner';
import ErrorHandler from './../../core/ErrorHandler';
import DataTable from '../../grid/DataTable';
import ActionCell from './ActionCell';
import DetailActionCell from './DetailActionCell';
import { CustomAction } from '../../grid/CustomActionBarAction'
import { DetailsModal } from '../../grid/DetailsModal';
import  IgnoreModal  from './IgnoreModal';
import PrescriptionDetailsModal from './PrescriptionDetailsModal';
import ExcelExporter from '../../export/excelExporter';
import CSVExporter from '../../export/csvExporter';

export function Overpayments(props) {

    const { passedProps } = props;
    const user = passedProps.user.email
    const [submittedFormData, setSubmittedFormData] = useState(null)
    const [data, setData] = useState(props.data ? props.data : [])
    const [columns, setColumns] = useState(props.columns ? props.columns : [])
    const [error, setError] = useState({})
    const [isLoading, setIsLoading] = useState(false)
    const [showViewReportModal, setShowViewReportModal] = useState(false)
    const [detailData, setDetailData] = useState([])
    const [detailColumns, setDetailColumns] = useState([])
    const [customDetailExport, setCustomDetailExport] = useState(null)
    const [detailPayer, setDetailPayer] = useState([])
    const [showIgnoreModal, setShowIgnoreModal] = useState(false)
    const [customExport, setCustomExport] = useState(null)
    const [selectedNCPDP, setSelectedNCPDP] = useState('')
    const [selectedRX, setSelectedRX] = useState('')
    const [ignoreAll, setIgnoreAll] = useState(false)
    const [showPrescriptionModal, setShowPrescriptionModal] = useState(false)
    const [selectedClid, setSelectedClid] = useState('')

    useEffect(() => {

        if (submittedFormData) {
            passedProps.auth.getAccessToken()
                .then(accessToken => {
                    axios.get(`api/PaymentException/GetOverpaymentsSummary`, {
                        params: {
                            user: user,
                            fromDate: submittedFormData.fromDate,
                            toDate: submittedFormData.toDate,
                            locations: submittedFormData.locations,
                            payers: submittedFormData.payers,
                            dateType: submittedFormData.dateType,
                            checkNumber: submittedFormData.checkNumber,
                            showArchiveRemitsOnly: submittedFormData.showArchiveRemitsOnly 

                        },
                        headers: { Authorization: `Bearer ${accessToken}` }
                    })
                        .then(onSuccess)
                        .catch(onFailure)
                })
                .catch(onFailure)
        }
    }, [submittedFormData])


    function handleFormSubmit(e) {
        setData([])
        setIsLoading(true)
        setSubmittedFormData(e)
    }

    function onSuccess(response) {

        switch (response.config.url) {
            case 'api/PaymentException/GetOverpaymentsSummary': setGridData(response.data); break;
            default: break;
        }
    }

    function onFailure(error) {
        setIsLoading(false);
        setError(error.response);
        switch (error.response.config.url) {
            case 'api/PaymentException/GetOverpaymentsSummary':
                setData([]);
                setColumns([]);
                break;
            case 'api/PaymentException/GetOverpaymentsDetails':
                setDetailData([]);
                setDetailColumns([]);
                break;
            default: break;
        }
    }

    function setGridData(data) {
        let rows = data;
        const columns = []

        if (rows.length > 0) {
            columns.push({ accessor: "Action", title: "Action", type: "custom" });
            const headerProps = constants.headerProps

            Object.keys(rows[0]).map(key => {
                return columns.push({
                    accessor: key,
                    title: headerProps[key].title,
                    type: headerProps[key].type,
                    showTotal: headerProps[key].showTotal,
                    hidden: headerProps[key].hidden
                })
            })
            rows.map(row => {
                row["Action"] = renderMainActionCell(row['pbid'])
            })
            setError({})
        }
        else {
            setError({ status: 201, Message: 'No data found for given params' })
        }

        setData(rows)
        setColumns(columns)
        setIsLoading(false)
    }

    function setDetailGridData(data) {
        let rows = data;
        const columns = []

        if (rows.length > 0) {
            columns.push({ accessor: "Action", title: "Action", type: "custom", fixedLeft: true });
            const headerProps = constants.detailHeaderProps

            Object.keys(rows[0]).forEach(key => {
                columns.push({
                    accessor: key,
                    title: headerProps[key].title,
                    type: headerProps[key].type,
                    fixedLeft: headerProps[key].fixedLeft,
                    showTotal: headerProps[key].showTotal,
                    hidden: headerProps[key].hidden
                })
            })
            rows.map(row => {
                row["Action"] = renderDetailActionCell(row['clid'])
            })
            setError({})
        }
        else {
            setError({ status: 201, Message: 'No data found for given params' })
        }

        setDetailData(rows)
        setDetailColumns(columns)
        setIsLoading(false)
        setShowViewReportModal(true)
    }

    function renderMainActionCell(key) {
        return (
            <ActionCell key={key}  />
        )
    }

    function renderDetailActionCell(key) {
        return (
            <DetailActionCell key={key} />
        )
    }

    function getNewRowSelectedValue(rows) {
        if (rows.length > 1) {
            return !rows.every(x => {
                return x.selected === true
            })
        }
        return !rows[0].selected
    }

    function handleRowSelected(rows) {
        let newData = [...data]
        let selectedValue = getNewRowSelectedValue(rows)

        rows.forEach(row => {
            let index = newData.findIndex((x => x.pbid == row.pbid))
            newData[index].selected = selectedValue
        })
        setData(newData)
    }

    function getNewRowSelectedValue(rows) {
        if (rows.length > 1) {
            return !rows.every(x => {
                return x.selected === true
            })
        }
        return !rows[0].selected
    }

    function handleModalRowSelected(rows) {
        let newData = [...detailData]
        let selectedValue = getNewRowSelectedValue(rows)

        rows.forEach(row => {
            let index = newData.findIndex((x => x.clid == row.clid))
            newData[index].selected = selectedValue
        })
        setDetailData(newData)
    }

    function getCustomActions() {
        return (
            <CustomAction label="View Selected Reports" onClick={getReport} />
        )
    }

    function getCustomModalActions() {
        return (
            <React.Fragment>
                <Nav variant="pills">
                    <NavDropdown title="Download Detail As..." id="nav-detail-dropdown">
                        <NavDropdown.Item>
                            <ExcelExporter columns={detailColumns} rows={detailData} custom={customDetailExport} />
                        </NavDropdown.Item>
                        <NavDropdown.Item>
                            <CSVExporter columns={detailColumns} rows={detailData} custom={customDetailExport} />
                        </NavDropdown.Item>
                    </NavDropdown>
                </Nav>
                <CustomAction label="Ignore Selected" onClick={ignore} />
            </React.Fragment>
        );
    }

    async function getDetailData(row) {
        setIsLoading(true);

        try {
            const accessToken = await passedProps.auth.getAccessToken();
            const response = await axios.get(`api/PaymentException/GetOverpaymentsDetails`, {
                params: {
                    user: user,
                    fromDate: submittedFormData.fromDate,
                    toDate: submittedFormData.toDate,
                    locations: submittedFormData.locations,
                    payers: row.pbid || data.reduce((a, o) => (o.selected && a.push(o.pbid), a), []).join(','),
                    dateType: submittedFormData.dateType,
                    checkNumber: submittedFormData.checkNumber,
                    showArchiveRemitsOnly: submittedFormData.showArchiveRemitsOnly
                },
                headers: { Authorization: `Bearer ${accessToken}` }
            });

            setDetailGridData(response.data);
        }
        catch (e) {
            setError(e)
        }

        setIsLoading(false)
    }

    function handleExportWithoutDetail(data) {
        if (Object.keys(data).length > 0) {
            let customCols = [];

            let headerProps = constants.detailExportProps;
            let col = Object.values(data[Object.keys(data)[0]]);

            Object.keys(col[0]).forEach(key => {
                customCols.push({
                    title: headerProps[key].title,
                    type: headerProps[key].type,
                    accessor: key,
                    showTotal: headerProps[key].showTotal,
                    hidden: headerProps[key].hidden || false
                })
            })

            return { columns: customCols, rows: data, PrimaryKey: 'clid', page: 'PaymentExceptionDetail' }
        }

        return null;
    }

    function handleExportWithDetail(data) {
        if (data.length > 0) {
            let customCols = [];
            let headerProps = constants.detailExportDetailsProps;

            Object.keys(data[0]).forEach(key => {
                customCols.push({
                    title: headerProps[key].title,
                    type: headerProps[key].type,
                    accessor: key,
                    showTotal: headerProps[key].showTotal,
                    hidden: headerProps[key].hidden || false
                })
            })

            return { columns: customCols, rows: data, PrimaryKey: '', page: 'PaymentExceptionDetail' }
        }

        return null;
    }

    async function getReport(row) {
        if (row.payer) {
            setDetailPayer(row.payer)
        }
        else {
            const payers = data.reduce((a, o) => (o.selected && a.push(o.payer), a), []) 
            setDetailPayer(payers.join(','))

            if (!row.payer && payers.length < 1) {
                return alert("Please select a report to view")
            }
        }
        setIsLoading(true)

        try {
            const accessToken = await passedProps.auth.getAccessToken();
            const exportWithoutDetail = await axios.get(`api/PaymentException/GetOverpaymentExport`, {
                params: {
                    user: user,
                    fromDate: submittedFormData.fromDate,
                    toDate: submittedFormData.toDate,
                    locations: submittedFormData.locations,
                    payers: row.pbid || data.reduce((a, o) => (o.selected && a.push(o.pbid), a), []).join(','),
                    dateType: submittedFormData.dateType,
                    checkNumber: submittedFormData.checkNumber,
                    showArchiveRemitsOnly: submittedFormData.showArchiveRemitsOnly
                },
                headers: { Authorization: `Bearer ${accessToken}` }
            });

            setCustomExport(handleExportWithoutDetail(exportWithoutDetail.data))

            const exportWithDetail = await axios.get(`api/PaymentException/GetOverpaymentExportDetails`, {
                params: {
                    user: user,
                    fromDate: submittedFormData.fromDate,
                    toDate: submittedFormData.toDate,
                    locations: submittedFormData.locations,
                    payers: row.pbid || data.reduce((a, o) => (o.selected && a.push(o.pbid), a), []).join(','),
                    dateType: submittedFormData.dateType,
                    checkNumber: submittedFormData.checkNumber,
                    showArchiveRemitsOnly: submittedFormData.showArchiveRemitsOnly
                },
                headers: { Authorization: `Bearer ${accessToken}` }
            });

            setCustomDetailExport(handleExportWithDetail(exportWithDetail.data))

            getDetailData(row)
        }
        catch (e) {
            setIsLoading(false)
            setError(e)
        }
    }

    function getPrescriptionDetail(clid) {

        setSelectedClid(clid)
        setShowPrescriptionModal(true)
        setShowViewReportModal(false)
    }

    function ignore(row) {
        if (row.clid) {
            setSelectedNCPDP(row.ncpdp), setSelectedRX(row.rxNumber)
    }else
    { setIgnoreAll(true), setSelectedNCPDP("All Selected"), setSelectedRX("All Selected") }
        setShowIgnoreModal(true)
        setShowViewReportModal(false)    
    }
    //Called when user clicks save in ignore details modal 
    function handleSaveIgnoreClick(selectedIgnoreOption,ignoreNote) {
        if (!selectedIgnoreOption) {
            return alert("Please Select an Ignore Code to Save!")
        }

        setShowIgnoreModal(false)
        setShowViewReportModal(true)    

        var strClids = ""
        if (selectedIgnoreOption) {
            if (!ignoreAll) {
                var objIndex = detailData.findIndex((obj => obj.ncpdp == selectedNCPDP && obj.rxNumber == selectedRX))
                strClids = detailData[objIndex].clid
            } else {
                detailData.forEach(function (obj) { if (obj.selected) { strClids += obj.clid + "," } })
            }
            

            passedProps.auth.getAccessToken()
                .then(accessToken => {
                    axios.get('api/PaymentException/IgnoreClaims', {
                        params: {
                            user: user,
                            ignoreId: selectedIgnoreOption,
                            ignoreNote: ignoreNote,
                            claimIds: strClids,
                        },
                        headers: { Authorization: `Bearer ${accessToken}` }
                    })
                        .then(resetGrid(objIndex))
                        .catch(onFailure)
                })
                .catch(onFailure)
        }
    }
    function handleCellClick(row, column) {
        switch (column) {
            case 'viewReport':
                getReport(row)
                break;
            case 'prescriptionDetail':
                getPrescriptionDetail(row.clid)
                break;
            case 'ignore':
                ignore(row)
                break;
            case 'excelExport':
                break;
            case 'pdfExport':
                break;
            default: break;
        }
    }
    function resetGrid(objIndex) {
        if (ignoreAll) {
            let resetDetailData = detailData.filter((claim) => !claim.selected)
            setDetailData([...resetDetailData])
            setIgnoreAll(false);
        }
        else {
            let resetDetailData = detailData
            resetDetailData.splice(objIndex, 1)
            setDetailData([...resetDetailData])
        }
    }

    function handleIgnoreClose() { setShowIgnoreModal(false); setIgnoreAll(false); setShowViewReportModal(true) }
    function handlePrescriptionDetailClose() { setShowPrescriptionModal(false), setShowViewReportModal(true) }

    return (
        <div className="feature">
            <LoadingSpinner isDataLoading={isLoading} controlsName='Overpayments' />
            <ErrorHandler error={error} onClose={() => { setError({}) }} />
            <Accordion defaultExpanded label="Search & Filter">
                <Row>
                    <Col>
                        <OverpaymentsForm handleFormSubmit={handleFormSubmit} auth={passedProps.auth} />
                    </Col>
                    <Col>
                        <FeatureWidget />

                    </Col>
                </Row>
            </Accordion>
            <DataTable
                id="PaymentException-Overpayments"
                columns={columns}
                rows={data}
                handleCellClick={handleCellClick}
                handleRowSelected={handleRowSelected}
                customActionBarActions={getCustomActions()}
                exportable pagination filterable selectableRows totalFooter
            />
            <DetailsModal title={detailPayer} show={showViewReportModal} handleClose={() => setShowViewReportModal(false)}>
                <DataTable
                    columns={detailColumns}
                    rows={detailData}
                    id="Overpayments-ViewReport"
                    exportable customExport={customExport} filterable totalFooter pagination selectableRows
                    handleCellClick={handleCellClick}
                    handleRowSelected={handleModalRowSelected}
                    customActionBarActions={getCustomModalActions()}

                />
            </DetailsModal>
            <IgnoreModal show={showIgnoreModal} selectedNCPDP={selectedNCPDP} selectedRX={selectedRX} handleSaveIgnoreClick={handleSaveIgnoreClick} handleClose={handleIgnoreClose} />
            <PrescriptionDetailsModal auth={passedProps.auth} clid={selectedClid} show={showPrescriptionModal} handleClose={handlePrescriptionDetailClose} handleCellClick={handleCellClick} user={user} />
        </div>
    );
}

