import React, { useState, useEffect } from 'react';
import PDFFormatter from './pdfFormatter';
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import NetRxLogo from '../assets/NetRxLogo.png';

export default function pdfExporter(props) {
    const { columns = [], rows = [], custom = {}, page = '', name = 'PDFExport', button = 'Portable Document Format .pdf', handleActionBarStartAction, handleActionBarCompleteAction } = props;

    const [pdfColumns, setPDFColumns] = useState([])
    const [pdfRows, setPDFRows] = useState([])

    const exportButton = <span onClick={handlePDFExport}><i className='fa fa-file-pdf-o' aria-hidden></i> {button}</span>
    pdfMake.vfs = pdfFonts.pdfMake.vfs;

    useEffect(() => {
        if (custom) {
            switch (custom.PrimaryKey) {
                case 'pmid': setPDFRows(filterByPaymentID()); break;
                default: setPDFRows(custom.rows); break;
            }
            setPDFColumns(custom.columns);
        }
        else {
            setPDFColumns(columns)
            setPDFRows(rows)
        }
    }, [rows])

    function filterByPaymentID() {
        let data = [];
        for (let i = 0; i < rows.length; i++) {
            let currentRow = rows[i];
            let customRow = custom.rows[currentRow.pmid];
            if (customRow) { data.push(customRow) }
        }
        return data;
    }

    function handlePDFExport() {
        handleActionBarStartAction();
        //alert("this started");

        var ctx = document.getElementById('hiddenCanvasForPdf').getContext('2d');
        var img = new Image();
        img.onload = function () {
            ctx.drawImage(img, 0, 0);
            pdfMake.createPdf(PDFFormatter(pdfColumns, pdfRows, page)).download(name);

            handleActionBarCompleteAction();
            //alert("pdf completed");
        };
        img.src = NetRxLogo;


    }

    return (
        <React.Fragment>{exportButton}</React.Fragment>
    );
}