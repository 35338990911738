import React, { useContext, useEffect, useState } from 'react';
import { withOktaAuth } from "@okta/okta-react";
import { useNavigate } from "react-router-dom";

import axios from 'axios';
import { Container, Row, Col } from 'react-bootstrap'

import { ScreenHeader } from './ScreenHeader/ScreenHeader'
import { ContentHeader } from './ContentHeader'
import { Footer } from './Footer'
import { NavBar } from './NavBar'

import { UserContext } from '../context/UserContext'
import { AuthContext } from '../context/AuthContext'
import { getWithExpiry } from '../../utils/localStorageAccess'

export default withOktaAuth(function Layout(props) {

    const { oktaAuth, authState } = props

    const userConfig = useContext(UserContext)
    const [isAuthenticated, setIsAuthenticated] = useState(null)
    const [user, setUser] = useState(null)
    const navigate = useNavigate();
    
    async function checkAuthentication() {
        if (authState) {
            const fetchedIsAuthenticated = authState.isAuthenticated;

            if (fetchedIsAuthenticated !== isAuthenticated) {
                setIsAuthenticated(fetchedIsAuthenticated);
                if (authState.accessToken) {
                    const userInfo = await oktaAuth.getUser();
                    userConfig.onEmailChange(userInfo.email);
                    setUser(userInfo);
                } else {
                    setUser(null);
                }
            }

            if (fetchedIsAuthenticated === false) {
                navigate("/Login");
            }
        }
    }

    useEffect(() => {
        checkAuthentication()
    }, [])

    useEffect(() => {
        checkAuthentication()
    })

    useEffect(() => {
        async function getMemberConfig() {
            if (isAuthenticated) {
                const userInfo = await oktaAuth.getUser();
                const accessToken = await oktaAuth.getAccessToken();

                console.log(userInfo);

                userConfig.onEmailChange(userInfo.email);

                let programResponse = await axios.get(`api/UserProgram/GetUserConfig`, {
                    params: {
                        email: userInfo.email,
                        type: userInfo.userType,
                    },
                    headers: { Authorization: `Bearer ${accessToken}` }
                });

                userConfig.onProgramsChange(programResponse.data);

                // let organizationDropdownOptions = await axios.get(`api/UserProgram/GetUserParentCompanies`, {
                //     params: {
                //         email: userInfo.email,
                //         type: userInfo.userType,
                //     //NOTE: Here we're forcing the type to be "GCT", the assumption is that all users logging in will be GCT types for Metric 2.0
                //         type: "GCT",

                //     },
                //     headers: { Authorization: `Bearer ${accessToken}` }
                // })

                let organizationDropdownOptions = await axios.get(`api/UserProgram/GetUserGroups`, {
                    params: {
                        email: userInfo.email,
                        type: userInfo.userType,
                        //NOTE: Here we're forcing the type to be "GCT", the assumption is that all users logging in will be GCT types for Metric 2.0
                        //type: "GCT",

                    },
                    headers: { Authorization: `Bearer ${accessToken}` }
                })

                const organizations = formatDictionaryToArray(organizationDropdownOptions.data)
                userConfig.onOrganizationsChange(organizations)

                const cachedConfig = getWithExpiry("rme-userConfig")

                if (cachedConfig &&
                    JSON.stringify(organizations) === JSON.stringify(cachedConfig.organizations) &&
                    cachedConfig.currentOrganization) {
                    userConfig.onCurrentOrganizationChange(cachedConfig.currentOrganization)
                } else {
                    userConfig.onCurrentOrganizationChange(organizations[0])
                }
            }
        }

        try {
            getMemberConfig()
        } catch (e) {
            console.log(e)
        }
    }, [isAuthenticated])

    useEffect(() => {
        async function getLocations() {
            if (userConfig.currentOrganization && isAuthenticated) {
                const userInfo = await oktaAuth.getUser();
                const accessToken = await oktaAuth.getAccessToken();

                let parameters = {
                    email: userInfo.email,
                    type: userInfo.userType
                }

                if (parameters.type === "GCT" || parameters.type === "UCT") {
                    parameters.GPID = userConfig.currentOrganization.value
                }
                else {
                    parameters.RPID = userConfig.currentOrganization.value
                }

                //NOTE TODO: Here it's getting location based on RPID, need to change it to get Locations based on GPID (group ID)
                let locationsResponse = await axios.get(`api/UserProgram/GetUserLocations`, {
                    params: parameters,
                    headers: { Authorization: `Bearer ${accessToken}` }
                })

                //NOTE TODO: Figure out how this is being determined
                let locations = formatDictionaryToArray(locationsResponse.data)
                let ncpdps = formatDictionaryToNcpdps(locationsResponse.data);

                try {
                    await axios.post('api/UserProgram/UpdateSisense',
                        {
                            email: userConfig.email,
                            locations: Object.keys(locationsResponse.data)
                        },
                        {
                            headers: { Authorization: `Bearer ${accessToken}` }
                        })

                } catch (e) {
                    console.log("sisense server down")
                }

                userConfig.onLocationsChange(locations)
                userConfig.onNcpdpsChange(ncpdps)
            }
        }

        async function getPayers() {
            if (userConfig.currentOrganization && isAuthenticated) {
                const userInfo = await oktaAuth.getUser();
                const accessToken = await oktaAuth.getAccessToken()

                let payerResponse = await axios.get(`api/Payers/GetPayersByRpid`, {
                    params: { User: userInfo.email, RPID: userConfig.currentOrganization.value },
                    headers: { Authorization: `Bearer ${accessToken}` }
                })

                let payers = formatPayers(payerResponse.data)
                userConfig.onPayersChange(payers)
            }
        }
        try {
            getLocations()
            getPayers()
        } catch (e) {
            console.log(e)
        }
    }, [userConfig.currentOrganization])

    function formatDictionaryToArray(data) {
        return Object.keys(data).map(key => {
            return {
                label: data[key],
                value: key
            }
        })
    }

    //NOTE: This is for extracting the NCPDP from the label on the frontend. We know that NCPDPs are always 7 characters long, we know that the label always ends with ")"
    function formatDictionaryToNcpdps(data) {
        return Object.keys(data).map(key => {
            return {
                label: data[key],
                value: data[key].substring(data[key].length - 8, data[key].length - 1)
            }
        })
    }

    function formatPayers(data) {
        return data.map(x => {
            return {
                label: x.Name,
                value: x.PBID.toString()
            }
        })
    }

    if (oktaAuth.authenticated === null || !user) return null;

    const childrenWithProps = React.Children.map(props.children, (child) =>
        React.cloneElement(child, { passedProps: { auth: oktaAuth, user: user } })
    );

    return (
        <AuthContext.Provider value={oktaAuth}>
            <Container className="px-0 d-flex flex-column" fluid="true" id="content">
                <header>
                    <ScreenHeader user={user} auth={oktaAuth} />
                    <ContentHeader featureTitle={props.featureTitle} />
                </header>
                <Row className="flex-fill d-flex no-gutters flex-nowrap">
                    <NavBar label={props.selectedNavbarLabel}  auth={oktaAuth} />
                    <Col className="overflow-auto">
                        <Row className="d-flex no-gutters flex-column" id="main-content">
                            {childrenWithProps}
                        </Row>
                        <Footer />
                    </Col>
                </Row>
            </Container>
        </AuthContext.Provider>
    );
});

