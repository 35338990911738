import React, { useContext } from 'react';
import MultiSelectDropdown from '../MultiSelectDropdown';

import { UserContext } from '../context/UserContext'
export default function MemberPayerDropdown(props) {
    const { onChange } = props
    const userContext = useContext(UserContext);

    return userContext.payers && (
        <MultiSelectDropdown options={userContext.payers} onChange={onChange} selectAllOption filterable onLoadAllSelected />
    );
}