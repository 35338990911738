import React, { useContext } from "react";

import { UserContext } from "../../context/UserContext";
import OrganizationDropdown from "./OrganizationDropdown/OrganizationDropdown";

export function Organization(props) {
  const { userType } = props;
  const userContext = useContext(UserContext);

  function getContent() {
    return (
      userContext.currentOrganization && (
        <React.Fragment>
          <i className="fa fa-building" />
          {userContext.currentOrganization.label}
        </React.Fragment>
      )
    );
  }

  const content = getContent();

  function getOrganizationName() {
    if (userContext.organizations && userContext.currentOrganization) {
      // if (
      //   userContext.organizations.length === 1 ||
      //   userType === "MCT" ||
      //   userType === "PCT"
      // ) {
      //   return content;
      // }

      // eslint-disable-next-line default-case
      switch (userType) {
        case "MCT":
        case "PCT":
        case "GCT":
        case "ACT":
        case "UCT":
          return (
            <OrganizationDropdown
              organizations={userContext.organizations}
              onChange={(organization) => {
                userContext.onCurrentOrganizationChange(organization);
              }}
              selectedOrganization={userContext.currentOrganization}
            >
              {content}
            </OrganizationDropdown>
          );
      }
    } else return null;
  }

  return getOrganizationName();
}
