
export default function SingleCheckPDFExporter(data) {
    var dataURL = document.getElementById('hiddenCanvasForSingleCheckPdf').toDataURL();

    var docDefinition = {
        pageSize: { width: 1000, height: 2500 },
        footer: function (currentPage, pageCount, pageSize) {

            return { text: "Page " + currentPage.toString(), alignment: 'center', margin: [0, 0, 0, 25] };
        },
        content: [
            {
                image: dataURL,
                margin: [0, 0, 0, 15]
            },
        ],
        styles: {
            tableHeader: {
                bold: true,
                fontSize: 13,
                color: 'black'
            },
            reportHeader: {
                bold: true,
                fontSize: 16,
                color: 'black'
            },
            boldText: {
                bold: true
            }
        }
    }

    let fixedWidthTable = {
        table: {
            widths: ['*', '*', '*'],
            body: [
                [
                    { text: "Payer", style: "boldText" },
                    { text: "Payee", style: "boldText" },
                    { text: "Payment", style: "boldText" }
                ],
                [
                    { text: data.payerName },
                    { text: data.parentCompanyName },
                    { text: "Reference: " + data.checkNumber }
                ],
                [
                    { text: data.payerAddressLine1 },
                    { text: data.parentCompanyAddressLine1 },
                    { text: "Date: " + new Date(data.checkDate).toLocaleDateString() }
                ],
                [
                    { text: data.payerCity + ", " + data.payerState + " " + data.payerZip },
                    { text: data.parentCompanyCity + ", " + data.parentCompanyState + " " + data.parentCompanyZip },
                    {
                        text: "Amount: " + data.checkAmount.toLocaleString(undefined, { style: 'currency', currency: 'USD', minimumFractionDigits: 2, maximumFractionDigits: 2 })
                    }
                ],
                [
                    "",
                    "",
                    ""
                ],
                [
                    "",
                    "",
                    ""
                ]
            ]
        },
        layout: 'noBorders'
    };

    docDefinition.content.push(fixedWidthTable);

    //NOTE: This is for the payment data section
    let previousNcpdp = "";
    let currentNcpdp = "";

    let paymentTable = {};

    //NOTE: If a check contains multiple NCPDPs, then the paymentTable needs to be re-constructed for each NCPDP section
    function generatePaymentTableBase() {
        paymentTable = {
            table: {
                headerRows: 1,
                body: [
                    [
                        { text: 'Rx Num', style: 'tableHeader' },
                        { text: 'Disp Dt', style: 'tableHeader' },
                        { text: 'Ty', style: 'tableHeader' },
                        { text: 'Billed', style: 'tableHeader', alignment: 'right' },
                        { text: 'Copay', style: 'tableHeader', alignment: 'right' },
                        { text: 'GC', style: 'tableHeader' },
                        { text: 'RC', style: 'tableHeader' },
                        { text: 'Adjust', style: 'tableHeader', alignment: 'right' },
                        { text: 'Paid', style: 'tableHeader', alignment: 'right' },
                        { text: 'Claim Number', style: 'tableHeader' },
                        { text: 'Patient Name', style: 'tableHeader' }
                    ]
                ]
            },
            layout: 'noBorders'
        };
    }

    generatePaymentTableBase();

    let totalPaid = 0;

    for (let i = 0; i < data.paymentDataSection.length; i++) {

        let currentRow = data.paymentDataSection[i];
        let thisIsTheLastRow = false;

        if (i === 0) {
            previousNcpdp = currentRow.ncpdp;
            currentNcpdp = currentRow.ncpdp;

            docDefinition.content.push({ text: currentNcpdp + " " + currentRow.storeName, style: "reportHeader" });
        }
        else {
            previousNcpdp = currentNcpdp;
            currentNcpdp = currentRow.ncpdp;
        }

        if (i === data.paymentDataSection.length - 1) {
            thisIsTheLastRow = true;
        }

        function addTheNextRowAndIncrementTheTotalPaidPerNcpdp() {
            totalPaid = totalPaid + currentRow.paid;
            paymentTable.table.body.push(
                [
                    currentRow.rxNumber,
                    currentRow.serviceDate ? new Date(currentRow.serviceDate).toLocaleDateString() : '',
                    currentRow.type,
                    {
                        text: currentRow.billed.toLocaleString(undefined, { style: 'currency', currency: 'USD', minimumFractionDigits: 2, maximumFractionDigits: 2 }),
                        alignment: 'right'
                    },
                    {
                        text: currentRow.copay.toLocaleString(undefined, { style: 'currency', currency: 'USD', minimumFractionDigits: 2, maximumFractionDigits: 2 }),
                        alignment: 'right'
                    },
                    currentRow.groupCode,
                    currentRow.reasonCode,
                    {
                        text: currentRow.adjusted.toLocaleString(undefined, { style: 'currency', currency: 'USD', minimumFractionDigits: 2, maximumFractionDigits: 2 }),
                        alignment: 'right'
                    },
                    {
                        text: currentRow.paid.toLocaleString(undefined, { style: 'currency', currency: 'USD', minimumFractionDigits: 2, maximumFractionDigits: 2 }),
                        alignment: 'right'
                    },
                    currentRow.claimNumber,
                    currentRow.patientLastName + ", " + currentRow.patientFirstName
                ]
            );
        }

        if (previousNcpdp === currentNcpdp) {
            addTheNextRowAndIncrementTheTotalPaidPerNcpdp();
        }

        if (thisIsTheLastRow || previousNcpdp !== currentNcpdp) {
            //NOTE: This is the row for the total paid amount
            paymentTable.table.body.push(
                [
                    "",
                    "",
                    "",
                    "",
                    "",
                    "",
                    "",
                    "",
                    {
                        text: totalPaid.toLocaleString(undefined, { style: 'currency', currency: 'USD', minimumFractionDigits: 2, maximumFractionDigits: 2 }),
                        alignment: 'right',
                        style: "boldText"
                    },
                    "",
                    ""
                ]
            );

            //NOTE: Add an empty row for spacing
            paymentTable.table.body.push(
                [
                    "",
                    "",
                    "",
                    "",
                    "",
                    "",
                    "",
                    "",
                    "",
                    "",
                    ""
                ]
            );

            docDefinition.content.push(paymentTable);

            //NOTE: Above this is about "finishing up the previous NCPDP, and then moving onto the next one
            //NOTE: After the table is pushed for 1 NCPDP segment, it needs to move onto the next NCPDP segment, which requires it's own header, table, and total row

            if (previousNcpdp !== currentNcpdp) {
                docDefinition.content.push({ text: currentNcpdp + " " + currentRow.storeName, style: "reportHeader" });
                totalPaid = 0;

                generatePaymentTableBase();
                addTheNextRowAndIncrementTheTotalPaidPerNcpdp();
            }

        }

    }

    //NOTE: This is for the check adjustment section
    docDefinition.content.push({ text: "Check Adjustments", style: "reportHeader" });

    let checkAdjustmentTable = {
        table: {
            headerRows: 1,
            body: [
                [
                    { text: 'Facility', style: 'tableHeader' },
                    { text: 'Reason', style: 'tableHeader' },
                    { text: 'Amount', style: 'tableHeader', alignment: 'right' },
                ]
            ]
        },
        layout: 'noBorders'
    };

    for (let j = 0; j < data.checkAdjustmentSection.length; j++) {
        let currentCheckAdjustment = data.checkAdjustmentSection[j];

        checkAdjustmentTable.table.body.push(
            [
                currentCheckAdjustment.memberName,
                currentCheckAdjustment.adjustmentCode,
                {
                    text: currentCheckAdjustment.amount.toLocaleString(undefined, { style: 'currency', currency: 'USD', minimumFractionDigits: 2, maximumFractionDigits: 2 }),
                    alignment: 'right'
                },
            ]
        );
    }

    checkAdjustmentTable.table.body.push(
        [
            "",
            "",
            ""
        ]
    );

    checkAdjustmentTable.table.body.push(
        [
            "",
            "",
            ""
        ]
    );

    docDefinition.content.push(checkAdjustmentTable);

    //NOTE: This is for the check summary section
    docDefinition.content.push({ text: "Check Summary", style: "reportHeader" });

    let totalIndividualMemberPaid = 0;
    let totalIndividualMemberAdjust = 0;
    let totalIndividualMemberTotal = 0;

    let checkSummaryTable = {
        table: {
            headerRows: 1,
            body: [
                [
                    { text: 'Facility', style: 'tableHeader' },
                    { text: 'Paid', style: 'tableHeader', alignment: 'right' },
                    { text: 'Adjusted', style: 'tableHeader', alignment: 'right' },
                    { text: 'Total', style: 'tableHeader', alignment: 'right' },
                ]
            ]
        },
        layout: 'noBorders'
    };

    for (let k = 0; k < data.checkSummarySection.length; k++) {
        let currentCheckSummary = data.checkSummarySection[k];

        totalIndividualMemberPaid = totalIndividualMemberPaid + currentCheckSummary.paid;
        totalIndividualMemberAdjust = totalIndividualMemberAdjust + currentCheckSummary.adjusted;
        totalIndividualMemberTotal = totalIndividualMemberTotal + currentCheckSummary.total;

        checkSummaryTable.table.body.push(
            [
                currentCheckSummary.memberName,
                {
                    text: currentCheckSummary.paid.toLocaleString(undefined, { style: 'currency', currency: 'USD', minimumFractionDigits: 2, maximumFractionDigits: 2 }),
                    alignment: 'right'
                },
                {
                    text: currentCheckSummary.adjusted.toLocaleString(undefined, { style: 'currency', currency: 'USD', minimumFractionDigits: 2, maximumFractionDigits: 2 }),
                    alignment: 'right'
                },
                {
                    text: currentCheckSummary.total.toLocaleString(undefined, { style: 'currency', currency: 'USD', minimumFractionDigits: 2, maximumFractionDigits: 2 }),
                    alignment: 'right'
                }
            ]
        );
    }

    checkSummaryTable.table.body.push(
        [
            "",
            "",
            "",
            ""
        ]
    );

    checkSummaryTable.table.body.push(
        [
            "",
            "",
            "",
            ""
        ]
    );

    docDefinition.content.push(checkSummaryTable);

    //NOTE: This is for the total check summary
    let totalCheckSummaryTable = {
        table: {
            headerRows: 0,
            body: [
                [
                    { text: 'Total Paid: ', style: 'boldText' },
                    { text: totalIndividualMemberPaid.toLocaleString(undefined, { style: 'currency', currency: 'USD', minimumFractionDigits: 2, maximumFractionDigits: 2 }), style: 'boldText' }
                ],
                [
                    { text: 'Total Adjusted: ', style: 'boldText' },
                    { text: totalIndividualMemberAdjust.toLocaleString(undefined, { style: 'currency', currency: 'USD', minimumFractionDigits: 2, maximumFractionDigits: 2 }), style: 'boldText' }
                ],
                [
                    { text: 'Total: ', style: 'boldText' },
                    { text: totalIndividualMemberTotal.toLocaleString(undefined, { style: 'currency', currency: 'USD', minimumFractionDigits: 2, maximumFractionDigits: 2 }), style: 'boldText' }
                ]
            ]
        },
        layout: 'noBorders'
    };

    docDefinition.content.push(totalCheckSummaryTable);

    return docDefinition;
}