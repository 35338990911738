import React, { useState, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import Accordion from '../../Accordion';
import axios from 'axios';
import FeeAndAdjustmentResearchForm from './FeeAndAdjustmentResearchForm';
import { FeatureWidget } from '../../SiSense/FeatureWidget';
import { LoadingSpinner } from './../../layout/LoadingSpinner';
import ErrorHandler from './../../core/ErrorHandler';
import DataTable from '../../grid/DataTable';
import { CustomAction } from '../../grid/CustomActionBarAction'
import ActionCell from './ActionCell';

import { constants } from './FeeAndAdjustmentResearchConstants'
import FeeAndAdjustmentDetailsModal from './FeeAndAdjustmentDetailsModal';

export default function FeeAndAdjustmentResearch(props) {

    const { passedProps } = props;
    const user = passedProps.user.email
    const [submittedFormData, setSubmittedFormData] = useState(null)
    const [error, setError] = useState({})
    const [isLoading, setIsLoading] = useState(false)

    const [data, setData] = useState([])
    const [columns, setColumns] = useState([])
    const [showDetails, setShowDetails] = useState(false)
    const [detailData, setDetailData] = useState([])

    const [detailExportData, setDetailExportData] = useState(null)

    useEffect(() => {
        if (submittedFormData) {
            setData([])
            setIsLoading(true)

            passedProps.auth.getAccessToken()
                .then(accessToken => {
                    axios.get(`api/FeeAndAdjustment/Get`, {
                        params: {
                            user: user,
                            fromDate: submittedFormData.fromDate,
                            toDate: submittedFormData.toDate,
                            locations: submittedFormData.locations,
                            payers: submittedFormData.payers,
                            adjustmentType: submittedFormData.adjustmentType,
                            checkNumber: submittedFormData.checkNumber,
                            feeCode: submittedFormData.feeCode,
                            remittanceStatus: submittedFormData.remitStatus
                        },
                        headers: { Authorization: `Bearer ${accessToken}` }
                    })
                        .then(onSuccess)
                        .catch(onFailure)
                })
                .catch(onFailure)

        }
    }, [submittedFormData])

    useEffect(() => {
        if (detailData.length > 0) {
            let exportColumns = [];
            let exportRows = [];
            let headerProps = constants.detailHeaderProps;

            Object.keys(detailData[0].details[0]).forEach(key => {
                exportColumns.push({
                    title: headerProps[key].title,
                    type: headerProps[key].type,
                    accessor: key,
                    showTotal: headerProps[key].showTotal || false,
                    hidden: headerProps[key].hidden || false
                })
            })

            detailData.forEach(item => {
                item.details.forEach(detail => {
                    exportRows.push(detail);
                })  
            })

            setDetailExportData({ columns: exportColumns, rows: exportRows, page: 'FeeAdjustmentResearch' })
        }
    }, [detailData])

    function onSuccess(response) {
        let rows = response.data;
        const columns = []

        if (rows.length > 0) {
            columns.push({ accessor: "Action", title: "Action", type: "custom" });
            const headerProps = constants.headerProps

            Object.keys(rows[0]).map(key => {
                return columns.push({
                    accessor: key,
                    title: headerProps[key].title,
                    type: headerProps[key].type,
                    showTotal: headerProps[key].showTotal,
                    hidden: headerProps[key].hidden
                })
            })
            rows.map(row => {
                row["Action"] = renderActionCell(row['feeCode'])
            })
            setError({})
        }
        else {
            setError({ status: 201, Message: 'No data found for given params' })
        }

        setData(rows)
        setColumns(columns)
        setIsLoading(false)
    }

    function onFailure(error) {
        setIsLoading(false)
        setError(error.response)
    }

    function renderActionCell(feeCode) {
        return (
            <ActionCell feeCode={feeCode}/>
        )
    }

    function getNewRowSelectedValue(rows) {
        if (rows.length > 1) {
            return !rows.every(x => {
                return x.selected === true
            })
        }
        return !rows[0].selected
    }

    function handleRowSelected(rows) {
        let newData = [...data]
        let selectedValue = getNewRowSelectedValue(rows)

        rows.forEach(row => {
            let index = newData.findIndex((x => x.feeCode == row.feeCode))
            newData[index].selected = selectedValue
        })
        setData(newData)
    }

    async function getReport(feeCodes) {
        try {
            setIsLoading(true)

            let accessToken = await passedProps.auth.getAccessToken()
            let response = await axios.get(`api/FeeAndAdjustment/GetDetails`, {
                params: {
                    user: user,
                    fromDate: submittedFormData.fromDate,
                    toDate: submittedFormData.toDate,
                    locations: submittedFormData.locations,
                    payers: submittedFormData.payers,
                    checkNumber: submittedFormData.checkNumber,
                    status: submittedFormData.remitStatus,
                    feeCodes: feeCodes.join(',')
                },
                headers: { Authorization: `Bearer ${accessToken}` }
            })

            if (response.data.length > 0) {
                setDetailData(response.data)
                setShowDetails(true);
            } else {
                setError({ status: 201, Message: 'No data found for given params' })
            }
        } catch (e) {
            setError(e.response)
        }
        setIsLoading(false)
    }

    function getCustomActions() {
        return (
            <CustomAction label="View Selected Reports" onClick={() => getReports()} />
        )
    }

    function getReports() {

        const selectedFeeCodes = data.reduce((a, o) => (o.selected && a.push(o.feeCode), a), [])
        if (selectedFeeCodes.length) {
            getReport(selectedFeeCodes)
        } else {
            alert("Please select atleast 1 row to view reports.")
        }
    }


    function handleCellClick(row, column) {
        switch (column) {
            case 'viewReport':
                getReport([row.feeCode])
                break;
        }
    }
    return (
        <div className="feature">
            <LoadingSpinner isDataLoading={isLoading} controlsName={'FeeAndAdjustmentResearch'} />
            <ErrorHandler error={error} onClose={() => { setError({}) }} />


            <Accordion defaultExpanded label="Search & Filter">
                <Row>
                    <Col>
                        <FeeAndAdjustmentResearchForm handleFormSubmit={setSubmittedFormData} />
                    </Col>
                    <Col>
                        <FeatureWidget
                            title='Last 30 Days Largest Impact by Type'
                            iFrameId='sisense-fee-and-adjustment-frame'
                            filters={{}}
                            dashboard={process.env.REACT_APP_Dashboard_Adjustments}
                            widget={process.env.REACT_APP_Widget_Adjustments}
                            settings={{ showToolbar: false, showLeftPane: false, showRightPane: false }}
                            hidden={false}
                        />
                    </Col>

                </Row>

            </Accordion>
            <DataTable
                columns={columns}
                rows={data}
                id="fee-and-adjustment-research"
                handleCellClick={handleCellClick}
                handleRowSelected={handleRowSelected}
                customActionBarActions={getCustomActions()}
                exportable pagination filterable selectableRows totalFooter
            />
            <FeeAndAdjustmentDetailsModal data={detailData} exportData={detailExportData} show={showDetails} onClose={() => setShowDetails(false)}/>
        </div>
    );
}