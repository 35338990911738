import React, { useState, useEffect } from 'react';
import { Row, Col, Card } from 'react-bootstrap';

import { loadScript, isLoaded } from '../../SiSense/SiSenseScriptLoader'
import Dashboard from '../../SiSense/Dashboard'

export function FeatureWidget(props) {

    const [scriptLoaded, setScriptLoaded] = useState(false)

    useEffect(() => {
        if (isLoaded()) {
            setScriptLoaded(true)
        } else {
            loadScript().then(() => {
                setScriptLoaded(true)
            }).catch(e => console.log(e))
        }
    }, [])

    const settings = { showToolbar: false, showLeftPane: false, showRightPane: false }

    return (
        <div>
            <Card style={{ width: '50rem', height: '16rem', float: 'right' }}>
                <Card.Body style={{}} >
                    <Card.Title className="text-center" >{}</Card.Title>
                    <Row>
                        <Col>
                            <div className="dashboard-widget-payment-exception-wrapper">
                                <Dashboard
                                    scriptLoaded={scriptLoaded}
                                    iFrameId={'sisense-payment-exceptions-overpayments'}
                                    filters={[]}
                                    dashboard={process.env.REACT_APP_Dashboard_PaymentException}
                                    widget={process.env.REACT_APP_Widget_PaymentExceptionOverpayment}
                                    settings={{ showToolbar: false, showLeftPane: false, showRightPane: false }}
                                    hidden={false}
                                />
                            </div>
                        </Col>
                        <Col>
                            <div className="dashboard-widget-payment-exception-wrapper">
                                <Dashboard
                                    scriptLoaded={scriptLoaded}
                                    iFrameId={'sisense-payment-exceptions-reversedClaims'}
                                    filters={[]}
                                    dashboard={process.env.REACT_APP_Dashboard_PaymentException}
                                    widget={process.env.REACT_APP_Widget_PaymentExceptionReversedClaims}
                                    settings={{ showToolbar: false, showLeftPane: false, showRightPane: false }}
                                    hidden={false}
                                />
                            </div>
                        </Col>
                        <Col>
                            <div className="dashboard-widget-payment-exception-wrapper">
                                <Dashboard
                                    scriptLoaded={scriptLoaded}
                                    iFrameId={'sisense-payment-exceptions-unappliedCash'}
                                    filters={[]}
                                    dashboard={process.env.REACT_APP_Dashboard_PaymentException}
                                    widget={process.env.REACT_APP_Widget_PaymentExceptionUnappliedCash}
                                    settings={settings}
                                    hidden={false}
                                />
                            </div>
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
        </div>
    )
}