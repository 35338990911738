import React, { useState, useEffect } from 'react';
import CSVFormatter from './csvFormatter';
import ReactExport from 'react-csv-downloader';

export default function csvExporter(props) {
    const { columns = [], rows = [], custom = {}, name = 'csvExport', button = 'Comma-separated Value .csv', handleActionBarStartAction, handleActionBarCompleteAction } = props;

    const [csvColumns, setCSVColumns] = useState([])
    const [csvRows, setCSVRows] = useState([])

    const exportButton = <span><i className='fa fa-file-o' aria-hidden></i> {button}</span>

    useEffect(() => {
        if (custom) {
            switch (custom.PrimaryKey) {
                case 'pmid': setCSVRows(filterByPaymentID()); break;
                case 'clid': setCSVRows(filterByClaimID()); break;
                default: setCSVRows(custom.rows); break;
            }
            setCSVColumns(custom.columns)
        }
        else {
            setCSVColumns(columns)
            setCSVRows(rows)
        }
    }, [rows])

    function filterByPaymentID() {
        let data = [];
        for (let i = 0; i < rows.length; i++) {
            let currentRow = rows[i];
            let customRow = custom.rows[currentRow.pmid];
            if (customRow) {
                customRow.forEach(row => { data.push(row) })
            }
        }
        return data;
    }

    function filterByClaimID() {
        let data = [];
        for (let i = 0; i < rows.length; i++) {
            let currentRow = rows[i];
            let customRow = custom.rows[currentRow.clid];
            if (customRow) {
                customRow.forEach(row => { data.push(row) })
            }
        }
        return data;
    }

    const data = CSVFormatter(csvColumns, csvRows)

    return (<ReactExport wrapColumnChar='"' filename={name} datas={data.rows} columns={data.columns} separator=",">{exportButton}</ReactExport>);
}