import axios from 'axios';
import React, { useState } from 'react';
import DataTable from '../../grid/DataTable';
import ErrorHandler from './../../core/ErrorHandler';
import { Row, Col } from 'react-bootstrap';
import { LoadingSpinner } from './../../layout/LoadingSpinner';
import { DSOByPayerTrackingForm } from './DSOByPayerTrackingForm';
import Accordion from '../../Accordion';
import { constants } from './DSOByPayerTrackingConstants'
import { FeatureWidget } from '../../SiSense/FeatureWidget';

export default function DSOByPayerTracking(props) {
    const { passedProps } = props
    const user = passedProps.user.email
    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState({})

    const [data, setData] = useState([])
    const [columns, setColumns] = useState([])

    function handleFormSubmit(e) {
        setIsLoading(true)
        passedProps.auth.getAccessToken()
            .then(accessToken => {
                axios.get(`api/DaysSalesOutstanding/GetAverageByPayer`, {
                    params: {
                        user: user,
                        toDate: e.toDate,
                        fromDate: e.fromDate,
                        mmids: e.selectedLocation,
                        pbids: e.selectedPayer
                    },
                    headers: { Authorization: `Bearer ${accessToken}` }
                })
                    .then(onSuccess)
                    .catch(onFailure)
            })
            .catch(onFailure)
    }

    function onSuccess(response) {
        let columns = [];
        let data = response.data

        if (data.length > 0) {
            const headerProps = constants.headerProps
            Object.keys(data[0]).map(key => {
                columns.push({
                    accessor: key,
                    title: headerProps[key].title,
                    type: headerProps[key].type,
                    showTotal: headerProps[key].showTotal,
                    isCellAlert: headerProps[key].isCellAlert
                })
            });
            setError({})
        } else {
            setError({ status: 201, Message: 'No data found for given params' })
        }
        setColumns(columns);
        setData(data);
        setIsLoading(false)
    }

    function onFailure(error) {
        setError(error.response);
        setColumns([])
        setData([])
        setIsLoading(false)
    }

    return (
        <React.Fragment>
            <div className="feature">
                <LoadingSpinner isDataLoading={isLoading} controlsName={'dsoByPayerTracking'} />
                <ErrorHandler error={error} onClose={() => { setError({}) }} />
                <Accordion defaultExpanded label="Search & Filter">
                    <Row>
                        <Col>
                            <DSOByPayerTrackingForm handleFormSubmit={handleFormSubmit} />
                        </Col>
                        <Col>
                            <FeatureWidget
                                title='90 Day DSO by Payer'
                                iFrameId='sisense-DSObpt-frame'
                                filters={{}}
                                dashboard={process.env.REACT_APP_Dashboard_DaySalesOutstanding}
                                widget={process.env.REACT_APP_Widget_DaySalesOutstanding}
                                settings={{ showToolbar: false, showLeftPane: false, showRightPane: false }}
                                hidden={false}
                                size="lg"
                            />
                        </Col>
                    </Row>
                </Accordion>
                <DataTable columns={columns} rows={data} id="dso-by-payer-tracking" exportable filterable totalFooter pagination />
            </div>
        </React.Fragment>
    );
}