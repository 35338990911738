import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Nav, NavDropdown } from 'react-bootstrap';
import CSVExporter from '../export/csvExporter';
import ExcelExporter from '../export/excelExporter';
import PDFExporter from '../export/pdfExporter';

import NetRxLogo from '../assets/NetRxLogo.png';

import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";

export default function ActionBar(props) {
    const { exportable, rows, columns, customExport, exportOptions, customActions, handleActionBarStartAction, handleActionBarCompleteAction } = props

    if (columns.length === 0 || rows.length === 0) return null
    return (
        <Nav className="justify-content-left">
            <ActionBarExport show={exportable} rows={rows} columns={columns} customExport={customExport} exportOptions={exportOptions} handleActionBarStartAction={handleActionBarStartAction} handleActionBarCompleteAction={handleActionBarCompleteAction} />
            {customActions}
        </Nav>
    )
}

const columnExact = {
    accessor: PropTypes.string.isRequired,
    type: PropTypes.oneOf(['text', 'number', 'money', 'percent', 'bool', 'checkbox', 'date', 'custom']).isRequired,
    title: PropTypes.string.isRequired,
    showTotal: PropTypes.bool,
    selectable: PropTypes.bool,
    truncatable: PropTypes.bool,
    hidden: PropTypes.bool,
}

ActionBar.propTypes = {
    exportable: PropTypes.bool.isRequired,
    rows: PropTypes.arrayOf(PropTypes.object).isRequired,
    columns: PropTypes.arrayOf(PropTypes.exact(columnExact)).isRequired,
    //addActionBarOption: PropTypes.Text
}

function ActionBarExport(props) {
    const { show, rows, columns, customExport, exportOptions, handleActionBarStartAction, handleActionBarCompleteAction } = props

    pdfMake.vfs = pdfFonts.pdfMake.vfs;

    if (!show || rows.length === 0 || columns.length === 0) return null
    
    const exportButton = useMemo(() => {
        return (
            <React.Fragment>
                <canvas id="hiddenCanvasForPdf" style={{ display: "none" }} width={185} height={70}></canvas>

                <Nav variant="pills">
                    <NavDropdown title={<span><i className="fa fa-download" aria-hidden></i> Download As...</span>} id="nav-dropdown">
                        <NavDropdown.Item>
                            <ExcelExporter columns={columns} rows={rows} custom={customExport} handleActionBarStartAction={handleActionBarStartAction} handleActionBarCompleteAction={handleActionBarCompleteAction} />
                        </NavDropdown.Item>
                        <NavDropdown.Item>
                            <CSVExporter columns={columns} rows={rows} custom={customExport} handleActionBarStartAction={handleActionBarStartAction} handleActionBarCompleteAction={handleActionBarCompleteAction} />
                        </NavDropdown.Item>
                        <NavDropdown.Item style={exportOptions.indexOf(`pdf`) > -1 ? { display: 'block' } : { display: 'none' }}>
                            <PDFExporter columns={columns} rows={rows} custom={customExport} page={customExport ? customExport.page : ''} handleActionBarStartAction={handleActionBarStartAction} handleActionBarCompleteAction={handleActionBarCompleteAction} />
                        </NavDropdown.Item>
                    </NavDropdown>
                </Nav>
            </React.Fragment>
        )
    }, [rows, columns])

    return (
        <React.Fragment>
            {exportButton}
        </React.Fragment>
    )
}

ActionBarExport.propTypes = {
    show: PropTypes.bool.isRequired,
    rows: PropTypes.arrayOf(PropTypes.object).isRequired,
    columns: PropTypes.arrayOf(PropTypes.exact(columnExact)).isRequired,
}

function CustomAction(props) {
    const { addActionbarClick, addActionBarOption, show } = props;

    if (!show) return null

    return (
        <Nav.Item >
            <Nav.Link onClick={addActionbarClick}>
                <span className="" > {addActionBarOption}</span>
            </Nav.Link>
        </Nav.Item>
    )
}