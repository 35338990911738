import React, { useState, useEffect } from 'react';
import './Dashboard.css'

export default function Dashboard(props) {
    const { iFrameId, filters, dashboard, widget, settings, hidden, scriptLoaded, type = "iframe" } = props

    const [frame, setFrame] = useState(null)
    const [id, setId] = useState(iFrameId)

    // once script has been loaded create sisense frame object
    useEffect(() => {
        if (scriptLoaded && type !== "iframe") {
            if (frame == null) {
                const { SisenseFrame } = window['sisense.embed']
                const sisenseFrame = new SisenseFrame({
                    url: process.env.REACT_APP_SisenseURI,
                    dashboard: dashboard,
                    settings: settings,
                    element: document.getElementById(id),
                    widget: widget
                });

                if (filters.length > 0) {
                    sisenseFrame.dashboard.applyFilters(filters)
                }

                sisenseFrame.render(document.getElementById(id), hidden)
                    .then(() => {
                        setId(sisenseFrame._id)
                        setFrame(sisenseFrame)
                    })
            }
        }
    }, [scriptLoaded])

    // On filter updates re-render
    useEffect(() => {
        if (frame != null) {
            frame.dashboard.applyFilters(filters)
            frame.render(document.getElementById(id), hidden)
        }
    }, [filters])


    const iframeURL = `${process.env.REACT_APP_SisenseURI}/app/main#/dashboards/${dashboard}/widgets/${widget}?embed=true`
    return type === "iframe" ? (
        <iframe width="100%" frameborder="0" src={iframeURL}></iframe>
    ) : (
            <iframe id={id} className="sisense-dashboard" />
        )
}