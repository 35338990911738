import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import { Form, Col, Button } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import MemberLocationDropdown from "../../form/MemberLocationDropdown";

import FormValidation from "../../form/validation/FormValidation";

export function TotalSalesForm(props) {
  const [toDate, setToDate] = useState(new Date());
  const [fromDate, setFromDate] = useState(
    new Date(new Date().setDate(new Date().getDate() - 7))
  );
  const [dateType, setDateType] = useState("claimsDispense");
  const [paidAmountType, setPaidAmountType] = useState("promiseToPay");
  const [includeCopay, setIncludeCopay] = useState(false);
  const [omitIgnored, setOmitIgnored] = useState(false);
  const [locationOptions, setLocationOptions] = useState([]);
  const [selectedLocations, setSelectedLocations] = useState([]);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if (!loaded && selectedLocations.length > 0) {
      setLoaded(true);
      submitForm();
    }
  }, [selectedLocations]);

  function submitForm() {
    let parameters = {
      fromDate: fromDate,
      toDate: toDate,
      dateType: dateType,
      paidAmountType: paidAmountType,
      includeCopay: includeCopay,
      omitIgnored: omitIgnored,
      selectedLocations: selectedLocations,
      requiredFields: ["fromDate", "toDate"],
    };

    let validationIssueMessage = FormValidation(parameters);

    if (validationIssueMessage) {
      alert(validationIssueMessage);
    } else {
      props.handleFormSubmit({
        toDate: toDate.toLocaleDateString(),
        fromDate: fromDate.toLocaleDateString(),
        dateType: dateType,
        paidAmountType: paidAmountType,
        includeCopay: includeCopay,
        omitIgnored: omitIgnored,
        selectedLocation: selectedLocations.join(","),
      });
    }
  }

  function handleSubmit(event) {
    event.preventDefault();
    submitForm();
  }

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Row className="justify-content-start">
        <Form.Group as={Col} md="auto" className="d-flex flex-column">
          <Form.Label>Date From</Form.Label>
          <Form.Control
            name="fromDate"
            as={DatePicker}
            selected={fromDate}
            onChange={(date) => setFromDate(date)}
          />
        </Form.Group>
        <Form.Group as={Col} md="auto" className="d-flex flex-column">
          <Form.Label>Date To</Form.Label>
          <Form.Control
            name="toDate"
            as={DatePicker}
            selected={toDate}
            onChange={(date) => setToDate(date)}
          />
        </Form.Group>
        <Form.Group as={Col} md="auto" className="d-flex flex-column">
          <Form.Label>Location (NCPDP)</Form.Label>
          <Form.Control
            as={MemberLocationDropdown}
            onChange={(locations) => setSelectedLocations(locations)}
          />
        </Form.Group>
        <Form.Group as={Col} md="auto" className="d-flex flex-column">
          <Button className="inline-show-result-button" size="sm" type="submit">
            Show Results
          </Button>
        </Form.Group>
      </Form.Row>
      <Form.Row className="justify-content-start">
        <Form.Group as={Col} md="auto">
          <Form.Label>Date Type</Form.Label>
          <div key="dateType" className="mb-3 mr-2">
            <Form.Check
              custom
              type="radio"
              id={`dateType${props._uid}-1`}
              name="dateType"
              onChange={(event) => setDateType(event.target.value)}
              label="Claims Dispense"
              value="claimsDispense"
              defaultChecked
            />
            <Form.Check
              custom
              type="radio"
              id={`dateType${props._uid}-2`}
              name="dateType"
              onChange={(event) => setDateType(event.target.value)}
              label="Claims Transaction"
              value="claimsTransaction"
            />
            {/*<Form.Check custom type="radio" id={`dateType${props._uid}-3`} name="dateType" onChange={(event) => setDateType(event.target.value)} label="Check Date" value="checkDate" />*/}
            {/*<Form.Check custom type="radio" id={`dateType${props._uid}-4`} name="dateType" onChange={(event) => setDateType(event.target.value)} label="Check Payment" value="checkPayment" />*/}
          </div>
        </Form.Group>
        <Form.Group as={Col} md="auto">
          <Form.Label>Paid Amount Type</Form.Label>
          <div key="PaidAmountType" className="mb-3 mr-2">
            <Form.Check
              custom
              type="radio"
              id={`PaidAmountType${props._uid}-1`}
              name="PaidAmountType"
              onChange={(event) => setPaidAmountType(event.target.value)}
              label="Promise to Pay"
              value="promiseToPay"
              defaultChecked
            />
            <Form.Check
              custom
              type="radio"
              id={`PaidAmountType${props._uid}-2`}
              name="PaidAmountType"
              onChange={(event) => setPaidAmountType(event.target.value)}
              label="Remittance Paid"
              value="remittancePaid"
            />
          </div>
        </Form.Group>
        <Form.Group
          as={Col}
          md="auto"
          controlId="TotalSalesByLocationControllers"
        >
          <div key="TotalSalesByLocationControllers" className="mb-3 mt-3 pt-3">
            <Form.Check
              custom
              type="checkbox"
              id={`IncludeCopay${props._uid}`}
              name="includeCopay"
              label="Include Copay Values"
              onChange={(event) => setIncludeCopay(event.target.checked)}
            />
            <Form.Check
              custom
              type="checkbox"
              id={`OmitIgnored${props._uid}`}
              name="omitIgnored"
              label="Omit Ignored Claims"
              onChange={(event) => setOmitIgnored(event.target.checked)}
            />
          </div>
        </Form.Group>
      </Form.Row>
    </Form>
  );
}

TotalSalesForm.propTypes = {
  handleFormSubmit: PropTypes.func.isRequired,
};
