import React, { useState, useEffect } from 'react';
import { Row, Col} from 'react-bootstrap';
import { DetailsModal } from '../../grid/DetailsModal';
import ErrorHandler from './../../core/ErrorHandler';
import axios from 'axios';
import { constants } from './PaymentExceptionConstants'
import DataTable from '../../grid/DataTable';
import PrescriptionDetailActionCell from './PrescriptionDetailActionCell';

import { formatCheckDetailsForExport } from '../../export/CheckDetailsExcelExport'
import { RowExcelExporter } from '../../export/RowExcelExporter'
import singleCheckPDFExporter from '../../export/SingleCheckPDFExporter';
import NetRxLogo from '../../assets/NetRxLogo.png';
import pdfMake from "pdfmake/build/pdfmake";
import { LoadingSpinner } from '../../layout/LoadingSpinner';

export default function PrescriptionDetailsModal(props) {
    const { auth, clid, show, handleClose, handleCellClick, user } = props;
    const [scriptDetailData, setScriptDetailData] = useState([])
    const [scriptDetailColumns, setScriptDetailColumns] = useState([])
    const [checkDetailData, setCheckDetailData] = useState([])
    const [checkDetailColumns, setCheckDetailColumns] = useState([])
    const [detailError, setDetailError] = useState({})

    const [excelData, setExcelData] = useState(null)
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        if (clid) {
        auth.getAccessToken()          
            .then(accessToken => {
            axios.get(`api/PaymentException/GetScriptDetails`, {
                params: {
                    user: user,
                    clid: clid
                },
                headers: { Authorization: `Bearer ${accessToken}` }
            })
                .then(setScriptData)
                .catch(onFailure)
        })
                .catch(onFailure)

        }

    }, [clid])

    useEffect(() => {
        if (clid && scriptDetailData) {
            auth.getAccessToken()
                .then(accessToken => {
                    axios.get(`api/PaymentException/GetCheckDetails`, {
                        params: {
                            user: user,
                            clid: clid
                        },
                        headers: { Authorization: `Bearer ${accessToken}` }
                    })
                        .then(setGrid)
                        .catch(onFailure)
                })
                .catch(onFailure)
        }
    }, [scriptDetailData])

    function onCellClick(row, column) {
        switch (column) {
            case 'excelExport':
                getExcel(row.pmid)
                break;
            case 'pdfExport':
                getPdf(row.pmid)
                break;
        }
    }

    async function getExcel(id) {
        setIsLoading(true)
        try {
            const accessToken = await auth.getAccessToken()
            const response = await axios.get(`api/Check/GetSingleCheckExcelExport`, {
                params: { pmid: id },
                headers: { Authorization: `Bearer ${accessToken}` }
            })

            setExcelData(formatCheckDetailsForExport(response.data))
        } catch (e) {
            console.log(e)
        }   
        setIsLoading(false)
    }

    async function getPdf(id) {
        setIsLoading(true)
        try {
            const accessToken = await auth.getAccessToken()
            const response = await axios.get(`api/Check/GetSingleCheckPdfExport`, {
                params: { PMID: id },
                headers: { Authorization: `Bearer ${accessToken}` }
            })

            var ctx = document.getElementById('hiddenCanvasForSingleCheckPdf').getContext('2d');
            var img = new Image();
            img.onload = () => {
                ctx.drawImage(img, 0, 0);

                const data = response.data
                let pdfFileName = "REMITADVICE";
                let docDefinition = singleCheckPDFExporter(data);
                if (docDefinition) {
                    pdfMake.createPdf(docDefinition).download(pdfFileName);
                    setIsLoading(false)
                }
            }
            img.src = NetRxLogo;
        } catch (e) {
            setIsLoading(false)
            console.log(e)
        }
    }

    function setScriptData(response) {
        const scriptDetailColumns = []
        const scriptDetailheaderProps = constants.scriptDetailheaderProps
        

        Object.keys(response.data[0]).map(key => {
            return scriptDetailColumns.push({
                accessor: key,
                title: scriptDetailheaderProps[key].title,
                type: scriptDetailheaderProps[key].type,
                showTotal: scriptDetailheaderProps[key].showTotal,
            })
        })

        setScriptDetailData(response.data)
        setScriptDetailColumns(scriptDetailColumns)
    }

    function setGrid(response) {
        let checkDetailRows = response.data
        const checkDetailColumns = []

        if (response.data && checkDetailRows.length  && scriptDetailData.length) {
            const checkDetailheaderProps = constants.checkDetailheaderProps
            checkDetailColumns.push({ accessor: "Action", title: "Download Report", type: "custom", fixedLeft: true });

            Object.keys(response.data[0]).map(key => {
                return checkDetailColumns.push({
                    accessor: key,
                    title: checkDetailheaderProps[key].title,
                    type: checkDetailheaderProps[key].type,
                    showTotal: checkDetailheaderProps[key].showTotal,
                    hidden: checkDetailheaderProps[key].hidden
                })
            })

            checkDetailRows.map(row => {
                row["Action"] = renderActionCell(row['pmid'])
            })

            setCheckDetailColumns(checkDetailColumns)
            setCheckDetailData(checkDetailRows)
            setDetailError({})
        }
        else {
            setDetailError({ status: 201, Message: 'No data found for given params' })
        }
    }

    function onFailure(error) {
        setDetailError(error);
        setCheckDetailColumns([])
        setScriptDetailColumns([])
        setCheckDetailData([])
        setScriptDetailData([])
    }

    function renderActionCell(key) {
        return (
            <PrescriptionDetailActionCell key={key} />
        )
    }

    return (
        <DetailsModal title="Prescription Detail" show={show} handleClose={handleClose}>
            <canvas id="hiddenCanvasForSingleCheckPdf" style={{ display: "none" }} width={185} height={70} />
            <LoadingSpinner isDataLoading={isLoading}/> 
            <RowExcelExporter dataSet={excelData} reset={() => setExcelData(null)} filename='Remittance Lookup'/>
            <ErrorHandler error={detailError} onClose={() => { setDetailError({}) }} />
            <Row>
                <Col>
                    <DataTable
                        columns={scriptDetailColumns}
                        rows={scriptDetailData}
                        id="Prescription-Details-table"
                    />
                </Col>
                <Col />
            </Row>
            <br />
            <h4>Associated Checks</h4>
            <DataTable
                columns={checkDetailColumns}
                rows={checkDetailData}
                id="check-details-table"
                pagination
                handleCellClick={onCellClick}
            />
        </DetailsModal>
    )
}