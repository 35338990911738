import React, { useState } from 'react';
import { Row, Col, Button, Form, Container, Modal} from 'react-bootstrap';
import { constants } from './PaymentExceptionConstants'
import SingleSelectDropdown from '../../SingleSelectDropdown';



export default function IgnoreModal(props) {
    const { show, selectedNCPDP, selectedRX, handleClose, handleSaveIgnoreClick } = props;
    const [selectedIgnoreOption, setSelectedIgnoreOption] = useState('')
    const [ignoreNote, setIgnoreNote] = useState('')

        return (
            <Modal className="grid-claim-ignore-modal" show={show} handleClose={handleClose}>
                < Modal.Header >
                    <h3>Ignore Details</h3>
                </Modal.Header >
                <Modal.Body>
                <Container>
                    <Row>
                        <Col> NCPDP </Col>
                        <Col> Rx Number</Col>
                    </Row>
                    <Row>
                        <Col>{selectedNCPDP}</Col>
                        <Col>{selectedRX}</Col>
                    </Row>
                    <br />
                    <Row>
                        <Col> Ignore Code  </Col>
                    </Row>
                    <Row md={1}>
                        <Col>
                            <SingleSelectDropdown options={constants.IgnoreOptions} onChange={(selectedIgnoreOption) => { setSelectedIgnoreOption(selectedIgnoreOption) }} />
                        </Col>
                    </Row>
                    <br />
                    <Row md={1}>
                        <Col>
                            <Form.Group controlId="txtIgnoreNote">
                                <Form.Label>  Comments  </Form.Label>
                                <Form.Control as="textarea" rows='5' placeholder="Type your message here..." onChange={(event) => { setIgnoreNote(event.target.value) }} > </Form.Control>
                            </Form.Group>
                        </Col>
                    </Row>
                    <br />
                    <Row>
                        <Col>
                            <Button variant="secondary" onClick={handleClose}>Cancel</Button>
                        </Col>
                        <Col>
                            <Button className='primary-button' onClick={() => handleSaveIgnoreClick(selectedIgnoreOption, ignoreNote)}>Save</Button>
                        </Col>
                    </Row>
                    </Container>
                </Modal.Body>
            </Modal>
        )
}