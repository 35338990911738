import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, useNavigate } from "react-router-dom";
import { Security } from "@okta/okta-react";
import { OktaAuth, toRelativeUrl } from "@okta/okta-auth-js";

//NOTE: These import lines come from the dev branch, see if it's still needed here or not, it may be a matter of moving the "BringYourOwnData" component to a different file
//import { ContactUs } from './components/pages/ContactUs';
//import { PrivacyPolicy } from './components/pages/PrivacyPolicy';
//import { TermsAndConditions } from './components/pages/TermsAndConditions';
//import TotalSalesPaymentSummary from './components/pages/TotalSalesPaymentSummary/TotalSalesPaymentSummary';
//import TwoYearBusinessTrend from './components/pages/TwoYearBusinessTrend/TwoYearBusinessTrend';
//import DSOByPayerTracking from './components/pages/DSOByPayerTracking/DSOByPayerTracking';
//import Aging from './components/pages/Aging/Aging';
//import PriceChange from './components/pages/DrugPriceChange/PriceChange';
//import Workbench from './components/pages/Workbench/Workbench';
//import BringYourOwnData from './components/pages/Byod/BringYourOwnData';
//import LocationComparison from './components/pages/Benchmarking/LocationComparison';
//import RemitReceiptVerification from './components/pages/RemitReceiptVerification/RemitRcptVerification';
//import ArchiveRemittanceReview from './components/pages/ArchiveRemittanceReview/ArchiveRemittanceReview'
//import SCCEvaluation from './components/pages/SCCEvaluation/SCCEvalution'
//import RemittanceLookup from './components/pages/RemittanceLookup/RemittanceLookup';
//import CopayAdjustmentManager from './components/pages/CopayAdjustmentManager/CopayAdjustmentManager'
//import FeeAndAdjustmentResearch from './components/pages/FeeAndAdjustmentResearch/FeeAndAdjustmentResearch'
//import PaymentException from './components/pages/PaymentException/PaymentException'
//import PayerGERTracker from './components/pages/PayerGERTracker/PayerGERTracker'
//import PayerMessagingManager from './components/pages/PayerMessagingManager/PayerMessagingManager'
//import Layout from './components/layout/Layout'
// import TelemetryProvider from './components/AppInsights/TelemetryProvider';
// import { getAppInsights } from "./components/AppInsights/TelemetryService";
import { UserContext } from "./components/context/UserContext";
import { setWithExpiry } from "./utils/localStorageAccess";
import RMERouter from "./components/router/RMERouter";

function onAuthRequired({ history }) {
  history.push("/login");
}

let appInsights = null;
export default function App(props) {
  let oktaIssuer = process.env.REACT_APP_OKTADomain + "/oauth2/default";

  //NOTE: I think we might need to store this somewhere on the deployed environment? Figure this out eventually
  let oktaClientId = process.env.REACT_APP_OKTAClientID;
  // console.log(oktaClientId);

  const constructedOktaAuth = new OktaAuth({
    issuer: oktaIssuer,
    clientId: oktaClientId,
    // redirectUri: window.location.origin + '/login/callback'
    // redirectUri: "http://localhost:3000/testfile"
    redirectUri: window.location.origin + "/implicit/callback",
  });

  const navigate = useNavigate();

  const constructedRestoreOriginalUri = async (_oktaAuth, originalUri) => {
    navigate(toRelativeUrl(originalUri, window.location.origin), {
      replace: true,
    });
  };

  const [programs, setPrograms] = useState(null);
  const [organizations, setOrganizations] = useState(null);
  const [currentOrganization, setCurrentOrganization] = useState(null);
  const [locations, setLocations] = useState(null);
  const [ncpdps, setNcpdps] = useState(null);
  const [payers, setPayers] = useState(null);
  const [email, setEmail] = useState("");

  let userConfig = {
    programs: programs,
    onProgramsChange: (programs) => setPrograms(programs),
    organizations: organizations,
    onOrganizationsChange: (organizations) => setOrganizations(organizations),
    currentOrganization: currentOrganization,
    onCurrentOrganizationChange: (currentOrganization) =>
      setCurrentOrganization(currentOrganization),
    locations: locations,
    onLocationsChange: (locations) => setLocations(locations),
    ncpdps: ncpdps,
    onNcpdpsChange: (ncpdps) => setNcpdps(ncpdps),
    payers: payers,
    onPayersChange: (payers) => setPayers(payers),
    email: email,
    onEmailChange: (email) => setEmail(email),
  };

  useEffect(() => {
    if (locations) {
      setWithExpiry("rme-userConfig", userConfig, 3600000);
    }
  }, [locations]);

  // return (
  //     <UserContext.Provider value={userConfig}>
  //         <Router>
  //             <TelemetryProvider
  //                 instrumentationKey="5a586087-db6d-4877-bf5d-fcef6773ba0e"
  //                 after={() => appInsights = getAppInsights()}
  //             >
  //                 <Security
  //                     issuer={oktaIssuer}
  //                     client_id={process.env.REACT_APP_OKTAClientID }
  //                     redirect_uri={window.location.origin + '/implicit/callback'}
  //                     onAuthRequired={onAuthRequired}
  //                     pkce={true}>
  //                     <RMERouter appInsights={appInsights}/>
  //                 </Security>
  //             </TelemetryProvider>
  //         </Router>
  //     </UserContext.Provider>
  // )

  return (
    <UserContext.Provider value={userConfig}>
      <Security
        oktaAuth={constructedOktaAuth}
        restoreOriginalUri={constructedRestoreOriginalUri}
        issuer={oktaIssuer}
        client_id={process.env.REACT_APP_OKTAClientID}
        onAuthRequired={onAuthRequired}
        pkce={true}
      >
        <RMERouter appInsights={appInsights} />
      </Security>
    </UserContext.Provider>
  );
}
