
export default function pdfFormatter(cols = [], rows = [], key = "") {

    const docDefinition = {
        verifyReceipt : {
            pageSize: { width: 1500, height: 2500 },
            footer: function (currentPage, pageCount, pageSize) {
                let PageFooter = "Page " + currentPage.toString() + "\n *Data viewed and printed from this website is dynamic and subject to change at any time upon the continuous loading of 835 ERA files";
                return { text: PageFooter, alignment: 'center', margin: [0, 0, 0, 25] }
            },
            content: [
                {
                    image: document.getElementById('hiddenCanvasForPdf').toDataURL(),
                    margin: [0, 0, 0, 15]
                },
                {
                    text: 'All Pending Remittance Advices', 
                    style: 'reportHeader'
                }
            ],
            styles: {
                tableHeader: { bold: true, fontSize: 13, color: 'black' },
                reportHeader: { bold: true, fontSize: 16, color: 'black' },
                boldText: { bold: true }
            }
        },
        archiveReceipt : {
            pageSize: { width: 1500, height: 2500 },
            footer: function (currentPage, pageCount, pageSize) {
                let PageFooter = "Page " + currentPage.toString() + "\n *Data viewed and printed from this website is dynamic and subject to change at any time upon the continuous loading of 835 ERA files";
                return { text: PageFooter, alignment: 'center', margin: [0, 0, 0, 25] }
            },
            content: [
                {
                    image: document.getElementById('hiddenCanvasForPdf').toDataURL(),
                    margin: [0, 0, 0, 15]
                },
                {
                    text: 'All Archived Remittance Advices',
                    style: 'reportHeader'
                }
            ],
            styles: {
                tableHeader: { bold: true, fontSize: 13, color: 'black' },
                reportHeader: { bold: true, fontSize: 16, color: 'black' },
                boldText: { bold: true }
            }
        },
        remittanceLookup: {
            pageSize: { width: 1500, height: 2500 },
            footer: function (currentPage, pageCount, pageSize) {
                let PageFooter = "Page " + currentPage.toString() + "\n *Data viewed and printed from this website is dynamic and subject to change at any time upon the continuous loading of 835 ERA files";
                return { text: PageFooter, alignment: 'center', margin: [0, 0, 0, 25] }
            },
            content: [
                {
                    image: document.getElementById('hiddenCanvasForPdf').toDataURL(),
                    margin: [0, 0, 0, 15]
                }
            ],
            styles: {
                tableHeader: { bold: true, fontSize: 13, color: 'black' },
                reportHeader: { bold: true, fontSize: 16, color: 'black' },
                boldText: { bold: true }
            }
        }
    };

    // specify the type of columns that we should not export
    const unExportableTypes = ['custom'];

    function formatter(value, type, bold = false) {
        switch (type) {
            case 'date': return formatDate(value, bold);
            case 'money': return formatMoney(value, bold);
            case 'percent': return formatPercent(value, bold);
            default: return unkownType(value, bold);
        }
    }

    function formatMoney(cellValue, bold = false) {
        let currency = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 2, maximumFractionDigits: 2 });
        return { text: currency.format(cellValue).toString(), alignment: "right", style: (bold) ? 'boldText' : '' };
    }

    function formatPercent(cellValue, bold = false) {
        let percent = new Intl.NumberFormat('en-US', { style: 'percent', minimumFractionDigits: 2, maximumFractionDigits: 2 });
        return { text: percent.format(cellValue).toString(), style: (bold) ? 'boldText' : '' };
    }

    function formatDate(cellValue, bold = false) {
        if (cellValue) {
            let dateValue = new Date(cellValue);
            let date = new Intl.DateTimeFormat('en-US').format(dateValue);
            return { text: date.toString(), style: (bold) ? 'boldText' : '' };
        }
        return { text: "" };
    }

    function unkownType(cellValue, bold = false) {
        switch (typeof (cellValue)) {
            case "number": return { text: cellValue.toString(), style: (bold) ? 'boldText' : '' };
            case "string": return { text: cellValue.toLocaleUpperCase(), style: (bold) ? 'boldText' : '' };
            default: return "";
        }
    }

    function handleRemittanceExport() {
        // set the table columns
        const mainTable = {
            table: {
                headerRows: 1,
                body: []
            },
            layout: 'noBorders'
        };

        // if a payment record belongs to multiple NCPDPs only the following columns would be exported after the first line.
        let mutipleNCPDPCols = ["location", "paid", "adjusted", "checkAmount"];
        
        let mainTableColumns = [];

        if (cols.length > 0 && rows.length > 0) {
            cols.forEach(col => {
                if (!col.hidden && !unExportableTypes.includes(col.type)) {
                    switch (col.type) {
                        case 'money': mainTableColumns.push({ text: col.title, style: 'tableHeader', alignment: 'right' }); break;
                        default: mainTableColumns.push({ text: col.title, style: 'tableHeader' }); break;
                    }
                }
            })
        }

        // add column headers to the main table
        mainTable.table.body.push(mainTableColumns);

        Object.values(rows).forEach(check => {
            // check has multiple NCPDPs
            if (check.length > 1) {
                mainTable.table.body.push(getMultipleNCPDPCheckDetails(check));
                // loop through every payment within the check to get the detail
                check.forEach(checkPayment => {
                    let payment = [];
                    cols.forEach(col => {
                        if (!col.hidden && !unExportableTypes.includes(col.type)) {
                            if (mutipleNCPDPCols.includes(col.accessor)) {
                                payment.push(formatter(checkPayment[col.accessor], col.type));
                            }
                            else {
                                payment.push("");
                            }
                        }
                    })

                    mainTable.table.body.push(payment);
                })
            }
            // check has single NCPDP
            else {
                let payment = [];
                cols.forEach(col => {
                    if (!col.hidden && !unExportableTypes.includes(col.type)) {
                        payment.push(formatter(check[0][col.accessor], col.type));
                    }
                })

                mainTable.table.body.push(payment);
            }
        })

        function getMultipleNCPDPCheckDetails(detail) {
            let paid = 0;
            let adjust = 0;
            let amount = 0;

            detail.forEach(row => {
                paid += row.paid;
                adjust += row.adjusted;
                amount += row.checkAmount;
            })

            let data = [];
            cols.forEach(col => {
                if (!col.hidden && !unExportableTypes.includes(col.type)) {
                    switch (col.accessor) {
                        case 'location': data.push({ text: detail.length + ' NCPDPs' }); break;
                        case 'paid': data.push(formatMoney(paid)); break;
                        case 'adjusted': data.push(formatMoney(adjust)); break;
                        case 'checkAmount': data.push(formatMoney(amount)); break;
                        default: data.push(formatter(detail[0][col.accessor], col.type)); break;
                    }
                }
            })

            return data;
        }

        function getChecksTotal() {
            let rowData = [];
            let allowedTypes = ['money', 'number'];

            let showTotal = false;

            cols.forEach(col => {
                if (!col.hidden && !unExportableTypes.includes(col.type)) {
                    if (allowedTypes.includes(col.type) && col.showTotal) {
                        rowData.push(formatter(getColumnTotal(col.accessor), col.type, true))
                        showTotal = true;
                    }
                    else {
                        rowData.push("");
                    }
                }
            })

            if (showTotal) rowData[0] = { text: 'Total: ', style: 'boldText' };

            return rowData;
        }

        function getColumnTotal(column) {
            let total = 0;

            Object.values(rows).forEach(check => {
                if (check.length > 1) {
                    check.forEach(checkPayment => { total += checkPayment[column] })
                }
                else {
                    total += check[0][column];
                }
            })

            return total;
        }

        // Add the total 
        mainTable.table.body.push(getChecksTotal());

        return mainTable;
    }

    switch (key) {
        case 'RemitRcptVerification':
            docDefinition.verifyReceipt.content.push(handleRemittanceExport());
            return docDefinition.verifyReceipt;
        case 'ArchiveRemittanceReview':
            docDefinition.archiveReceipt.content.push(handleRemittanceExport());
            return docDefinition.archiveReceipt;
        case 'RemittanceLookup':
            docDefinition.remittanceLookup.content.push(handleRemittanceExport());
            return docDefinition.remittanceLookup;
        default: return null;
    }
}