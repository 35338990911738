import React from 'react'

export const constants = {
    headerProps: {
        ncpdp: { title: 'NCPDP', fixedLeft: true },
        pharmacyName: { title: 'Pharmacy Name', fixedLeft: true, truncatable: true },
        rxNumber: { title: 'Rx #', fixedLeft: true },
        serviceDate: { title: 'Service Date', type: 'date', fixedLeft: true },
        transactionDate: { title: 'Transaction Date', type: 'date', fixedLeft: true },
        patientID: { title: 'Patient ID' },
        facilityID: { title: 'Facility ID' },
        patientName: { title: 'Patient Name' },
        claimPBMName: { title: 'Claim PBM Name' },
        bin: { title: 'BIN' },
        pcn: { title: 'PCN' },
        groupID: { title: 'Group ID' },
        adjudicated: { title: 'Adjudicated', type: 'money' },
        adjudicatedCopay: { title: 'Adjudicated Copay', type: 'money' },
        remitPayerName: { title: 'Remit Payer Name' },
        checkNumber: { title: 'Check #' },
        checkDate: { title: 'Check Date', type: 'date' },
        billed: { title: 'Billed', type: 'money'  },
        adjusted: { title: 'Adjusted', type: 'money' },
        remitPaid: { title: 'Remit Paid', type:'money' },
        remitCopay: { title: 'Remit Copay', type: 'money' },
        copayType: { title: 'Copay Type' },
        difference: { title: 'Difference', type: 'money',showTotal: true },
    },
    featureInfo:
        <React.Fragment>
             Please note, claims reporting will be based on the adjustment 
            occurring within a check that has a remit date within the report 
            date range. Therefore, this report may display service dates outside 
            of the selected date range.
        </React.Fragment>

}

