import React, { useEffect } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import { SecureRoute, LoginCallback } from "@okta/okta-react";

import Home from "../pages/Home";
import LoginForm from "../auth/LoginForm";

import { ContactUs } from "../pages/ContactUs";
import { PrivacyPolicy } from "../pages/PrivacyPolicy";
import { TermsAndConditions } from "../pages/TermsAndConditions";
import TotalSalesPaymentSummary from "../pages/TotalSalesPaymentSummary/TotalSalesPaymentSummary";
import TwoYearBusinessTrend from "../pages/TwoYearBusinessTrend/TwoYearBusinessTrend";
import DSOByPayerTracking from "../pages/DSOByPayerTracking/DSOByPayerTracking";
import Aging from "../pages/Aging/Aging";
import PriceChange from "../pages/DrugPriceChange/PriceChange";
import Workbench from "../pages/Workbench/Workbench";
import Byod from "../pages/Byod/Byod";
import LocationComparison from "../pages/Benchmarking/LocationComparison";
import RemitReceiptVerification from "../pages/RemitReceiptVerification/RemitRcptVerification";
import ArchiveRemittanceReview from "../pages/ArchiveRemittanceReview/ArchiveRemittanceReview";
import SCCEvaluation from "../pages/SCCEvaluation/SCCEvalution";
import RemittanceLookup from "../pages/RemittanceLookup/RemittanceLookup";
import CopayAdjustmentManager from "../pages/CopayAdjustmentManager/CopayAdjustmentManager";
import FeeAndAdjustmentResearch from "../pages/FeeAndAdjustmentResearch/FeeAndAdjustmentResearch";
import PaymentException from "../pages/PaymentException/PaymentException";
import PayerGERTracker from "../pages/PayerGERTracker/PayerGERTracker";
import PayerMessagingManager from "../pages/PayerMessagingManager/PayerMessagingManager";
//NOTE: This was in the dev branch
import BringYourOwnData from "../pages/Byod/BringYourOwnData";

import Layout from "../layout/Layout";

function NotFound() {
  let navigate = useNavigate();

  useEffect(() => {
    navigate("/");
  }, [navigate]);

  return null;
}

export default function RMERouter(props) {
  //return (
  //    <React.Fragment>
  //        <Switch>
  //            <Route path='/login' render={() => <Login baseUrl={process.env.REACT_APP_OKTADomain} />} />
  //            <Route path='/implicit/callback' component={ImplicitCallback} />
  //            <SecureLayout exact path='/' component={Home} selectedNavbarLabel='Dashboard' featureTitle='Dashboard' />
  //            <SecureLayout path='/TotalSalesPaymentSummary' component={TotalSalesPaymentSummary} selectedNavbarLabel='Business Overview' featureTitle='Total Sales / Payment Summary' />
  //            <SecureLayout path='/TwoYearBusinessTrend' component={TwoYearBusinessTrend} selectedNavbarLabel='Business Overview' featureTitle='Business Trend' />
  //            <SecureLayout path='/DSOByPayerTracking' component={DSOByPayerTracking} selectedNavbarLabel='Payer and Contract Evaluation' featureTitle='DSO by Payer Tracking' />
  //            <SecureLayout path='/LocationComparison' component={LocationComparison} selectedNavbarLabel='Location Comparison selectedNavbarLabel value' featureTitle='Location Comparison' />
  //            <SecureLayout path='/Aging' component={Aging} selectedNavbarLabel='Reconciliation and Aging Manager' featureTitle='Aging (Outstanding) Payments' />
  //            <SecureLayout path='/PriceChange' component={PriceChange} selectedNavbarLabel='Drug and Pricing Statistics' featureTitle='30-day Price Change Review' />
  //            <SecureLayout path='/AugmentedAnalytics' component={Workbench} selectedNavbarLabel='Workbench' featureTitle='Augmented Analytics' />
  //            <SecureLayout path='/Byod' component={Byod} selectedNavbarLabel='Self-guided Analytics' featureTitle='Self-guided Analytics' />
  //            <SecureLayout path='/RemitRcptVerification' component={RemitReceiptVerification} selectedNavbarLabel='Remittance Processing' featureTitle='Remittance Receipt Verification' />
  //            <SecureLayout path='/ArchiveRemittanceReview' component={ArchiveRemittanceReview} selectedNavbarLabel='Remittance Processing' featureTitle='Archived Remittance Review' />
  //            <SecureLayout path='/RemittanceLookup' component={RemittanceLookup} selectedNavbarLabel='Remittance Processing' featureTitle='Remittance Lookup' />
  //            <SecureLayout path='/SCCEvaluation' component={SCCEvaluation} selectedNavbarLabel='Claims and Billing Review' featureTitle='SCC Evaluation' />
  //            <SecureLayout path='/CopayAdjustmentManager' component={CopayAdjustmentManager} selectedNavbarLabel='Copay Adjustment Manager' featureTitle='Copay Adjustment Manager' />
  //            <SecureLayout path='/FeeAndAdjustmentResearch' component={FeeAndAdjustmentResearch} selectedNavbarLabel='Fee and Adjustment Tracker' featureTitle='Fee and Adjustment Research' />
  //            <SecureLayout path='/PayerGERTracker' component={PayerGERTracker} selectedNavbarLabel='Payer and Contract Evaluation' featureTitle='Payer GER Tracker' />
  //            <SecureLayout path='/PaymentException' component={PaymentException} selectedNavbarLabel='Fee and Adjustment Tracker' featureTitle='Payment Exceptions' />
  //            <SecureLayout path='/PayerMessagingManager' component={PayerMessagingManager} selectedNavbarLabel='Claims and Billing Review' featureTitle='Payer Messaging Manager' />
  //            <SecureRoute exact path='/Contact Us' component={ContactUs} />
  //            <SecureRoute exact path='/Privacy Policy' component={PrivacyPolicy} />
  //            <SecureRoute exact path='/Terms & Conditions' component={TermsAndConditions} />
  //        </Switch>
  //    </React.Fragment>
  //)

  //NOTE: Alright let's forget the login for now, try to figure out some other way of making things happen
  return (
    <Routes>
      <Route path="/login" element={<LoginForm />} />
      <Route
        path="/implicit/callback"
        element={
          <LoginCallback loadingElement={<p>Loading...</p>}></LoginCallback>
        }
      />
      <Route
        exact
        path="/"
        element={
          <Layout
            selectedNavbarLabel="Self-guided Analytics"
            featureTitle="Self-guided Analytics"
          >
            <Byod />
          </Layout>
        }
      />
      <Route
        path="/LocationComparison"
        element={
          <Layout
            selectedNavbarLabel="Location Comparison"
            featureTitle="Benchmark"
          >
            <LocationComparison />
          </Layout>
        }
      />
      <Route
        path="/AugmentedAnalytics"
        element={
          <Layout
            selectedNavbarLabel="Workbench"
            featureTitle="Augmented Analytics"
          >
            <Workbench />
          </Layout>
        }
      />

      <Route
        path="/Byod"
        element={
          <Layout
            selectedNavbarLabel="Self-guided Analytics"
            featureTitle="Self-guided Analytics"
          >
            <Byod />
          </Layout>
        }
      />

      <Route
        path="/TotalSalesPaymentSummary"
        element={
          <Layout
            selectedNavbarLabel="Business Overview"
            featureTitle="Total Sales / Payment Summary"
          >
            <TotalSalesPaymentSummary />
          </Layout>
        }
      />
      <Route
        path="/DSOByPayerTracking"
        element={
          <Layout
            selectedNavbarLabel="Payer and Contract Evaluation"
            featureTitle="DSO by Payer Tracking"
          >
            <DSOByPayerTracking />
          </Layout>
        }
      />

      <Route
        path="/Aging"
        element={
          <Layout
            selectedNavbarLabel="Reconciliation and Aging Manager"
            featureTitle="Aging (Outstanding) Payments"
          >
            <Aging />
          </Layout>
        }
      />
      <Route
        path="/PaymentException"
        element={
          <Layout
            selectedNavbarLabel="Reconciliation and Aging Manager"
            featureTitle="Payment Exceptions"
          >
            <PaymentException />
          </Layout>
        }
      />

      <Route
        path="/RemitRcptVerification"
        element={
          <Layout
            selectedNavbarLabel="Remittance Processing"
            featureTitle="Remittance Receipt Verification"
          >
            <RemitReceiptVerification />
          </Layout>
        }
      />
      <Route
        path="/ArchiveRemittanceReview"
        element={
          <Layout
            selectedNavbarLabel="Remittance Processing"
            featureTitle="Archived Remittance Review"
          >
            <ArchiveRemittanceReview />
          </Layout>
        }
      />
      <Route
        path="/RemittanceLookup"
        element={
          <Layout
            selectedNavbarLabel="Remittance Processing"
            featureTitle="Remittance Lookup"
          >
            <RemittanceLookup />
          </Layout>
        }
      />

      <Route
        path="/FeeAndAdjustmentResearch"
        element={
          <Layout
            selectedNavbarLabel="Fee and Adjustment Tracker"
            featureTitle="Fee and Adjustment Research"
          >
            <FeeAndAdjustmentResearch />
          </Layout>
        }
      />
      <Route
        path="/CopayAdjustmentManager"
        element={
          <Layout
            selectedNavbarLabel="Copay Adjustment Manager"
            featureTitle="Copay Adjustment Manager"
          >
            <CopayAdjustmentManager />
          </Layout>
        }
      />

      <Route
        path="/PaymentException"
        element={
          <Layout
            selectedNavbarLabel="Reconciliation and Aging Manager"
            featureTitle="Payment Exceptions"
          >
            <PaymentException />
          </Layout>
        }
      />

      {/* Catch-all route */}
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}

//NOTE: Okay I think this "SecureRoute" component really only works on react-router-dom v5, and this is on v6 now
const SecureLayout = ({
  component: Component,
  selectedNavbarLabel: selectedNavbarLabel,
  featureTitle: featureTitle,
  ...rest
}) => {
  return (
    <SecureRoute
      {...rest}
      render={(matchProps) => (
        <Layout
          selectedNavbarLabel={selectedNavbarLabel}
          featureTitle={featureTitle}
        >
          <Component {...matchProps} />
        </Layout>
      )}
    />
  );
};
