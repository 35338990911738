import React, { useState, useEffect } from 'react';
import { Row, Col, Card } from 'react-bootstrap';

import { loadScript, isLoaded } from '../../SiSense/SiSenseScriptLoader'
import Dashboard from '../../SiSense/Dashboard';

export function CopayAdjustmentWidget(props) {
    const { title, iFrameId, filters, settings, hidden } = props

    const [scriptLoaded, setScriptLoaded] = useState(false)

    useEffect(() => {
        if (isLoaded()) {
            setScriptLoaded(true)
        } else {
            loadScript().then(() => {
                setScriptLoaded(true)
            }).catch(e => console.log(e))
        }
    }, [])

    return (
        <Card style={{ width: '30rem', height: '16rem', float: 'right' }}>
            <Card.Body style={{ padding: '5px' }} >
                <Card.Title className="text-center" >{title}</Card.Title>
                <Row>
                    <Col className="dashboard-widget-wrapper">
                        <Dashboard
                            scriptLoaded={scriptLoaded}
                            iFrameId={iFrameId}
                            filters={filters}
                            dashboard={process.env.REACT_APP_Dashboard_CopayAdjustmentManager1}
                            widget={process.env.REACT_APP_Widget_CopayAdjustmentManager1}
                            settings={settings}
                            hidden={hidden}
                        />
                    </Col>
                    <Col className="dashboard-widget-wrapper">
                        <Dashboard
                            scriptLoaded={scriptLoaded}
                            iFrameId={iFrameId}
                            filters={filters}
                            dashboard={process.env.REACT_APP_Dashboard_CopayAdjustmentManager2}
                            widget={process.env.REACT_APP_Widget_CopayAdjustmentManager2}
                            settings={settings}
                            hidden={hidden}
                        />
                    </Col>
                </Row>
            </Card.Body>
        </Card>
    )
}