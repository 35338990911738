import React, { useEffect, useState, useContext } from 'react';
import axios from 'axios';
import pdfMake from "pdfmake/build/pdfmake";
import { Col, Row } from 'react-bootstrap';
import NetRxLogo from '../../assets/NetRxLogo.png';
import { CustomAction } from '../../grid/CustomActionBarAction';
import DataTable from '../../grid/DataTable';
import { DetailsModal } from '../../grid/DetailsModal';
import { FeatureWidget } from '../../SiSense/FeatureWidget';
import ErrorHandler from './../../core/ErrorHandler';
import { LoadingSpinner } from './../../layout/LoadingSpinner';
import ActionCell from './ActionCell';
import RemitRcptForm from './RemitRcptVerificationForm';
import singleCheckPDFExporter from '../../export/SingleCheckPDFExporter';
import { UserContext } from '../../context/UserContext';
import Accordion from '../../Accordion';
import { formatCheckDetailsForExport } from '../../export/CheckDetailsExcelExport'
import { RowExcelExporter } from '../../export/RowExcelExporter'
import { constants } from './RemitRcptVerificationConstants';
import { setDefaultLocale } from 'react-datepicker';

export default function RemitRcptVerification(props) {

    const { passedProps } = props;
    const user = passedProps.user.email
    const [isLoading, setIsLoading] = useState(false)
    const [submittedFormData, setSubmittedFormData] = useState(null)
    const [error, setError] = useState({})

    const [gridRows, setGridRows] = useState([])
    const [gridCols, setGridCols] = useState([])

    // Detail Modal Variables
    const [gridDetail, setGridDetail] = useState(null)
    const [detailRows, setDetailRows] = useState([])
    const [detailCols, setDetailCols] = useState([])
    const [showModal, setShowModal] = useState(false)

    // Archive Modal Variables
    const [archiveRows, setArchiveRows] = useState([])
    const [archiveCols, setArchiveCols] = useState([])
    const [showArchiveModal, setShowArchiveModal] = useState(false)


    const [exportData, setExportData] = useState(null)
    
    const [singleCheckForExport, setSingleCheckForExport] = useState(null)
    const userContext = useContext(UserContext)

    /*
     * On Form Submit
     * 1) Get the Grid Detail Data
     * 2) Get the Grid Data
     */

    // 1) Getting Grid Detail Data
    useEffect(() => {
        async function getGridDetailData() {
            try {
                setIsLoading(true)
                const accessToken = await passedProps.auth.getAccessToken();
                const response = await axios.get(constants.mainGridDetailAPI.URL, {
                    params: {
                        user: user,
                        fromDate: submittedFormData.fromDate,
                        toDate: submittedFormData.toDate,
                        locations: submittedFormData.locations,
                        payers: submittedFormData.payers,
                        dateType: submittedFormData.dateType
                    },
                    headers: { Authorization: `Bearer ${accessToken}` }
                });

                if (Object.keys(response.data).length > 0) {
                    setGridDetail(response.data)
                    handleCustomExport(response.data)
                }
                else {
                    setError({ status: 201, Message: 'No data found for given params' })
                    setIsLoading(false)
                }
            }
            catch (e) { handleError(e) }
        }

        if (submittedFormData) {
            getGridDetailData()
        }
    }, [submittedFormData])

    // 2) Getting Grid Data
    useEffect(() => {
        async function getGridData() {
            try {
                const accessToken = await passedProps.auth.getAccessToken();
                const response = await axios.get(constants.mainGridAPI.URL, {
                    params: {
                        user: user,
                        fromDate: submittedFormData.fromDate,
                        toDate: submittedFormData.toDate,
                        locations: submittedFormData.locations,
                        payers: submittedFormData.payers,
                        dateType: submittedFormData.dateType
                    },
                    headers: { Authorization: `Bearer ${accessToken}` }
                });

                if (response.data.length > 0) {
                    setGridData(response.data)
                }
            }
            catch (e) { handleError(e) }
        }
        if (gridDetail) {
            getGridData()
        }
    }, [gridDetail])


    // Set Grid Rows & Cols
    function setGridData(data) {
        let columns = [];
        let rows = data;

        let headerProps = constants.mainGridHeaderProps;

        //NOTE: Explicitly adding a column that wasn't in the response.data. Also, take note of when the action column is added;
        //It is added at the beginning, because the order in the "column" array determines the column order on the grid
        columns.push({ accessor: "Action", title: "Action", type: "custom", selectable: true });

        Object.keys(rows[0]).forEach(key => {
            columns.push({
                accessor: key,
                title: headerProps[key].title,
                type: headerProps[key].type,
                showTotal: headerProps[key].showTotal,
                fixedLeft: headerProps[key].fixedLeft,
                hidden: headerProps[key].hidden,
                editable: headerProps[key].editable
            })
        })

        rows.forEach(row => {
            row["selected"] = false;
            let customDataObject = { NCPDP: row.ncpdp, archived: row.archive };
            row["Action"] = getActionCell(row['pmid'], customDataObject);
        })

        setError({})
        setGridRows(rows)
        setGridCols(columns)
        setIsLoading(false)
    }

    function getActionCell(rowId, rowData) {
        return ( <ActionCell rowId={rowId} rowData={rowData}/> )
    }


    // Get Modal Detail
    function getCheckDetails(id) {
        let columns = [];

        let headerProps = constants.mainGridDetailHeaderProps;

        let firstKey = Object.keys(gridDetail)[0];
        Object.keys(gridDetail[firstKey][0]).forEach(key => {
            columns.push({
                accessor: key,
                title: headerProps[key].title,
                type: headerProps[key].type,
                showTotal: headerProps[key].showTotal,
                fixedLeft: headerProps[key].fixedLeft
            })
        });

        setDetailRows(gridDetail[id])
        setDetailCols(columns)
        setShowModal(true)
    }

    // Get Archive Modal Detail
    async function getArchiveHistory(id) {
        if (id) {
            try {
                setIsLoading(true)
                const accessToken = await passedProps.auth.getAccessToken();
                const response = await axios.get(constants.archiveHistoryAPI.URL, {
                    params: { user: user, PMID: id },
                    headers: { Authorization: `Bearer ${accessToken}` }
                });

                if (response.data.length > 0) {
                    let columns = [];
                    let rows = response.data;
                    let headerProps = constants.archiveHistoryHeaderProps;

                    Object.keys(rows[0]).forEach(key => {
                        columns.push({
                            accessor: key,
                            title: headerProps[key].title,
                            type: headerProps[key].type,
                            showTotal: headerProps[key].showTotal,
                            fixedLeft: headerProps[key].fixedLeft,
                            hidden: headerProps[key].hidden
                        })
                    });

                    setArchiveCols(columns)
                    setArchiveRows(rows)
                    setShowArchiveModal(true)
                }
            }
            catch (e) {
                handleError(e)
            }
            finally { setIsLoading(false) }
        }
    }

    // Get Single Check PDF Export Report
    async function getSingleCheckPdfExport(id) {
        try {
            setIsLoading(true)
            const accessToken = await passedProps.auth.getAccessToken()
            const response = await axios.get(`api/Check/GetSingleCheckPdfExport`, {
                params: { user: user, PMID: id },
                headers: { Authorization: `Bearer ${accessToken}` }
            })

            var ctx = document.getElementById('hiddenCanvasForSingleCheckPdf').getContext('2d');
            var img = new Image();
            img.onload = () => {
                ctx.drawImage(img, 0, 0);

                const data = response.data
                let pdfFileName = "REMITADVICE";
                let docDefinition = singleCheckPDFExporter(data);
                if (docDefinition) {
                    pdfMake.createPdf(docDefinition).download(pdfFileName);
                }
            }
            img.src = NetRxLogo;
        }
        catch (e) {
            handleError(e)
        }
        finally { setIsLoading(false) }
    }

    // Get Single Check Excel Export Report
    async function getSingleCheckExcelExport(id) {
        try {
            setIsLoading(true)
            const accessToken = await passedProps.auth.getAccessToken()
            const response = await axios.get('api/Check/GetSingleCheckExcelExport', {
                params: { user: user, pmid: id },
                headers: { Authorization: `Bearer ${accessToken}` }
            })

            setSingleCheckForExport(formatCheckDetailsForExport(response.data))
        }
        catch (e) {
            handleError(e)
        }
        finally {
            setIsLoading(false)
        }
    }


    // Event Handlers
    function handleFormSubmit(e) {
        setGridRows([])
        setGridCols([])

        setGridDetail(null)
        setDetailRows([])
        setDetailCols([])

        setArchiveRows([])
        setArchiveCols([])
        setSubmittedFormData(e)
    }

    function handleCellClick(row, column) {
        switch (column) {
            case 'pdf':             getSingleCheckPdfExport(row.pmid); break;
            case 'excel':           getSingleCheckExcelExport(row.pmid); break;
            case 'archiveHistory':  getArchiveHistory(row.pmid); break;
            case 'archiveRecord':   handleArchiveSingleRecord(row); break;
            case 'ncpdp':
            case 'paid':
            case 'adjusted':
            case 'checkAmount':     getCheckDetails(row.pmid); break;
        }
    }

    async function handleCellEdit(value, row, column) {
        if (column === 'paymentDate') {
            try {
                const accessToken = await passedProps.auth.getAccessToken();
                const response = await axios.post('api/RemittanceReceiptVerification/UpdatePaymentDate',
                    {
                        user: user,
                        PaymentDate: (value) ? value.toLocaleDateString() : '',
                        PMID: row.pmid
                    },
                    { headers: { Authorization: `Bearer ${accessToken}` } });
                if (response.data) {
                    let index = gridRows.findIndex(element => element.pmid == row.pmid)
                    let updatedData = [...gridRows]
                    let updatedRow = row

                    updatedRow[column] = value
                    updatedData[index] = updatedRow
                    setGridRows(updatedData)

                    setError({ status: 200, Message: 'Your payment date is queued to be updated!' })
                }
                else {
                    setError({ status: 501, Message: '' })
                }
                setTimeout(() => { setError({}) }, 3600);
            }
            catch (e) {
                handleError(e);
            }
        }
    }

    /* Archive Remit Logic */
    function handleArchiveSingleRecord(row) {
        let newData = gridRows;
        let index = newData.findIndex(x => x.pmid === row.pmid)
        newData[index].selected = true
        setGridRows(newData)

        handleArchiveRemit([row.pmid])
    }

    function handleArchiveAllSelected() {
        const pmids = [];
        gridRows.forEach((row) => { if (row.selected) pmids.push(row.pmid) });

        handleArchiveRemit(pmids)
    }

    async function handleArchiveRemit(pmids) {
        if (pmids.length > 0) {
            try {
                const accessToken = await passedProps.auth.getAccessToken();
                await axios.get('api/RemittanceReceiptVerification/ArchiveRemit', {
                    params: {
                        user: user,
                        rpid: userContext.currentOrganization.value,
                        pmids: pmids.join(',')
                    },
                    headers: { Authorization: `Bearer ${accessToken}` }
                }).then(response => {
                    let resetData = gridRows.filter(payment => !payment.selected)
                    setGridRows([...resetData])
                    setError({ status: 200, Message: 'Your payment is queued to be archived!' })
                    setTimeout(() => { setError({}) }, 3600);
                });
            }
            catch (e) {
                handleError(e)
            }
        }
    }

    function getNewRowSelectedValue(rows) {
        if (rows.length > 1) {
            return !rows.every(x => {
                return x.selected === true
            })
        }
        return !rows[0].selected
    }

    function handleRowSelected(rows) {
        let newData = [...gridRows]
        let selectedValue = getNewRowSelectedValue(rows)

        rows.forEach(row => {
            let index = newData.findIndex((x => x.pmid == row.pmid))
            newData[index].selected = selectedValue
        })
        setGridRows(newData)
    }

    function handleCustomExport(data) {
        let columns = [];

        let headerProps = constants.mainGridExportHeaderProps;

        if (Object.keys(data).length > 0) {
 
            let col = Object.values(data[Object.keys(data)[0]]);

            Object.keys(col[0]).forEach(key => {
                columns.push({
                    title: headerProps[key].title,
                    type: headerProps[key].type,
                    accessor: key,
                    showTotal: headerProps[key].showTotal
                })
            })
        }

        setExportData({ columns: columns, rows: data, PrimaryKey: 'pmid', page: 'RemitRcptVerification' })
    }

    function handleError(error) {
        setIsLoading(false)
        setError(error)
    }

    function getCustomActions() {
        return (<CustomAction label="Archive Selected" onClick={handleArchiveAllSelected} />);
    }
    
    return (
        <div className="feature">
            <canvas id="hiddenCanvasForSingleCheckPdf" style={{ display: "none" }} width={185} height={70} ></canvas>
            <RowExcelExporter dataSet={singleCheckForExport} reset={() => setSingleCheckForExport(null)} filename='Remittance Lookup' />
            <LoadingSpinner isDataLoading={isLoading} controlsName={'RemitRcptVerification'} />
            <ErrorHandler error={error} onClose={() => { setError({}) }} />

            <Accordion defaultExpanded label="Search & Filter">
                <Row>
                    <Col><RemitRcptForm handleFormSubmit={handleFormSubmit} /></Col>
                    <Col>
                        <FeatureWidget
                            title='Oldest Payments Not Yet Received'
                            iFrameId='sisense-remittance-processing-frame'
                            filters={{}}
                            dashboard={process.env.REACT_APP_Dashboard_ReceiptVerification}
                            widget={process.env.REACT_APP_Widget_ReceiptVerification}
                            settings={{ showToolbar: false, showLeftPane: false, showRightPane: false }}
                            hidden={false}
                            size="lg"
                        />
                    </Col>
                </Row>
            </Accordion>

            <DataTable
                columns={gridCols}
                rows={gridRows}
                id="remittance-receipt-verification"
                exportable customExport={exportData} exportOptions={[`xlsx`, `pdf`]}
                filterable totalFooter pagination selectableRows
                handleCellClick={handleCellClick}
                handleCellEdit={handleCellEdit}
                handleRowSelected={(row) => handleRowSelected(row)}
                customActionBarActions={getCustomActions()}
                handleActionBarStartAction={() => { setIsLoading(true) }}
                handleActionBarCompleteAction={() => { setIsLoading(false) }}
            />
            <DetailsModal title="Remittance Receipt Details" show={showModal} handleClose={() => setShowModal(false)}>
                <DataTable
                    columns={detailCols}
                    rows={detailRows}
                    id="remittance-receipt-verification-detail"
                    exportable filterable totalFooter pagination
                />
            </DetailsModal>
            <DetailsModal title="Archive History" show={showArchiveModal} handleClose={() => setShowArchiveModal(false)}>
                <DataTable
                    columns={archiveCols}
                    rows={archiveRows}
                    id="remittance-archive-history"
                />
            </DetailsModal>
        </div>
    );

}