import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { DetailsModal } from '../../grid/DetailsModal';
import ErrorHandler from './../../core/ErrorHandler';
import { constants } from './PaymentExceptionConstants'
import DataTable from '../../grid/DataTable';
import PrescriptionDetailActionCell from './PrescriptionDetailActionCell';
import { formatCheckDetailsForExport } from '../../export/CheckDetailsExcelExport'
import { RowExcelExporter } from '../../export/RowExcelExporter'
import singleCheckPDFExporter from '../../export/SingleCheckPDFExporter';
import pdfMake from "pdfmake/build/pdfmake";
import NetRxLogo from '../../assets/NetRxLogo.png';
import { LoadingSpinner } from '../../layout/LoadingSpinner';

export default function PrescriptionDetailsModal(props) {
    const { auth, pbid, rxNumber, dispensedDate, show, handleClose, handleCellClick, user } = props;

    const [checkDetailData, setCheckDetailData] = useState([])
    const [checkDetailColumns, setCheckDetailColumns] = useState([])
    const [detailError, setDetailError] = useState({})

    const [excelData, setExcelData] = useState(null)
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        if (pbid && rxNumber && dispensedDate) {
            auth.getAccessToken()
                .then(accessToken => {
                    axios.get(`api/PaymentException/GetUnappliedCashCheckDetails`, {
                        params: {
                            User: user,
                            Pbid: pbid,
                            RxNumber: rxNumber,
                            ServiceDate: dispensedDate
                        },
                        headers: { Authorization: `Bearer ${accessToken}` }
                    })
                        .then(setGrid)
                        .catch(onFailure)
                })
                .catch(onFailure)

        }

    }, [pbid, rxNumber, dispensedDate])

    function onCellClick(row, column) {
        switch (column) {
            case 'excelExport': getExcel(row.pmid); break;
            case 'pdfExport': getPdf(row.pmid); break;
        }
    }

    async function getExcel(id) {
        setIsLoading(true)
        try {
            const accessToken = await auth.getAccessToken()
            const response = await axios.get(`api/Check/GetSingleCheckExcelExport`, {
                params: { pmid: id },
                headers: { Authorization: `Bearer ${accessToken}` }
            })

            setExcelData(formatCheckDetailsForExport(response.data))
        } catch (e) {
            console.log(e)
        }
        setIsLoading(false)
    }

    async function getPdf(id) {
        setIsLoading(true)
        try {
            const accessToken = await auth.getAccessToken()
            const response = await axios.get(`api/Check/GetSingleCheckPdfExport`, {
                params: { PMID: id },
                headers: { Authorization: `Bearer ${accessToken}` }
            })

            var ctx = document.getElementById('hiddenCanvasForSingleCheckPdf').getContext('2d');
            var img = new Image();
            img.onload = () => {
                ctx.drawImage(img, 0, 0);

                const data = response.data
                let pdfFileName = "REMITADVICE";
                let docDefinition = singleCheckPDFExporter(data);
                if (docDefinition) {
                    pdfMake.createPdf(docDefinition).download(pdfFileName);
                    setIsLoading(false)
                }
            }
            img.src = NetRxLogo;
        } catch (e) {
            setIsLoading(false)
            console.log(e)
        }
    }

    function setGrid(response) {
        let checkDetailRows = response.data
        const checkDetailColumns = []

        if (response.data && checkDetailRows.length > 0) {
            const checkDetailheaderProps = constants.checkDetailheaderProps
            checkDetailColumns.push({ accessor: "Action", title: "Download Report", type: "custom", fixedLeft: true });

            Object.keys(response.data[0]).map(key => {
                return checkDetailColumns.push({
                    accessor: key,
                    title: checkDetailheaderProps[key].title,
                    type: checkDetailheaderProps[key].type,
                    showTotal: checkDetailheaderProps[key].showTotal,
                    hidden: checkDetailheaderProps[key].hidden
                })
            })

            checkDetailRows.map(row => {
                row["Action"] = renderActionCell(row['pmid'])
            })

            setCheckDetailColumns(checkDetailColumns)
            setCheckDetailData(checkDetailRows)
            setDetailError({})
        }
        else {
            setDetailError({ status: 201, Message: 'No data found for given params' })
        }
    }

    function onFailure(error) {
        setDetailError(error);
        setCheckDetailColumns([])
        setCheckDetailData([])
    }

    function renderActionCell(key) {
        return (
            <PrescriptionDetailActionCell key={key} />
        )
    }

    return (
        <DetailsModal title="Prescription Detail" show={show} handleClose={handleClose}>
            <canvas id="hiddenCanvasForSingleCheckPdf" style={{ display: "none" }} width={185} height={70} />
            <LoadingSpinner isDataLoading={isLoading} />
            <RowExcelExporter dataSet={excelData} reset={() => setExcelData(null)} filename='Remittance Lookup' />
            <ErrorHandler error={detailError} onClose={() => { setDetailError({}) }} />
            <h4>Associated Checks</h4>
            <DataTable
                columns={checkDetailColumns}
                rows={checkDetailData}
                id="check-details-table"
                pagination
                handleCellClick={onCellClick}
            />
        </DetailsModal>
    )
}