import DatePicker from "react-datepicker";
import React, { useState, useEffect } from "react";
import { Form, Col, Button, OverlayTrigger, Popover } from "react-bootstrap";
import MemberLocationDropdown from "../../form/MemberLocationDropdown";
import MemberPayerDropdown from "../../form/MemberPayerDropdown";
import { constants } from "./CopayAdjustmentManagerConstants";

import FormValidation from "../../form/validation/FormValidation";

export default function CopayAdjustmentManagerForm(props) {
  const [toDate, setToDate] = useState(null);
  const [fromDate, setFromDate] = useState(null);
  const [selectedPayers, setSelectedPayers] = useState([]);
  const [selectedLocations, setSelectedLocations] = useState([]);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const date = new Date();
    setFromDate(new Date(date.getFullYear(), date.getMonth() - 1, 1));
    setToDate(new Date(date.getFullYear(), date.getMonth(), 0));
  }, []);

  useEffect(() => {
    if (!loaded && selectedLocations.length > 0 && selectedPayers.length > 0) {
      setLoaded(true);
      submitForm();
    }
  }, [selectedLocations, selectedPayers]);

  function handleFormSubmit(event) {
    event.preventDefault();

    submitForm();
  }

  function submitForm() {
    let parameters = {
      fromDate: fromDate,
      toDate: toDate,
      selectedLocations: selectedLocations,
      selectedPayers: selectedPayers,
      requiredFields: ["fromDate", "toDate"],
    };

    let validationIssueMessage = FormValidation(parameters);

    if (validationIssueMessage) {
      alert(validationIssueMessage);
    } else {
      // To calculate the time difference of two dates
      const differenceInTime = toDate.getTime() - fromDate.getTime();
      // To calculate the no. of days between two dates
      let differenceInDays = differenceInTime / (1000 * 3600 * 24);
      differenceInDays += 1;

      if (Math.round(differenceInDays) > 31) {
        alert("Please select a date range under 31 days.");
      } else {
        props.handleFormSubmit({
          fromDate: fromDate,
          toDate: toDate,
          payers: selectedPayers.join(","),
          locations: selectedLocations.join(","),
        });
      }
    }
  }

  const popover = (
    <Popover id="popover-basic">
      <Popover.Content>{constants.featureInfo}</Popover.Content>
    </Popover>
  );

  return (
    <Form onSubmit={handleFormSubmit}>
      <Form.Row className="justify-content-start">
        <Form.Group as={Col} md="auto" className="d-flex flex-column">
          <Form.Label>Date From</Form.Label>
          <Form.Control
            name="fromDate"
            as={DatePicker}
            selected={fromDate}
            onChange={(date) => setFromDate(date)}
          />
        </Form.Group>
        <Form.Group as={Col} md="auto" className="d-flex flex-column">
          <Form.Label>Date To</Form.Label>
          <Form.Control
            name="toDate"
            as={DatePicker}
            selected={toDate}
            minDate={fromDate}
            maxDate={new Date()}
            onChange={(date) => setToDate(date)}
          />
        </Form.Group>
        <Form.Group as={Col} md="auto" className="d-flex flex-column">
          <Form.Label>Payer</Form.Label>
          <Form.Control
            as={MemberPayerDropdown}
            onChange={(payers) => setSelectedPayers(payers)}
          />
        </Form.Group>
        <Form.Group as={Col} md="auto" className="d-flex flex-column">
          <Form.Label>Location (NCPDP)</Form.Label>
          <Form.Control
            as={MemberLocationDropdown}
            onChange={(locations) => setSelectedLocations(locations)}
          />
        </Form.Group>
        <Form.Group as={Col} md="auto" className="d-flex flex-column">
          <Button className="inline-show-result-button" size="sm" type="submit">
            Show Results
          </Button>
        </Form.Group>
        <span>
          <OverlayTrigger
            trigger="click"
            placement="bottom"
            overlay={popover}
            rootClose
          >
            <i className="additional-info-popover-icon fa fa-info-circle" />
          </OverlayTrigger>
        </span>
      </Form.Row>
    </Form>
  );
}
