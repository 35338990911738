export const constants = {
    headerProps:
    {
        feeCode: { title: 'Fee Code' },
        description: { title: 'Description' },
        total: { title: 'Total', type: 'money', showTotal: true, },
        feeId: { title: 'id', hidden: true }
    },
    detailSummaryHeaderProps: {
        payer: { title: 'Payer' },
        checkNumber: { title: 'Check #' },
        adjustmentAmount: { title: 'Adjustments', type: 'money' }
    },
    detailHeaderProps: {
        payer: { title: 'Payer' },
        checkNumber: { title: 'Check Number' },
        ncpdp: { title: 'NCPDP' },
        checkDate: { title: 'Check Date', type: 'date' },
        checkAmount: { title: 'Check Amount', type: 'money' },
        rxNumber: { title: 'Rx #' },
        fillDate: { title: 'Fill Date', type: 'date' },
        transactionDate: { title: 'Transaction Date', type: 'date' },
        patientName: { title: 'Patient Name' },
        feeAdjustmentAmount: { title: 'Fee/Adjustment Amount', type: 'money', },
        reasonCode: { title: 'Reason Code' },
        reasonDescription: { title: 'Reason Description'}

    }

}