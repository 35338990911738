import React, { Component } from 'react';
import { FileUpload } from './FileUpload';
import axios from "axios";
import ByodForm from './ByodForm';

export default function Byod(props) {

    const SisenseURL = process.env.REACT_APP_SisenseURI + '/app/main#/home?h=false'

    return (
        <div className="full-sisense-feature">
            <iframe src={SisenseURL} id="sisense-frame" width="100%" height="100%" allowFullScreen="true"></iframe>
        </div>
        )
    
}
