import React from 'react';
import { Nav, NavDropdown } from 'react-bootstrap';
import { DetailsModal } from '../../grid/DetailsModal';
import ExcelExporter from '../../export/excelExporter';
import CSVExporter from '../../export/csvExporter';
import FeeAndAdjustmentDetailsGroup from './FeeAndAdjustmentDetailsGroup';
import FeeAndAdjustmentDetailGrandTotal from './FeeAndAdjustmentDetailGrandTotal';

export default function FeeAndAdjustmentDetailsModal(props) {
    const { data, exportData, show, onClose } = props

    const summaries = data && data.map(section => section.summary)

    return (
        <DetailsModal title="Fee and Adjustment Research Detail" show={show} handleClose={onClose}>
            <Nav variant="pills">
                <NavDropdown title={<span><i className="fa fa-download" aria-hidden></i> Download As...</span>} id="nav-dropdown">
                    <NavDropdown.Item>
                        <ExcelExporter columns={[]} rows={[]} custom={exportData} />
                    </NavDropdown.Item>
                    <NavDropdown.Item>
                        <CSVExporter columns={[]} rows={[]} custom={exportData} />
                    </NavDropdown.Item>
                </NavDropdown>
            </Nav>
            <FeeAndAdjustmentDetailGrandTotal data={summaries} />
            {data.map((section, index) => {
                return (
                    <React.Fragment>
                        <FeeAndAdjustmentDetailsGroup key={index} data={section} /> 
                        <br/>
                    </React.Fragment>

                )
            })}
        </DetailsModal>
    )
}