export const constants = {
    mainGridHeaderProps: {
        payer: { title: 'Payer' },
        pmid: { title: 'PMID', hidden: true },
        checkNumber: { title: 'Check Number' },
        checkDate: { title: 'Check Date', type: 'date' },
        remittanceDate: { title: 'Remit Date', type: 'date' },
        paymentDate: { title: 'Payment Date', type: 'date', editable: true },
        status: { title: 'Status' },
        ncpdp: { title: 'NCPDP' },
        paid: { title: 'Paid', type: 'money', showTotal: true },
        adjusted: { title: 'Adjusted', type: 'money', showTotal: true },
        checkAmount: { title: 'Check Amount', type: 'money', showTotal: true },
        archived: { title: 'archived', hidden: true },
        isManualCheck: { title: 'do not show', hidden: true }
    },
    mainGridDetailHeaderProps: {
        payer: { title: 'Payer' },
        pmid: { title: 'PMID', hidden: true },
        checkNumber: { title: 'Check Number' },
        checkDate: { title: 'Check Date', type: 'date' },
        remittanceDate: { title: 'Remit Date', type: 'date' },
        paymentDate: { title: 'Payment Date', type: 'date' },
        status: { title: 'Status', hidden: true },
        location: { title: 'Location' },
        paid: { title: 'Paid', type: 'money', showTotal: true },
        adjusted: { title: 'Adjusted', type: 'money', showTotal: true },
        checkAmount: { title: 'Check Amount', type: 'money', showTotal: true },
        archiveUser: { title: 'Archive User', hidden: true },
        archivedDate: { title: 'Archive Date', type: 'date', hidden: true },
    },
    mainGridExportHeaderProps: {
        payer: { title: 'Payer' },
        pmid: { title: 'PMID', hidden: true },
        checkNumber: { title: 'Check Number' },
        checkDate: { title: 'Check Date', type: 'date' },
        remittanceDate: { title: 'Remit Date', type: 'date' },
        paymentDate: { title: 'Payment Date', type: 'date' },
        status: { title: 'Status' },
        location: { title: 'NCPDP' },
        paid: { title: 'Paid', type: 'money', showTotal: true },
        adjusted: { title: 'Adjusted', type: 'money', showTotal: true },
        checkAmount: { title: 'Check Amount', type: 'money', showTotal: true },
        archivedDate: { title: 'Archive Date', type: 'date', hidden: true },
        archiveUser: { title: 'Archive User', hidden: true },
    },
    archiveHistoryHeaderProps: {
        checkNumber: { title: 'Check Number' },
        checkDate: { title: 'Check Date', type: 'date' },
        checkAmount: { title: 'Check Amount', type: 'money' },
        archived: { title: 'Activity' },
        addUser: { title: 'User' },
        addDate: { title: 'Date', type: 'date' },
        pmid: { title: 'pmid', hidden: true }
    }
}

